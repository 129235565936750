import React from 'react'
import { useMutation, useQuery } from 'react-query'
import useApiCall from './useApiCall'

export const useOrderDetail = (shortId, { params } = {}) => {
  const [fetchOrderDetail] = useApiCall({
    path: `orders/shortId/${shortId}`,
    params,
  })
  return useQuery(['order', shortId], async () => {
    const order = await fetchOrderDetail()
    return order
  })
}

export const useFetchInfoBarMessage = () => {
  const [fetchInfoBarMessages] = useApiCall({ path: 'info' })
  return useQuery(['infobarmessages'], async () => {
    const messages = await fetchInfoBarMessages()
    return messages
  })
}

export const useFetchNotifs = () => {
  const [fetchInfoBarMessages] = useApiCall({ path: 'notifs' })
  return useQuery(['notifs'], async () => {
    const messages = await fetchInfoBarMessages()
    return messages
  })
}

// eslint-disable-next-line import/prefer-default-export
export const useFeedbackPopupMutation = (callbacks) => {
  const [apiCall] = useApiCall({
    path: '/forms/feedback-popup',
    method: 'POST',
  })

  return useMutation((body) => apiCall(body), callbacks)
}
// eslint-disable-next-line import/prefer-default-export
export const useWoodMakerContactMutation = (callbacks) => {
  const [apiCall] = useApiCall({
    path: '/forms/woodmaker-contact',
    method: 'POST',
  })

  return useMutation((body) => apiCall(body), callbacks)
}

export const useCheckDiscountCodeMutation = (callbacks) => {
  const [apiCall] = useApiCall({
    path: '/discount-codes/check',
    method: 'POST',
  })

  console.log('inside')
  return useMutation((body) => apiCall(body), callbacks)
}

export const useBadWoodMakerMutation = (shortId, callbacks) => {
  const [apiCall] = useApiCall({
    path: `/orders/shortId/${shortId}/bad-woodmaker`,
    method: 'POST',
  })

  return useMutation((body) => apiCall(body), callbacks)
}

export const useNewReviewMutation = (shortId, callbacks) => {
  const [apiCall] = useApiCall({
    path: `/orders/shortId/${shortId}/review`,
    method: 'POST',
  })

  return useMutation((body) => apiCall(body), callbacks)
}

export const useNewReviewFilesMutation = (shortId, callbacks) => {
  const [apiCall] = useApiCall({
    path: `/orders/shortId/${shortId}/review-files`,
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  const mutation = useMutation((body) => apiCall(body), callbacks)

  return {
    ...mutation,
    mutateAsync: async (body) => {
      const formData = new FormData()
      Object.keys(body).forEach((key) => {
        body[key]?.forEach?.((file) => {
          formData.append(key, file)
        })
      })
      return mutation.mutateAsync(formData)
    },
  }
}

export const useRemindReviewMutation = (shortId, callbacks) => {
  const [apiCall] = useApiCall({
    path: `/orders/shortId/${shortId}/remind-review`,
    method: 'POST',
  })

  return useMutation((body) => apiCall(body), callbacks)
}

export const useCustomerComplaintMutation = (shortId, callbacks) => {
  const [apiCall] = useApiCall({
    path: `/orders/shortId/${shortId}/customer-service`,
    method: 'POST',
  })

  return useMutation((body) => apiCall(body), callbacks)
}

export const useSendCalcMutation = (callbacks) => {
  const [apiCall] = useApiCall({
    path: '/forms/send-calc',
    method: 'POST',
  })

  return useMutation((body) => apiCall(body), callbacks)
}

export const useSubscribeMutation = (callbacks) => {
  const [apiCall] = useApiCall({
    path: '/forms/subscribe',
    method: 'POST',
  })

  return useMutation((body) => apiCall(body), callbacks)
}

export const useCalcResult = (shortId, callbacks) => {
  const [fetchCall] = useApiCall({
    path: `calc/amounts-result/shortId/${shortId}`,
  })
  return useQuery(
    ['calc-result', shortId],
    async () => {
      const order = await fetchCall()
      return order
    },
    callbacks,
  )
}

// export const useTriggerHookMutation = (key, callbacks) => {
//   const [apiCall] = useApiCall({
//     method: 'POST',
//   })

//   return useMutation(
//     (triggerIndex) => apiCall(null, `/projects/${key}/trigger/${triggerIndex}`),
//     callbacks,
//   )
// }

export const useCalcMutation = (callbacks) => {
  const [apiCall] = useApiCall({
    path: 'calc/calc-amounts',
    method: 'POST',
  })

  return useMutation((body) => apiCall(body), callbacks)
}

export const useNewOrderMutation = (callbacks) => {
  const [apiCall] = useApiCall({
    path: 'calc/order',
    method: 'POST',
  })

  return useMutation((body) => apiCall(body), callbacks)
}
