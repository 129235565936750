import React from 'react'
import styled, { css } from 'styled-components'
import { up, addResponsivity, upBp } from '../lib/styles'

const Col = styled.div`
  ${({
    theme: { colors },
    justifyContent,
    alignItems,
    alignSelf,
    mobileAlignSelf,
    mobileAlignItems,
    position,
    maxWidth,
    minWidth,
    mobileMaxWidth,
    width,
    mobileWidth,
    flex,
    grow,
    basis,
    shrink,
    padding,
    margin,
    mobilePadding,
    mobileMargin,
    mobileHeight,
    mobileMinWidth,
    height,
    cursor,
    textWrap,
    bp = 'mobile',
    transition,
  }) => css`
    display: flex;
    flex-direction: column;
    align-items: ${mobileAlignItems || alignItems};
    justify-content: ${justifyContent};
    max-width: ${mobileMaxWidth || maxWidth};
    min-width: 0;
    min-width: ${mobileMinWidth || minWidth};
    width: ${mobileWidth || width};
    height: ${mobileHeight || height};
    position: ${position};
    flex: ${flex};
    align-self: ${mobileAlignSelf || alignSelf};
    flex-grow: ${grow};
    flex-basis: ${basis};
    flex-shrink: ${shrink};
    padding: ${mobilePadding || padding};
    margin: ${mobileMargin || margin};
    transition: ${transition};
    cursor: ${cursor};
    text-wrap: ${textWrap};

    ${upBp} {
      padding: ${padding};
      margin: ${margin};
      align-self: ${alignSelf};
      max-width: ${maxWidth};
      align-items: ${alignItems};
      width: ${width};
      min-width: ${minWidth};
    }

    ${addResponsivity({ displayAs: 'flex' })}
  `}
`

export default Col
