import React from 'react'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroupCore from '@mui/material/ToggleButtonGroup'
import { styled } from '@mui/material/styles'

const StyledToggleButtonGroup = styled(ToggleButtonGroupCore)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    borderRadius: '150px',
    padding: '8px 20px',
    '&.Mui-selected': {
      background: 'rgb(225, 225, 225)',
    },
    // margin: theme.spacing(0.5),
    // border: 0,
    // '&.Mui-disabled': {
    //   border: 0,
    // },
    // '&:not(:first-of-type)': {
    //   borderRadius: theme.shape.borderRadius,
    // },
    // '&:first-of-type': {
    //   borderRadius: theme.shape.borderRadius,
    // },
  },
}))

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  // '& .Mui-selected': {
  //   background: 'red',
  // },
}))

export default function ToggleButtonGroup(props) {
  const {
    multi,
    defaultValue,
    onChange,
    options,
  } = props

  const [value, setValue] = React.useState(defaultValue || (multi ? [] : null))

  const handleChange = (event, value) => {
    setValue(value)
    onChange(value)
  }

  return (
    <StyledToggleButtonGroup
      size="small"
      value={value}
      exclusive={!multi}
      onChange={handleChange}
    >
      {options.map((option) => (
        <StyledToggleButton
          key={option.value}
          value={option.value}
          aria-label={option.label}
          disabled={option.disabled}
        >
          {option.label}
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  )
}
