import React from 'react'
import styled, { css } from 'styled-components'
import { addResponsivity } from '../lib/styles'

const Cross = styled.div`
  ${({ justIcon, scale, appendCss }) => css`
    display: block;
    min-width: 40px;
    min-height: 40px;
    cursor: pointer;
    transform: scale(${scale || 1});

    ${!justIcon &&
    css`
      position: absolute;
      right: 0;
      top: 0;
      /* transform: translate(0, -50%); */
    `}

    ${addResponsivity()}
    ${appendCss}
  `}
`

const CrossItem = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  width: 25px;
  height: 4px;
  background: #c4c4c4;
  border-radius: 100px;
  /* transform-origin: center center; */
  transform: translate(-50%, -50%)
    rotate(${({ rotation }) => rotation || 45}deg);
`

// eslint-disable-next-line arrow-body-style
const CloseIcon = ({ onClick, justIcon, scale, appendCss, ...rest }) => {
  // console.log('renders CloseIcon')
  return (
    <Cross
      onClick={onClick}
      justIcon={justIcon}
      scale={scale}
      appendCss={appendCss}
      {...rest}
    >
      <CrossItem />
      <CrossItem rotation={135} />
    </Cross>
  )
}

export default CloseIcon
