import { ErrorMessage, useFormikContext } from 'formik'
import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import {
  addResponsivity,
  up,
  down,
  between,
  addFont,
  colors,
} from '../../lib/styles'

const StyledSuccessWrapper = styled.div`
  position: relative;
  ${({ width }) => css`
    width: ${width};
  `}
`
const StyledSuccess = styled.div`
  ${({ theme: { colors } }) => css`
    ${addFont('signika')}
    font-weight: 400;
    font-size: 14px;
    color: ${colors.green};
    position: absolute;
    top: 0;
  `}
`

const Success = ({ text }) => {
  const { errors, touched } = useFormikContext()

  const errorMessage = errors[name]

  return (
    <StyledSuccessWrapper>
      <StyledSuccess>{text}</StyledSuccess>
    </StyledSuccessWrapper>
  )
}

export default Success
