import React from 'react'
import styled, { css } from 'styled-components'
import { Link as GatsbyLink, graphql, navigate, useStaticQuery } from 'gatsby'
import { up, addResponsivity } from '../lib/styles'
import { getLink, links } from '../lib/links'
import { trimSlash } from '../lib/utils'
import { usePopups } from './hooks/usePopups'
import { entities as linkEntities } from '../editor/linkEntities'
import { extractNodesFromData } from '../lib/gatsbyHelpers'

const scrollTo = (anchor, offset = -20, { container } = {}) => {
  const target = document.getElementById(anchor)
  if (target) {
    if (!container) {
      const pos = target.getBoundingClientRect().top + window.scrollY
      // console.log(pos)
      window.scroll({
        top: pos + offset, // subtract height of pinned menu on desktop
        behavior: 'smooth',
      })
    }

    // scroll container if provided, e.g. popup
    if (container) {
      const pos = target.getBoundingClientRect().top + container.scrollTop
      // console.log('targettargettarget',target, target.offsetTop )
      // window.tt = target
      container.scrollTo({
        top: pos + offset, // subtract height of pinned menu on desktop
        behavior: 'smooth',
      })
    }
  } else {
    console.log('NO TARGET!!!')
  }
}

const LinkWrapper = styled.div`
  ${({
    theme: { colors },
    upperCase,
    white,
    red,
    blue,
    green,
    navLink,
    withUnderline,
    withHoverUnderline,
    withHover,
    fontSize = '16px',
    bold,
    fontWeight = 600,
    alignSelf,
    isActive,
    mobileFontSize,
    lineHeight = '20px',
    bp = 'mobile',
    width,
    noStyle,
    color,
    hoverColor,
    activeColor,
  }) => css`
    display: inline-block;
    width: ${width};

    ${!noStyle &&
    css`
      & > a,
      & > span {
        transition: 0.2s;
        text-decoration: none;
        color: ${white && 'white'};
        color: ${green && colors.green};
        color: ${red && colors.red};
        color: ${blue && colors.blue};
        color: ${colors[color] || color};
        text-transform: ${upperCase && 'uppercase'};
        display: inline-block;
        text-decoration: ${withUnderline && 'underline'};
        text-decoration: ${navLink && 'none'};
        font-weight: ${fontWeight};
        align-self: ${alignSelf};
        line-height: ${lineHeight};
        cursor: pointer;

        &:hover {
          text-decoration: none;
          color: ${blue && colors.darkerBlue};
          color: ${colors[hoverColor] || hoverColor};
          text-decoration: ${withHoverUnderline && 'underline'};
        }

        ${isActive &&
        css`
          color: ${colors[activeColor] || activeColor};
        `}

        ${addResponsivity()}

      font-size: ${mobileFontSize || fontSize};
        ${up(bp)} {
          font-size: ${fontSize};
        }

        /* fancy underline */
        ${navLink &&
        css`
          width: fit-content;
          /* &::after {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            position: relative;
            margin-top: 0px;
            opacity: 0;
            transition: 0.2s;

            background-image: linear-gradient(
              to right,
              ${colors.orange} 7px,
              rgba(255, 255, 255, 0) 0%
            );
            background-position: bottom left;
            background-size: 11px 100px;
            background-repeat: repeat-x;
          } */

          ${isActive &&
          css`
            &::after {
              opacity: 1;
            }
            ${withHover &&
            css`
              color: ${colors.orange};
            `}
          `}
        `}
      }
    `}
  `}
`

// automatically fill route path with translated route
// automatically prepend path with /en/ or /de/ etc...
// when preferLang --> generate link in that lang === use to switch to other lang
//
const Link = ({
  to, // page route constant or dynamic route with /key/slug (eg. blog/article-slug)
  preferLang,
  asSpan,
  asA,
  href, // only for asA
  target,
  anchor,
  alt,
  onClick,
  children,
  popup,
  popupProps,
  entities = linkEntities,
  ...rest
}) => {
  // console.log('restrest', rest.pageKey, rest.postId)

  const { pageKey } = rest
  const data = useStaticQuery(graphql`
    query {
      pages: allSitePage {
        edges {
          node {
            id
            pageContext
          }
        }
      }
    }
  `)

  // console.log('entitiesentitiesentitiesentities', entities)
  // console.log('LinkComponent rest', rest)

  // for editor links - find if we have filled prop with some entity key
  const { pages } = extractNodesFromData(data)

  // console.log('pages', pages)

  const { postId } = rest
  let dbPage = null
  if (postId) {
    dbPage = pages.find((page) => page.pageContext._id === postId)
    to = `/${dbPage.pageContext.slug}`
    // console.log('dbPage', dbPage)
  }

  if (pageKey) {
    to = pageKey
  }

  const popups = usePopups()

  const handleClick = (e, { isA, href } = {}) => {
    if (onClick) {
      onClick(e)
    }

    if (anchor) {
      setTimeout(() => {
        scrollTo(anchor)
      }, 300)
    }
    // TODO: kotva + gatsby link

    if (popup && popups.popupTypes[popup]) {
      popups.openPopup(popups.popupTypes[popup], popupProps)
    }

    // if (isA) {
    //   // Deal with anchor link on our page
    //   if (href && !href.startsWith('http') && href.indexOf('#') !== -1) {
    //     // console.log('is anchor')
    //     // history.pushState(null, null, hashval)

    //     setTimeout(() => {
    //       const targetId = href.substring(href.indexOf('#') + 1)

    //       window.scroll({
    //         top: document.getElementById(targetId).offsetTop - 24, // subtract height of pinned menu on desktop
    //         behavior: 'smooth',
    //       })
    //     }, 100)

    //     e.preventDefault()
    //   }
    // }
  }

  return (
    <LinkWrapper as={asSpan && 'span'} {...rest}>
      {asSpan && <span onClick={handleClick}>{children}</span>}
      {asA && (
        <a
          href={href}
          target={target}
          alt={alt}
          onClick={(e) => handleClick(e, { isA: true, href })}
        >
          {children}
        </a>
      )}
      {!asSpan && !asA && (
        <GatsbyLink to={to} onClick={handleClick}>
          {children}
        </GatsbyLink>
      )}
    </LinkWrapper>
  )
}

// define common link styles
Link.BasicLink = (props) => (
  <Link upperCase fontSize="14px" withHover {...props} />
)

// Navbar link
Link.NavLink = (props) => (
  <Link.BasicLink navLink mobileFontSize="16px" bp="menu" {...props} />
)

// Footer link
Link.FooterLink = (props) => (
  <Link withHoverUnderline lineHeight="inherit" fontSize="inherit" {...props} />
)

// Text link
Link.TextLink = (props) => (
  <Link
    blue
    // fontWeight="inherit"
    withHoverUnderline
    fontSize="inherit"
    {...props}
  />
)

Link.ShadowTextLink = (props) => (
  <Link
    // blue
    // fontWeight="inherit"
    withHoverUnderline
    fontSize="inherit"
    color="textGrey"
    {...props}
  />
)

// Link.FooterLink = styled(Link)`
//   ${({ theme: { colors } }) => css`
//     /* a { */
//       /* color: ${colors.white};
//       font-size: 16px;
//       font-weight: 600;
//       line-height: 24px;
//       &:hover {
//         text-decoration: underline;
//         color: ${colors.white};
//       }
//     } */
//   `}
// `

// PAGE ROUTES CONSTS
Object.keys(links).forEach((link) => {
  Link[link] = links[link]
})

Link.scrollTo = scrollTo

Link.getLink = getLink

Link.navigate = navigate

export default Link
