import PhoneNumber from 'awesome-phonenumber'

import Loading from '@kiwicom/orbit-components/lib/Loading'
import useDebounce from 'react-use/lib/useDebounce'

import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'

import React, { Component, useEffect, useMemo, useRef, useState } from 'react'
import * as Yup from 'yup'
import { nanoid } from 'nanoid'
import axios from 'axios'
import {
  Formik,
  Form,
  useField,
  useFormikContext,
  Field,
  ErrorMessage,
} from 'formik'

import { navigate } from 'gatsby'
import { Col, Row, Text, Title, Gap, Link, VH, Button, RH } from '..'
import {
  useCalcResult,
  useCheckDiscountCodeMutation,
} from '../hooks/useQueries'
import {
  Checkbox,
  RadioGroup,
  Slider,
  Input,
  SLocationInput,
  FormikObserver,
  HintTooltip,
  Label,
} from '../fields'

import { StyledTooltip, HintIcon, InfoIcon } from '../fields/HintTooltip'
import config, { deliveryTime, deliveryTimeUnit } from '../../config'

import CloseIcon from '../CloseIcon'

import Popup, { PopupPositioner } from '../Popup'
import {
  classicRound,
  formatCurrency,
  formatNumberWithThousands,
  getFormikErrorsFromAxiosError,
  getFormikErrorsFromMutationError,
  getUrlParam,
} from '../../lib/helpers'

import Loader from '../Loader'

import { ButtonSmallText } from '../Button'
import ReactPortal from '../ReactPortal'
import { addressHint } from './helpers'
import { PersistFormikValues } from '../../lib/PersistFormikValues'
import { useGlobalContext } from '../GlobalContext'
import { useSendGlobalOrder, useServerCalculateMutation } from './hooks'
import {
  GlobalSLocationInput,
  useUpdateGlobalAddress,
  validateFormikAddress,
  validateOrderAddress,
} from '../fields/SLocationInput'
import { initialAddress } from './initialValues'
import { usePartner } from '../hooks/useParner'
import { useUpdatePricePopup } from './UpdatePricePopup'
import { useExperiments } from '../hooks/useExperiments'
import { orderPopupSchema } from '../../validations/forms'

export const OrderPopupItemLabel = (props) => (
  <Text fontSize="15px" fontWeight={700} {...props} />
)

export const OrderPopupItemValue = (props) => (
  <Text fontSize="18px" {...props} />
)
export const OrderPopupItemValueNote = (props) => (
  <Text fontSize="15px" {...props} as="span" displayAs="inline" />
)

export const OrderPopupItem = ({ label, value, valueNote, valueHint }) => (
  <>
    <Gap gap="16px" />
    <OrderPopupItemLabel>{label}</OrderPopupItemLabel>
    <Row>
      <OrderPopupItemValue>{value}</OrderPopupItemValue>
      {' '}
      {valueNote && (
        <OrderPopupItemValueNote>
          <>&nbsp;</>
          {valueNote}
        </OrderPopupItemValueNote>
      )}
      {valueHint && <HintTooltip hint={valueHint} />}
    </Row>
  </>
)

export const OrderPopup = ({ closePopup, popupRef }) => {
  const { dimensions } = useExperiments()

  const {
    setIsOrderPopupOpen,
    // inputs which order started with
    orderCalcInputs,
    // results which order started with
    orderCalcResult,
  } = useGlobalContext()

  useEffect(() => {
    setIsOrderPopupOpen(true)
    console.log('setting order popup OPEN')
    return () => {
      setIsOrderPopupOpen(false)
      console.log('setting order popup CLOSE')
    }
  }, [])

  const {
    UpdatePricePopup,
    newOffer,
    openUpdatePricePopup,
    calculateNewAddress,
  } = useUpdatePricePopup()

  const { partner } = usePartner()

  const checkDiscountCodeMutation = useCheckDiscountCodeMutation()

  const { sendOrderAsync } = useSendGlobalOrder()

  const [showDiscountCode, setShowDiscountCode] = useState(false)
  const [discountCodeObj, setDiscountCodeObj] = useState()

  const needAccurateAddress = orderCalcInputs.address.source !== 'addr'

  // Order popup submit
  const onSubmit = async (values, formikHelpers, ...rest) => {
    const { setSubmitting, setErrors } = formikHelpers
    setSubmitting(true)

    try {
      const data = await sendOrderAsync(values)
      setSubmitting(false)

      if (partner) {
        navigate(`/thanks-partner?partner=${partner}`)
      } else {
        navigate('/thanks')
      }
    } catch (e) {
      console.log('error sending order', e, e?.response?.data)

      const formErrors = getFormikErrorsFromAxiosError(e)

      console.log('formErrors', formErrors)

      if (formErrors) {
        setErrors(formErrors)
      } else {
        alert(
          'Odeslání se nezdařilo, zkuste znovu. Pokud problémy přetrvávají, kontaktujte nás prosím na info@srovnejdrevo.cz.',
        )
      }

      setSubmitting(false)
    }
  }

  const onAddressUpdate = async (address) => {
    openUpdatePricePopup()
    await calculateNewAddress(address)
  }

  return (
    <ReactPortal wrapperId="react-portal-popup-order">
      <Formik
        initialValues={{
          name: '',
          email: '',
          phone: '',
          discountCode: '',
          address: initialAddress,
        }}
        validationSchema={orderPopupSchema}
        validate={async (values) => {
          let errors = {}

          const pn = PhoneNumber(values.phone, 'CZ')
          if (!pn.isValid()) {
            errors.phone = 'Číslo není správné'
          }

          // TODO: mozna pridat  debounce?
          if (values.discountCode) {
            try {
              const result = await checkDiscountCodeMutation.mutateAsync({
                discountCode: values.discountCode,
              })
              delete errors.discountCode

              console.log('result CODE', result)
              setDiscountCodeObj(result)
            } catch (error) {
              const formErrors = getFormikErrorsFromMutationError(error)

              console.log('errorerrorerror', error, formErrors)
              errors.discountCode =
                formErrors?.discountCode ||
                error?.name ||
                error?.message ||
                'Chyba'
              setDiscountCodeObj(null)
            }
          }

          // if calc address is not enough
          if (needAccurateAddress) {
            errors = {
              ...errors,
              ...validateFormikAddress(values, {
                name: 'address',
                validation: validateOrderAddress,
              }),
            }
          }

          // we have newOffer but user didt accept --> show again
          if (
            newOffer &&
            newOffer?.finalTotalPrice !== orderCalcResult?.finalTotalPrice
          ) {
            openUpdatePricePopup()
            return { ...errors, showWarningPopup: true }
          }

          return errors
        }}
        onSubmit={onSubmit}
      >
        {({
          submitForm,
          setFieldValue,
          isSubmitting,
          values,
          errors,
          setErrors,
          setFieldError,
          setFieldTouched,
          ...rest
        }) => {
          const showDiscountField = showDiscountCode || errors.discountCode

          let finalPriceText = `${formatNumberWithThousands(
            orderCalcResult.finalTotalPrice,
          )} Kč`
          if (discountCodeObj) {
            finalPriceText = `${formatNumberWithThousands(
              orderCalcResult.finalTotalPrice,
            )} - ${discountCodeObj.absoluteAmount} = 
          ${formatNumberWithThousands(
              orderCalcResult.finalTotalPrice - discountCodeObj.absoluteAmount,
            )}
          Kč`
          }

          return (
            <>
              <Popup
                withBottomGradient
                popupRef={popupRef}
                mobileMaxWidth="350px"
              >
                <>
                  {/* {(() => {
                    console.log('rest', rest, 'errors', errors)
                  })()} */}
                  {isSubmitting && <Loader />}
                  <Form>
                    <PersistFormikValues
                      name="order-form"
                      ignoreValues={['discountCode', 'address']}
                    />
                    <Col alignItems="flex-start">
                      <Col maxWidth="300px" width="100%">
                        <CloseIcon onClick={closePopup} />

                        <Title.SectionTitle mobileTextAlign="left">
                          Už jen 1 krok
                        </Title.SectionTitle>
                        <Gap gap="24px" />
                        <OrderPopupItemLabel>
                          Přivezeme pro Vás:
                        </OrderPopupItemLabel>
                        <OrderPopupItemValue>
                          {orderCalcResult.amount}
                          {' '}
                          m&sup3; sypaných
                          {' '}
                          {orderCalcInputs.woodVariant !== 'beech'
                            ? orderCalcInputs.woodCategory === 'hard'
                              ? 'tvrdého dřeva'
                              : 'měkkého dřeva'
                            : orderCalcInputs.beechOrBeechMix === 'beech'
                              ? 'bukového dřeva'
                              : 'bukového dřeva (s příměsí)'}
                          {' '}
                          {orderCalcInputs.isDry && '(suché)'}
                        </OrderPopupItemValue>
                        <Gap gap="16px" />
                        <OrderPopupItemLabel>Na adresu:</OrderPopupItemLabel>
                        {!needAccurateAddress && (
                          <OrderPopupItemValue>
                            {orderCalcInputs.address?.address}
                            ,
                            {' '}
                            {orderCalcInputs.address?.city?.name}
                          </OrderPopupItemValue>
                        )}

                        {needAccurateAddress && (
                          <SLocationInput
                            name="address"
                            type="text"
                            // hint={addressHint}
                            placeholder="Ulice a číslo popisné, město"
                            onChange={(value) => {
                              if (value.source === 'addr') {
                                // console.log('precise address')
                                onAddressUpdate(value)
                              }
                            }}
                          />
                        )}

                        <OrderPopupItem
                          label="Aktuální průměrná doba dodání:"
                          value={`${deliveryTime} ${deliveryTimeUnit}`}
                          valueHint="Doba dodání zavisí na aktuálním vytížení dodavatele, počasí, a dalších faktorech. Po objednání vás dodavatel neprodleně kontaktuje a domluví s vámi termín závozu."
                        />

                        <OrderPopupItem
                          label="Způsob platby:"
                          value="Hotově při převzetí"
                          valueHint="Standardně se platí při převzetí přímo dodavateli v hotovosti. Během domlouvání termínu závozu se ale s dodavatelem můžete domluvit na jiné platební metodě, pokud to umožňuje."
                        />

                        <OrderPopupItem
                          label="Celková cena s dopravou:"
                          value={finalPriceText}
                          valueNote={
                            orderCalcResult.offer.includesVat && '(vč. DPH)'
                          }
                          valueHint="Cena je včetně dopravy a manipulace. Fakturu vám dodavatel vystaví po dodání."
                        />

                        <Gap gap="26px" />

                        <Title.SectionSubTitle
                          textAlign="left"
                          mobileTextAlign="left"
                        >
                          Kontaktní údaje
                        </Title.SectionSubTitle>

                        <Gap gap="8px" />
                      </Col>
                      <Col>
                        <Input name="name" type="text" label="Vaše jméno" />
                        <Input
                          name="email"
                          type="email"
                          label="E-mail"
                          hint="Shrnutí a kontakt na dodavatele vám pošleme na e-mail."
                        />
                        <Input
                          name="phone"
                          type="tel"
                          label="Telefon"
                          hint="Po objednání vám dodavatel neprodleně zavolá a domluví s vámi termín závozu."
                        />

                        {!showDiscountField && (
                          <Link
                            asSpan
                            blue
                            onClick={() => {
                              setShowDiscountCode((state) => !state)
                            }}
                          >
                            Mám slevový kód
                          </Link>
                        )}
                        {showDiscountField && (
                          <Input
                            name="discountCode"
                            type="text"
                            label="Slevový kód"
                            showSuccess
                            successLabel={`Slevový kód aktivní: -${discountCodeObj?.absoluteAmount} Kč`}
                          />
                        )}

                        <Gap gap="20px" />
                        <Button.FormikSubmitButton
                          preset="order"
                          realistic={!!dimensions.expIsNewCalculator}
                          isSubmitting={isSubmitting}
                        >
                          {isSubmitting ? (
                            'Objednávám...'
                          ) : (
                            <div>
                              <div>Objednat</div>
                              <ButtonSmallText>
                                s povinností platby
                              </ButtonSmallText>
                            </div>
                          )}
                        </Button.FormikSubmitButton>

                        <Gap gap="32px" />
                        <Text.Small maxWidth="300px" color="grey">
                          Stisknutím tlačítka “Objednat” souhlasíte s obchodními
                          podmínkami, které najdete
                          {' '}
                          <a target="_blank" href="/obchodni-podminky.pdf">
                            zde
                          </a>
                          .
                        </Text.Small>
                        <Gap gap="48px" />
                      </Col>
                    </Col>
                  </Form>
                </>
              </Popup>

              <UpdatePricePopup newAddress={values.address} />
            </>
          )
        }}
      </Formik>
    </ReactPortal>
  )
}

export const OrderPopupWithScrollLock = (props) => {
  const ref = useRef()

  // useEffect(() => {
  //   disableBodyScroll(ref.current)
  //   return () => {
  //     enableBodyScroll(ref.current)
  //   }
  // }, [])

  return <OrderPopup popupRef={ref} {...props} />
}
