import React, { Component, useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import Slider from '@material-ui/core/Slider'
import withStyles from '@material-ui/core/styles/withStyles'
import { useField, useFormikContext } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import HintTooltip from './HintTooltip'
import Error from './Error'
import Label from './Label'
import {
  addResponsivity,
  up,
  down,
  between,
  addFont,
  colors,
} from '../../lib/styles'
import { Col, Row, Text, Title, Gap, Link, VH, Button } from '..'
import { getColor } from '../Calculator/helpers'

const horizontalShaking = keyframes`

    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(5px);
    }
    50% {
      transform: translateX(-5px);
    }
    75% {
      transform: translateX(5px);
    }
    100% {
      transform: translateX(0);
    }
`

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
`

const StyledWrapper = styled.div`
  ${({ shake }) => css`
    width: 100%;
    translate: all 0.3s ease-in-out;
    & .MuiSlider-thumb {
      ${shake &&
      css`
        animation: ${horizontalShaking} 0.6s;
        animation-iteration-count: 3;
        animation-delay: 1.2s;
        /* animation-timing-function: linear; */

        /* animation-timing-function: cubic-bezier(0.36, 0.07, 0.19, 0.97);
        animation-timing-function: cubic-bezier(0.2, 0.72, 0.63, 1.03); */

        &::after,
        &::before {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z' fill='%23555'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3C/svg%3E");

          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          transform: translateX(-100%) rotate(90deg) scale(1.6);
          transform-origin: center;
          opacity: 1;
          transition: opacity 1.2s ease-in-out;
          animation: ${fadeOut} 4s;
          animation-delay: 3s;
          animation-fill-mode: forwards;
        }
        &::before {
          right: 0;
          transform: translateX(100%) rotate(-90deg) scale(1.6);
        }
      `}
    }
  `}
`

const useStyles = ({ rail, track, height = 8, thumbMarginTop = -8 } = {}) => makeStyles({
  root: {
    color: colors.orange,
    height,
    marginTop: -height,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: thumbMarginTop,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height,
    borderRadius: 40,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    // backgroundImage: bgGradient,
    // backgroundColor: 'transparent',
    ...track,
  },
  rail: {
    height,
    borderRadius: 40,
    backgroundColor: colors.checkRadioGrey,
    ...rail,
  },
})()

export const StyledSlider = (props) => {
  const classes = useStyles()

  return (
    <StyledWrapper shake={!props.noShake}>
      <Slider classes={classes} {...props} />
    </StyledWrapper>
  )
}

export const ColorizedPriceAmountSlider = (props) => {
  const { amountOffers } = props

  const sorted = [...amountOffers].sort((a, b) => a.price - b.price)

  const min = sorted[0].price
  const max = sorted[sorted.length - 1].price
  const diff = max - min

  // na zacatku grafu to jde prudce dolu, usekneme prvnich xx %
  const trimmedDiff = diff * 0.65

  const normalized = amountOffers.map((offer) => ({
    ...offer,
    normalizedPrice: Math.min((offer.price - min) / trimmedDiff, 1),
  }))

  // console.log(
  //   'min max diff',
  //   min,
  //   max,
  //   diff,
  //   sorted,
  //   normalized,
  //   sortedNormalized.map((o) => o.normalizedPrice),
  // )

  const step = 1 / (amountOffers.length - 1)

  const railbg = `linear-gradient(90deg, ${normalized
    .map((offer, i) => {
      const color = getColor(offer.normalizedPrice)
      // console.log('color', color, offer.normalizedPrice)
      const position = `${i * step * 100}%`
      return `${color} ${position}`
    })
    .join(', ')})`

  const rail = {
    backgroundImage: railbg,
    opacity: 0.99,
  }
  const track = {
    background: 'transparent',
    boxShadow: `${colors.orange} 0px 0px 10px 3px`,
    boxShadow:
      'rgba(255, 255, 255, 1) 0px 0px 0px 2px, rgba(255, 122, 0, 0.76) 0px 0px 5px 4px',
  }

  const classes = useStyles({ rail, track, height: 16, thumbMarginTop: -4 })

  return (
    <StyledWrapper shake>
      <Slider classes={classes} {...props} />
    </StyledWrapper>
  )
}

// export const StyledSlider = withStyles({
//   root: {
//     color: colors.orange,
//     height: 8,
//   },
//   thumb: {
//     height: 24,
//     width: 24,
//     backgroundColor: '#fff',
//     border: '2px solid currentColor',
//     marginTop: -8,
//     marginLeft: -12,
//     '&:focus, &:hover, &$active': {
//       boxShadow: 'inherit',
//     },
//   },
//   active: {},
//   valueLabel: {
//     left: 'calc(-50% + 4px)',
//   },
//   track: {
//     height: 8,
//     borderRadius: 4,
//   },
//   rail: {
//     height: 8,
//     borderRadius: 4,
//     backgroundColor: colors.checkRadioGrey,
//   },
// })(Slider)

const SliderValue = styled.label`
  ${({ theme: { colors } }) => css`
    ${addFont('signika')}
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
  `}
`

const printValueDefault = (value) => `${value} m³ sypaných / ${Math.ceil(value * 0.625 * 10) / 10} m³ rovnaných`

const SliderField = ({
  label,
  hint,
  max,
  min,
  name,
  printValue = printValueDefault,
  onChange,
  ...props
}) => {
  const [field, meta, helpers] = useField(name)
  const { value } = meta
  const { setTouched, setValue } = helpers

  // const formikContext = useFormikContext()

  const handleSliderChange = (event, newValue) => {
    setTouched()
    setValue(newValue)

    // onChange?.(newValue, formikContext)
  }

  return (
    <SliderBase
      hint={hint}
      name={name}
      label={label}
      value={field.value}
      min={min}
      max={max}
      onChange={handleSliderChange}
      printValue={printValue}
    />
  )
}

export default SliderField

export const SliderBase = ({
  hint,
  name,
  label,
  value,
  min,
  max,
  onChange,
  printValue,
}) => (
  <Col alignItems="center">
    <Col alignSelf="flex-start">
      <Row alignItems="center">
        <Label htmlFor={name}>{label}</Label>
        <HintTooltip hint={hint} />
      </Row>
    </Col>
    <Gap />
    <SliderValue>{printValue(value)}</SliderValue>
    <StyledSlider
      max={max}
      min={min}
      value={typeof value === 'number' ? value : 0}
      onChange={onChange}
    />

    {/* no errors, possibly do it in the future */}
  </Col>
)
