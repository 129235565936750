import React from 'react'
import styled, { css } from 'styled-components'
import { up } from '../lib/styles'

export const boxHoverCss = css`
  transition: 0.3s ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 3px;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.29) 0px 8px 24px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 20px;
  }
`

const Wrapper = styled.div`
  ${({
    theme,
    padding = '32px 40px',
    mobilePadding = '24px 32px',
    overflow,
    width,
    maxWidth,
    grow,
    background,
    noShadow,
    margin,
    mobileMargin,
    bp = 'menu',
  }) => css`
    border-radius: 16px;
    background: ${background || 'white'};
    padding: ${mobilePadding || padding};
    overflow: ${overflow};
    width: ${width};
    max-width: ${maxWidth};
    flex-grow: ${grow};
    margin: ${mobileMargin || margin};

    transition: 0.3s ease-in-out;

    ${!noShadow && boxHoverCss}

    ${up(bp)} {
      margin: ${margin};
      padding: ${padding};
    }

    &:hover {
      /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

      box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px; */
      /* box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 20px; */
      /* 
      box-shadow: rgba(255, 255, 255, 1) -3px -2px 17px -5px,
        rgba(0, 0, 0, 0.2) 4px 4px 17px -5px; */

      /* box-shadow: -6px -6px 14px rgba(255, 255, 255, .7),
              -6px -6px 10px rgba(255, 255, 255, .5),
              6px 6px 8px rgba(255, 255, 255, .075),
              6px 6px 10px rgba(0, 0, 0, .15);

    background: #f8f8f8; */
    }
  `}
`

export default function Box({ children, collapsed, ...props }) {
  const appendedProps = {}

  if (collapsed) {
    appendedProps.padding = '8px 40px'
    appendedProps.background = 'transparent'
    appendedProps.noShadow = true
  }

  return (
    <Wrapper {...appendedProps} {...props}>
      {children}
    </Wrapper>
  )
}
