// console.log(
//   'process.env.GATSBY_API_URLprocess.env.GATSBY_API_URLprocess.env.GATSBY_API_URL',
//   process.env.GATSBY_API_URL,
// )

module.exports = {
  // TODO: zatim tady. Je otazka, jak toto vyresit a odkud to brat, pokud nechcem na FE mit veci z BE
  // apiUrl: 'http://localhost:3000/api/v1/',
  apiUrl: process.env.GATSBY_API_URL,

  woodCategorySoft: 'soft',
  woodCategoryHard: 'hard',
  woodCategorySemiHard: 'semihard',

  woodTypeNames: {
    hardRaw: 'Tvrdé syrové',
    hardDry: 'Tvrdé suché',
    softRaw: 'Měkké syrové',
    softDry: 'Měkké suché',
  },

  deliveryTime: 7,
  deliveryTimeUnit: 'dní',

  vatMultiplier: 1.15,
}
