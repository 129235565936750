import React from 'react'
import styled, { css } from 'styled-components'
import ChevronRightIcon from './icons/ChevronRightIcon'

const Shadow = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  will-change: transform;
  transform: translateY(2px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
  filter: blur(1px);
`

const Edge = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(
    to left,
    hsl(340deg 100% 16%) 0%,
    hsl(340deg 100% 32%) 8%,
    hsl(340deg 100% 32%) 92%,
    hsl(340deg 100% 16%) 100%
  );
  background: linear-gradient(
    to left,
    rgb(135 73 2) 0%,
    rgb(190 100 0) 8%,
    rgb(195 103 0) 92%,
    rgb(158 84 0) 100%
  );
`

const Front = styled.div`
  display: block;
  position: relative;
  padding: 12px 42px;
  border-radius: 12px;

  /* font-size: 14px;
  text-transform: uppercase; */

  font-size: 18px;
  color: white;
  background: hsl(345deg 100% 47%);
  background: #f97c08;
  background: linear-gradient(180deg, #ffc04b, #f47f00);
  will-change: transform;
  transform: translateY(-4px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
`

const Pushable = styled.button`
  ${({ width, disabled }) => css`
    width: ${width};
    opacity: ${disabled ? 0.6 : 1};

    position: relative;
    border: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    outline-offset: 4px;
    transition: filter 250ms ease-out, opacity 0.3s ease-out;

    &:hover {
      filter: brightness(110%);

      ${Front} {
        ${!disabled &&
        css`
          /* transform: translateY(-6px);
          transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5); */
        `}
        transform: translateY(-6px);
        transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
      }
    }

    &:active ${Front} {
      transform: translateY(-2px);
      transition: transform 34ms;
    }

    &:hover ${Shadow} {
      ${!disabled &&
      css`
        /* transform: translateY(4px);
        transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5); */
      `}
      transform: translateY(4px);
      transition: transform 250ms cubic-bezier(0.3, 0.7, 0.4, 1.5);
    }
    &:active ${Shadow} {
      transform: translateY(1px);
      transition: transform 34ms;
    }
    &:focus:not(:focus-visible) {
      outline: none;
    }
  `}
`

const IconWrapper = styled.div`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);

  & > * {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
`

export default function ButtonRealistic({
  children,
  withNextIcon,
  onClick,
  ...props
}) {
  return (
    <Pushable onClick={onClick} {...props}>
      <Shadow />
      <Edge />
      <Front>
        {children}
        {withNextIcon && (
          <IconWrapper>
            <ChevronRightIcon />
          </IconWrapper>
        )}
      </Front>
    </Pushable>
  )
}
