import React, { useState } from 'react'
import { formatCurrency } from '../lib/helpers'
import Col from './Col'
import Gap from './Gap'
import Sort from './icons/sort'
import Row from './Row'
import Table from './Table'
import Text from './Text'

const HeaderText = (props) => (
  <Text fontSize="16px" lineHeight="24px" fontWeight="700" {...props} />
)

const ColText = (props) => (
  <Text fontSize="16px" lineHeight="16px" fontWeight="400" {...props} />
)

const SortSign = ({ isActive, isUp }) => (
  <>
    <Gap gap="16px" />
    <Col>
      <Sort isActive={isUp && isActive} />
      <Gap gap="4px" />
      <Sort isActive={!isUp && isActive} down />
    </Col>
  </>
)

function sortDataBaseFn({ data, sortProp, isNumberSort, sortUp }) {
  return data.sort((rowA, rowB) => {
    const a = rowA[sortProp]
    const b = rowB[sortProp]
    let result
    if (isNumberSort) {
      // a = a.replace(',', '.')
      // b = b.replace(',', '.')
      result = b - a
    } else {
      result = a && b && b.localeCompare(a)
    }
    if (sortUp) result = -result
    return result
  })
}

const useTableSorting = () => {
  const [sortProp, setSortProp] = useState()
  const [sortUp, setSortUp] = useState(true)
  const [isNumberSort, setIsNumberSort] = useState(true)
  const setSort = (newSortProp, isNumberSort = false) => {
    setSortProp(newSortProp)
    setSortUp(newSortProp === sortProp ? !sortUp : sortUp)
    setIsNumberSort(isNumberSort)
  }

  return {
    sortProp,
    setSortProp,
    sortUp,
    setSortUp,
    isNumberSort,
    setIsNumberSort,
    setSort,
    sortData: (data) => sortDataBaseFn({ data, sortProp, isNumberSort, sortUp }),
  }
}

const TableHeaderCell = ({ setSort, sortProp, sortUp, header }) => (
  <Table.Header onClick={() => setSort(header.key, header.isNumberSort)}>
    <Row alignItems="center">
      <HeaderText>{header.title}</HeaderText>
      {!header.notSortable && (
        <SortSign isActive={sortProp === header.key} isUp={sortUp} />
      )}
    </Row>
  </Table.Header>
)

function SortableTable({ data, headers, withOverflowGradient, ...props }) {
  const { sortData, ...tableSortingHelpers } = useTableSorting()

  const sortedData = sortData(data)

  // na sticky header neni jednoduche reseni...
  return (
    <Table withOverflowGradient={withOverflowGradient} {...props}>
      <thead>
        <Table.HeaderRow>
          {headers.map((header) => (
            <TableHeaderCell
              key={header.key}
              header={header}
              {...tableSortingHelpers}
            />
          ))}
        </Table.HeaderRow>
      </thead>
      <tbody>
        <tr style={{ height: '12px' }} />
        {sortedData.map((row) => (
          <Table.Row key={row.id || row._id || row.key}>
            {headers.map((header) => (
              <Table.Col key={header.key}>
                <Col
                  cursor={header.onCellClick ? 'pointer' : ''}
                  maxWidth="300px"
                  onClick={() => header.onCellClick?.(row)}
                >
                  <ColText fontWeight="700" {...header.textProps}>
                    {header.formatCell
                      ? header.formatCell(row[header.key], row)
                      : row[header.key]}
                  </ColText>
                </Col>
              </Table.Col>
            ))}
          </Table.Row>
        ))}
        <tr style={{ height: '12px' }} />
      </tbody>
    </Table>
  )
}

export default SortableTable
