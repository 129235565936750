import React from 'react'

export default function ChartIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#0585D2" />
      <g clipPath="url(#clip0_5217_686)">
        <path
          d="M6.24242 3.68164V17.7616H20.3224V19.5216H4.48242V3.68164H6.24242ZM18.4557 7.82405L19.7 9.06837L14.6779 14.0905L12.0379 11.4514L8.26001 15.2284L7.01569 13.9841L12.0379 8.96189L14.6779 11.601L18.4557 7.82405Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5217_686">
          <rect
            x="1.8418"
            y="1.04102"
            width="21.12"
            height="21.12"
            rx="6"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
