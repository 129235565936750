import axios from 'axios'
import React from 'react'

class ApiError extends Error {
  constructor(originalError) {
    super(originalError.message)
    // console.log('originalError?.response', originalError?.response)
    this.errors = originalError?.response?.data?.errors
    this.error = originalError?.response?.data?.error
    this.error = originalError?.response?.data?.error
    this.data = originalError?.response?.data
    this.name = originalError?.response?.data?.name || 'ApiError'
  }
}

const useApiCall = ({ method = 'get', path, data: body, headers = {}, params }) => {
  // throws error
  const apiCall = async (data, runtimePath) => {
    try {
      const response = await axios({
        method,
        baseURL: '/api/v1',
        baseURL:
          process.env.NODE_ENV === 'development'
            ? 'http://127.0.0.1:3000/api/v1'
            : '/api/v1',
        // baseURL: 'http://127.0.0.1:3000/api/v1',
        url: runtimePath || path,
        data: data || body,
        headers,
        params,
      })
      return response.data.data
    } catch (error) {
      console.log('errrrrrrrr', error.response.data.errors)
      throw new ApiError(error)
    }
  }

  return [apiCall]
}

export default useApiCall
