import { findSavedAddress } from './address'

export const initialAddress = { address: '' }

export const getInitialValuesByVariant = (variant, woodVariant = 'all') => {
  const address = findSavedAddress()
  let defaultValues = {
    woodCategory: 'hard',
    variety: null,
    address: address || initialAddress,
    isDry: false,
    amount: 5,
    // calcUglyToo: true,
    exactAmountOnly: false,
    variant,
    woodVariant,
  }

  if (woodVariant === 'beech') {
    defaultValues = {
      ...defaultValues,
      beechOrBeechMix: 'beech',
      woodCategory: null,
      // variety: 'beech',
      isDry: true,
      // calcUglyToo: false,
    }
  }

  if (variant === 'amount') {
    delete defaultValues.amount
  }

  return defaultValues
}
