import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { addResponsivity, up, down, between } from '../lib/styles'
import { Col, Row, Text, Title, Gap, Link, Button } from '.'

const Wrapper = styled.div`
  ${({ solved }) => css`
    display: ${solved ? 'none' : 'flex'};
    flex-direction: column;
    align-items: center;
    padding: 16px;
    position: fixed;
    position: relative;
    background: white;
    z-index: 100;
    bottom: 0;
    width: 100%;
    box-shadow: 0 2px 8px rgba(64, 82, 171, 0.16);

    pointer-events: auto;
  `}
`
const InnerWrapper = styled.div`
  ${({
    theme: {
      sizes: { maxPageWidth },
    },
  }) => css`
    max-width: ${maxPageWidth}px;
  `}
`

class CookiesFooter extends Component {
  state = {
    solved: true,
  }

  componentDidMount() {
    const solved = window.localStorage.getItem('cookiesSolved')
    this.setState({ solved })
  }

  solveCookies = () => {
    this.setState({ solved: true })
    window.localStorage.setItem('cookiesSolved', true)
  }

  render() {
    const { solved } = this.state
    return (
      <Wrapper solved={solved}>
        <InnerWrapper>
          <Row alignItems="center" justifyContent="stretch" responsive>
            <Text>EU FOOTER - cookies</Text>
            <Gap gap="24px" />
            <Row shrink="0" justifyContent="stretch" alignItems="center">
              <Button height="40px" padding="0 16px" onClick={this.solveCookies}>
                V pořádku
              </Button>
              <Gap gap="16px" />
              <Col shrink="0">dgdfgdfg</Col>
            </Row>
          </Row>
        </InnerWrapper>
      </Wrapper>
    )
  }
}

CookiesFooter.propTypes = {}

export default CookiesFooter
