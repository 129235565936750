import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { useField, useFormikContext } from 'formik'
import HintTooltip from './HintTooltip'
import Error from './Error'
import Label from './Label'
import {
  addResponsivity,
  up,
  down,
  between,
  addFont,
  colors,
} from '../../lib/styles'
import { Col, Row, Text, Title, Gap, Link, VH, Button } from '..'

import checkboxTickPath from '../../data/images/checkbox-tick.svg'

const CheckboxWrapper = styled.div`
  ${({ extendedPadding }) => css`
    cursor: pointer;
    padding: ${extendedPadding ? '8px' : '0'};
    margin: ${extendedPadding ? '-8px' : '0'};
  `}
`

const StyledCheckbox = styled.div`
  ${({ theme: { colors }, extendedPadding, error, checked }) => css`
    background: white;
    border-radius: 6px;
    border: solid 2px ${colors.checkRadioGrey};
    width: 24px;
    height: 24px;
    position: relative;
    cursor: pointer;

    &:after {
      display: block;
      position: absolute;
      width: 19px;
      height: 18px;
      content: '';
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) translateX(-1px);
      background: url(${checkboxTickPath});
      opacity: 0;
      transition: 0.15s;
    }

    ${checked &&
    css`
      /* background: white;
     */
      &:after {
        opacity: 1;
      }
    `}
  `}
`

const CheckboxField = ({
  name,
  hint,
  interactiveHint,
  extendedPadding,
  label,
  small,
  ...props
}) => {
  const [field, meta, helpers] = useField(name)
  const { value } = meta
  const { setTouched, setValue } = helpers

  const handleChange = (e) => {
    setTouched()
    setValue(!value)
    // setFieldValue() // sadly this is necessary to trigger persistence
    e.stopPropagation()
  }

  return (
    <Checkbox
      name={name}
      value={value}
      handleChange={handleChange}
      extendedPadding={extendedPadding}
      label={label}
      hint={hint}
      interactiveHint={interactiveHint}
      fieldName={field.name}
      withFormikError
      small={small}
    />
  )
}

export default CheckboxField

export const Checkbox = ({
  name,
  value,
  handleChange,
  extendedPadding,
  label,
  hint,
  fieldName,
  withFormikError,
  interactiveHint,
  small,
  ...props
}) => (
  <Col>
    <Row alignItems="center">
      <VH>
        <input
          id={name}
          {...props}
          type="checkbox"
          checked={value}
          onChange={handleChange}
        />
      </VH>
      <CheckboxWrapper extendedPadding={extendedPadding} onClick={handleChange}>
        <StyledCheckbox checked={value} />
      </CheckboxWrapper>
      <Gap />
      <Label htmlFor={name} pointer small={small}>
        {label}
      </Label>
      <HintTooltip hint={hint} interactive={interactiveHint} />
    </Row>
    {withFormikError && <Error name={fieldName || name} />}
  </Col>
)
