import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Text from './Text'
import Row from './Row'
import Col from './Col'
import Gap from './Gap'
import Link from './Link'
import { up, addPagePadding } from '../lib/styles'
import packageInfo from '../../package.json'

const { version } = packageInfo

const Wrapper = styled.div`
  ${({ theme: { sizes, colors } }) => css`
    background-color: ${colors.footerGreen};
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 80px 40px;
    color: white;
    font-size: 16px;
    line-height: 36px;
  `}
`

const Footer = ({ config, ...props }) => !config.hide ? (
  <Wrapper>
    <Row responsive alignSelf="center">
      <Col>
        <div>Cross Orders s.r.o.</div>
        <div>IČ: 03488454</div>
        <Link.FooterLink
          asA
          href="/obchodni-podminky-b2c.pdf"
          target="_blank"
          fontSize="inherit"
        >
          Obchodní podmínky B2C
        </Link.FooterLink>
      </Col>

      <Gap gap="80px" mobileGap="20px" />

      <Col>
        <Link.FooterLink to="/" anchor="calculator">
          Srovnavač palivového dřeva
        </Link.FooterLink>
        <Link.FooterLink to={Link.CALC_FIREPLACE} anchor="calculator">
          Srovnavač dřeva do krbu
        </Link.FooterLink>
        <Link.FooterLink to={Link.CALC_BEECH} anchor="calculator">
          Srovnavač bukového dřeva
        </Link.FooterLink>
        <Link.FooterLink to={Link.CALC_UNITS} anchor="calculator">
          Převod PRMS/PRMR
        </Link.FooterLink>
      </Col>

      <Gap gap="80px" mobileGap="20px" />

      <Col>
        <Link.FooterLink to={Link.PRICE_MAP}>Cenová mapa</Link.FooterLink>
        <Link.FooterLink to={Link.GLOSSARY}>Slovníček</Link.FooterLink>
        <Link.FooterLink to={Link.BLOG}>Blog</Link.FooterLink>
        <Link.FooterLink to="/pridali-jsme-casto-kladene-otazky">
          Často kladené otázky
        </Link.FooterLink>
        <Link.FooterLink to={Link.NEW_WOODMAKER}>
          Pro dodavatele
        </Link.FooterLink>
      </Col>
      <Gap gap="80px" mobileGap="20px" />

      <Col>
        <Link.FooterLink
          asA
          target="_blank"
          href="https://www.facebook.com/srovnejdrevo"
        >
          Facebook
        </Link.FooterLink>
        <Link.FooterLink
          asA
          target="_blank"
          href="https://www.instagram.com/srovnejdrevo/"
        >
          Instagram
        </Link.FooterLink>
      </Col>
    </Row>
    <Gap gap="20px" />

    <Text
      color="version"
      fontSize="14px"
      fontWeight="300"
      textAlign="center"
      color="white"
    >
      © 2020 -
      {' '}
      {new Date().getFullYear()}
      {' '}
      (v
      {version}
      )
    </Text>
  </Wrapper>
) : null

Footer.defaultProps = {}

Footer.propTypes = {}

export default Footer
