import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import {
  addResponsivity,
  up,
  down,
  between,
  addFont,
  colors,
} from '../../lib/styles'
import { Col, Row, Text, Title, Gap, Link, VH, Button } from '..'

const Label = styled.label`
  ${({ theme: { colors }, pointer, small, fontSize = '18px' }) => css`
    ${addFont('signika')}
    font-weight: 700;
    font-size: ${fontSize};

    line-height: 22px;

    ${small &&
    css`
      font-size: 16px;
      line-height: 20px;
    `}

    ${pointer && 'cursor: pointer;'}

    & > span {
      font-size: 16px;
    }
  `}
`

export default Label
