import React from 'react'
import styled, { css } from 'styled-components'
import { up, addResponsivity } from '../lib/styles'
import ReactPortal from './ReactPortal'

const PopupOverlay = styled.div`
  ${({ theme: { colors }, show }) => css`
    position: fixed;
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10000;
    backdrop-filter: blur(12px);

    ${addResponsivity}
  `}
`

const PopupWrapper = styled.div`
  ${({ theme: { colors }, withBottomGradient, mobileMaxWidth }) => css`
    display: flex;
    background: white;
    position: absolute;
    top: 0;
    border-radius: 16px;
    transform: translateX(-50%);
    max-height: 100vh;
    max-height: calc(100vh - 32px);
    max-height: calc(100vh - 110px);
    margin-top: 16px;
    margin-bottom: 16px;
    left: 50%;
    margin-left: 0;
    margin-right: 8px;
    overflow-y: hidden;
    max-width: ${mobileMaxWidth};
    width: 100%;
    width: calc(100% - 4px);

    box-shadow: rgba(0, 0, 0, 0.35) 0px 4px 55px 0px;

    ${up('mobile')} {
      max-width: 100%;
      max-width: calc(100% - 32px);
      margin-left: 0;
      margin-right: 0;
      width: calc(100% - 16px);
      width: auto;
      max-height: calc(100vh - 80px);
      margin-top: 24px;
    }

    ${withBottomGradient &&
    css`
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 160px;
        background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        pointer-events: none;
      }
    `}
  `}
`

const PopupBody = styled.div`
  ${({ theme: { colors }, padding, paddingMobile, color }) => css`
    padding: ${paddingMobile || padding || '24px 20px'};
    overflow-y: auto;
    max-height: 100%;
    width: 100%;
    position: relative;
    background: ${colors[color] || color || 'white'};

    ${up('mobile')} {
      padding: ${padding || '24px 40px'};
    }
  `}
`

const AbsoluteContentWrapper = styled.div`
  ${({ theme: { colors }, absoluteContentCss }) => css`
    position: absolute;
    z-index: 0;
    ${absoluteContentCss}
  `}
`

export const PopupPositioner = styled.div`
  ${({ theme: { colors } }) => css`
    position: absolute;
  `}
`

// uncontrolled popup
function Popup({
  children,
  onPopupOverlayClick,
  popupRef,
  absoluteContent,
  withBottomGradient,
  absoluteContentCss,
  desktop,
  mobileMaxWidth,
  portalId,
  padding,
  paddingMobile,
  bgColor,
}) {
  const Wrapper = portalId ? ReactPortal : React.Fragment

  return (
    <Wrapper wrapperId={portalId}>
      <PopupOverlay onClick={onPopupOverlayClick} desktop={desktop}>
        <PopupWrapper
          withBottomGradient={withBottomGradient}
          mobileMaxWidth={mobileMaxWidth}
        >
          <AbsoluteContentWrapper absoluteContentCss={absoluteContentCss}>
            {absoluteContent}
          </AbsoluteContentWrapper>
          <PopupBody
            id="popup-body" // for scrolling in calc popup
            ref={popupRef}
            onClick={(e) => {
              e.stopPropagation()
            }}
            padding={padding}
            paddingMobile={paddingMobile}
            color={bgColor}
          >
            {children}
          </PopupBody>
        </PopupWrapper>
      </PopupOverlay>
    </Wrapper>
  )
}
export default Popup
