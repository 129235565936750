import * as Yup from 'yup'

const idRegex = /^[0-9]{8,10}$/

const vatIdRegex = /^(CZ)?[0-9]{8,10}$/

const skVatIdRegex = /^(SK)?[0-9]{10}$/

// eslint-disable-next-line import/prefer-default-export
export const subscriptionSchema = Yup.object({
  email: Yup.string().email('Není emailová adresa').required('Povinné'),
})

export const feedbackSchema = Yup.object({
  message: Yup.string().trim().min(5, 'Alespoň 5 znaků').required('Povinné'),
  contact: Yup.string().trim().min(5, 'Alespoň 5 znaků').required('Povinné'),
})

export const woodMakerContactSchema = Yup.object({
  contact: Yup.string().trim().min(5, 'Alespoň 5 znaků').required('Povinné'),
})

export const sendCalcSchema = Yup.object({
  email: Yup.string().email('Není emailová adresa').required('Povinné'),
})

export const orderPopupSchema = Yup.object({
  name: Yup.string()
    .max(50, 'Maximálně 50 znaků')
    .min(5, 'Alespoň 5 znaků')
    .required('Povinné'),
  email: Yup.string().email('Není emailová adresa').required('Povinné'),
  phone: Yup.string().required('Povinné'),
  // acceptedTerms: Yup.boolean().required('Povinné'),
})

export const orderCartSchema = Yup.object({
  name: Yup.string()
    .max(50, 'Maximálně 50 znaků')
    .min(5, 'Alespoň 5 znaků')
    .required('Povinné'),
  email: Yup.string().email('Není emailová adresa').required('Povinné'),
  phone: Yup.string().required('Povinné'),
  companyName: Yup.string().when('companyBuy', {
    is: (companyBuy) => !!companyBuy,
    then: Yup.string().min(5, 'Alespoň 5 znaků').required('Povinné'),
    otherwise: Yup.string(),
  }),
  companyId: Yup.string().when('companyBuy', {
    is: (companyBuy) => !!companyBuy,
    then: Yup.string().matches(idRegex, 'Neplatné IČ').required('Povinné'),
    otherwise: Yup.string(),
  }),
  companyVatId: Yup.string().when('companyBuy', {
    is: (companyBuy) => !!companyBuy,
    then: Yup.string().test('companyVatId', 'Neplatné DIČ', (value) => {
      const regexes = [vatIdRegex, skVatIdRegex]
      return regexes.some((regex) => regex.test(value)) || !value
    }),
    otherwise: Yup.string(),
  }),
})

export const orderReviewSchema = Yup.object({
  wouldRecommendWoodMaker: Yup.string().required('Povinné'),
  orderStatus: Yup.string().required('Povinné'),
})
