import {
  differenceInMilliseconds,
  formatDistanceToNow,
  isFuture,
} from 'date-fns'
import React, {
  useEffect,
  useRef,
  useState,
  Fragment,
  useCallback,
  useMemo } from 'react'
import { cs } from 'date-fns/locale'

import { toast } from 'react-toastify'

import useLocalStorageState from 'use-local-storage-state'

import styled from 'styled-components'
import { useFetchNotifs } from './hooks/useQueries'
import Gap from './Gap'
import Icon from './Icon'
import { makeFirstLetterUppercase } from '../../lib/text'
import { useExperiments } from './hooks/useExperiments'
import Link from './Link'
import { shuffleArray } from '../../lib/arrays'

/**
  - zmena notif dat --> refresh timoutu, pokud driv, zobrazit hned
  - refresh prohlizece / novy mount
      - A) budoucnost, cajk
      - B) je to v historii -> nastavit novy timeout od zacatku
*/

const StyledMessage = styled.div`
  font-size: 15px;
  color: #414141;
`
const Button = styled.div`
  font-size: 13px;
  color: #0087ff;
  cursor: pointer;
  font-weight: 600;
`
const StyledTime = styled.div`
  font-size: 12px;
  color: #999;
`

const getNotShownNotifs = (notifs = [], shownIds = {}) => notifs.filter((n) => !shownIds[n.id])

const getNextNotif = (notifs = [], shownIds = {}) => {
  // console.log('getNextNotif', notifs, shownIds)
  const filteredNotifs = getNotShownNotifs(notifs, shownIds)

  return filteredNotifs[0]
}

const Notif = ({ closeToast, toastProps, notif, type }) => (
  <div>
    <StyledMessage>
      {notif.text.split('**').map((part, i) => (
        <Fragment key={i}>
          {i % 2 === 1 ? <strong>{part}</strong> : part}
        </Fragment>
      ))}
    </StyledMessage>

    {notif.date && (
      <>
        <Gap gap="4px" />
        <StyledTime>
          {makeFirstLetterUppercase(
            formatDistanceToNow(new Date(notif.date), {
              addSuffix: true,
              locale: cs,
            }),
          )}
        </StyledTime>
      </>
    )}
    {type === 'withButton' && (
      // {type === 'on' && (
      <>
        <Gap gap="4px" />

        <Link to="/" anchor="calculator">
          <Button>Porovnat nabídky</Button>
        </Link>
      </>
    )}
  </div>
)

const showToast = (notif, { type } = {}) => toast(<Notif notif={notif} type={type} />, {
  position: toast.POSITION.BOTTOM_LEFT,
  autoClose: notif.autoClose,
  icon: <Icon name={notif.icon} color={notif.iconColor} />,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
})

function Notifs({ notifsType }) {
  const { data, dataUpdatedAt } = useFetchNotifs()
  let shuffledData = data?.notifs

  shuffledData = useMemo(() => {
    if (data?.notifs && notifsType === 'shuffled') {
      console.log('shufelling')
      return shuffleArray(data.notifs)
    }
    return shuffledData
  }, [data])

  // console.log('shuffledData', shuffledData)

  // const dataRef = useRef(data?.notifs)
  // const updated =
  //   JSON.stringify(dataRef.current) !== JSON.stringify(data?.notifs)
  // dataRef.current = data?.notifs

  // useEffect(() => {
  //   console.log('notifs data updated')
  // }, [updated])

  // console.log('fetched notifs data', dataUpdatedAt, data)
  // console.log('renders')

  const wasNotifScheduled = useRef(null)
  const sessionNotifShowAt = useRef(null)

  const timeoutRef = useRef(null)
  clearTimeout(timeoutRef.current)

  // const [lastShownAt, setLastShownAt] = useLocalStorageState(
  //   'notifs-lastShownAt',
  //   new Date(),
  // // )
  // if (!lastShownAt) {
  //   // if wrong local storage
  //   setLastShownAt(new Date())
  // }

  // map is better than array
  // const [shownIds, setShownIds] = useLocalStorageState('notifs-shownIds', {})

  const [state, setState] = useLocalStorageState('notifs', {
    shownIds: {}, // map is better than array
    lastShownAt: new Date(),
  })

  if (!state.lastShownAt) {
    // if wrong local storage
    setState({ ...state, lastShownAt: new Date() })
  }

  // console.log('state', state)

  const nextNotif = getNextNotif(shuffledData, state.shownIds)

  const showNotif = (notif) => {
    // console.log('showing notif NOW', notif.id)
    showToast(notif, { type: notifsType })
    setState({
      ...state,
      lastShownAt: new Date(),
      shownIds: { ...state.shownIds, [notif.id]: new Date() },
    })
  }

  if (nextNotif) {
    // console.log('next notif', nextNotif)

    const continuityShowAt = new Date(
      new Date(state.lastShownAt).getTime() + nextNotif.secondsToShow * 1000,
    )

    // console.log('continuityShowAt', continuityShowAt)

    const showAt = isFuture(continuityShowAt)
      ? // next notif is in the future
      continuityShowAt
      : // in the past, was sth already shown this session (mount)?
      wasNotifScheduled.current
        ? // yes --> only data update, let it in the past and show it now
        continuityShowAt
        : // no --> this is first time after browser load --> reset date to now and add seconds
        // to show (this is browser refresh after some while), preserve it, for repeated renders
        sessionNotifShowAt.current ||
        new Date().getTime() + nextNotif.secondsToShow * 1000

    sessionNotifShowAt.current = showAt

    // console.log('FINAL showAt', showAt)

    if (isFuture(showAt)) {
      const diff = differenceInMilliseconds(showAt, new Date())
      // console.log('notif is in the future -->', diff, showAt)

      timeoutRef.current = setTimeout(() => {
        showNotif(nextNotif)
        // console.log('trigger notif timeout!')
      }, diff)
      wasNotifScheduled.current = true
    } else {
      // console.log('notif is in the past')
      showNotif(nextNotif)
      wasNotifScheduled.current = true
    }
  } else {
    // console.log('no next notif')
    if (data?.notifs) {
      // if we have data, then reset so it cycles
      setState({ ...state, shownIds: {} })
    }
  }

  return null
}

const NotifsEnabler = (Component) => () => {
  const { dimensions } = useExperiments()

  // console.log('notifs dim:', dimensions?.notifs)

  if (dimensions.notifs === 'off') {
    return null
  }

  return <Component notifsType={dimensions.notifs} />
}

export default NotifsEnabler(Notifs)
