import { formatDistance, isBefore } from 'date-fns'
import { cs } from 'date-fns/locale'
import React from 'react'
import styled from 'styled-components'
import { InfoIcon } from './fields/HintTooltip'
import Gap from './Gap'
import { useFetchInfoBarMessage } from './hooks/useQueries'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  background: #6a2d00;
  justify-content: center;
  padding: 8px;
  font-size: 14px;
  align-items: center;
  color: white;
`
function InfoBar(props) {
  const { data } = useFetchInfoBarMessage()

  if (!data || !data.length) {
    return null
  }

  const messages = data.filter((m) => isBefore(new Date(), new Date(m.validTill)))

  if (!messages || !messages.length) {
    return null
  }

  const message = messages[Math.floor(Math.random() * messages.length)]

  const diff = formatDistance(new Date(message.time), new Date(), {
    addSuffix: true,
    locale: cs,
  })

  const text = message.message.replace('{{timeDiff}}', diff)

  return (
    <Wrapper>
      <InfoIcon />
      <Gap />
      <div>{text}</div>
    </Wrapper>
  )
}

export default InfoBar
