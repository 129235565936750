import React from 'react'
import styled, { css } from 'styled-components'
import { cs } from 'date-fns/locale'

import { formatDistance } from 'date-fns'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { Menu, Footer } from '../components'
import CookiesFooter from '../components/CookiesFooter'
import { InfoIcon } from '../components/fields/HintTooltip'
import { up } from '../lib/styles'
import App from './App'
import pagesConfig from '../../pagesConfig'
import StickyFooter from '../components/StickyFooter'
import { useFetchInfoBarMessage } from '../components/hooks/useQueries'
import InfoBar from '../components/InfoBar'
import Notifs from '../components/Notifs'
import { useExperiments } from '../components/hooks/useExperiments'

// import AllPopups from '../components/AllPopups'

const StyledToastContainer = styled(ToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&&.Toastify__toast-container {
  }
  .Toastify__toast {
    border-radius: 8px;
  }

  &&&.Toastify__toast-container--bottom-left {
    @media only screen and (min-width: 480px) {
      left: 16px;
      bottom: 16px;
    }
  }

  .Toastify__toast-icon {
    width: 40px;
    height: 40px;

    svg {
      width: 100%;
    }
  }
`

const PageWrapper = styled.div`
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
`

// const FixedWrapper = styled.div`
//   position: fixed;
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   z-index: 100;
//   pointer-events: none;
// `

function DefaultLayout(props) {
  // these props are exactly same as pageProps injected by gatsby
  // console.log('this.props LAYOUT', this.props)
  const {
    location: { pathname },
    pageContext: { trimmedPath, pageKey },
    path,
    children,
  } = props

  const finalTrimmedPath = path?.endsWith('/') ? path.slice(0, -1) : path

  const configKey = pageKey || finalTrimmedPath
  const pageConfig = pagesConfig[configKey] || {}
  // console.log(
  //   'finalTrimmedPath',
  //   finalTrimmedPath,
  //   configKey,
  //   path,
  //   pageConfig,
  //   pageConfig,
  // )

  const { bare } = pageConfig

  if (bare) {
    return (
      <App trimmedPath={trimmedPath} pageConfig={pageConfig}>
        <PageWrapper>{children}</PageWrapper>
      </App>
    )
  }
  return (
    <App trimmedPath={trimmedPath} pageConfig={pageConfig}>
      {/* <AllPopups /> */}
      <InfoBar />
      <StyledToastContainer limit={1} />
      <Notifs />
      <PageWrapper>
        <Menu pathname={pathname} config={pageConfig.menu || {}} />
        {children}
        <Footer config={pageConfig.footer || {}} />
        {/* <StickyFooter /> */}
      </PageWrapper>
      {/* <FixedWrapper>
          <CookiesFooter style={{ pointerEvents: 'auto' }} />
        </FixedWrapper> */}
    </App>
  )
}

export default DefaultLayout
