import React, { useEffect, useCallback } from 'react'

const useOnScroll = callback => {
  let win = null
  if (typeof window !== 'undefined') {
    win = window
  }

  const handleScroll = useCallback(
    () => {
      const result = {
        scrollTop: win.scrollY,
      }
      callback(result)
    },
    [callback, win],
  )

  useEffect(() => {
    handleScroll()
    win.addEventListener('scroll', handleScroll)

    return () => {
      win.removeEventListener('scroll', handleScroll)
    }
  }, [win, handleScroll])
}

export default useOnScroll
