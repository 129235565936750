import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { up, addResponsivity, upBp } from '../lib/styles'

const Gap = styled.div`
  ${({
    gap,
    mobileGap,
    shrink,
    basis,
    mobileBasis,
    grow,
    mobileGrow,
    maxWidth,
    hidden,
    mobileMaxWidth,
    // bp, // use this top override default bp
    onlyWidth, // sets only width gap, height is 0
    // onlyHeight,
    // theme: { bp: themeBp = 'mobile' },
  }) => css`
    display: ${hidden ? 'none' : 'block'};
    flex: 0 0 ${mobileGap || gap};
    width: ${mobileGap || gap};
    height: ${mobileGap || gap};
    height: ${onlyWidth && 0};
    flex-grow: ${mobileGrow || grow};
    flex-shrink: ${shrink};
    flex-basis: ${mobileBasis || basis};
    max-width: ${mobileMaxWidth || maxWidth};

    /* ${up('mobile')} { */
    ${upBp()} {
      flex: 0 0 ${gap};
      width: ${gap};
      height: ${gap};
      height: ${onlyWidth && 0};
      max-width: ${maxWidth};
      flex-basis: ${basis};
      flex-grow: ${grow};
    }

    ${addResponsivity()}
  `}
`

Gap.defaultProps = {
  gap: '8px',
}

Gap.propTypes = {
  gap: PropTypes.string,
}

// intro gap for almost every page. Menu height is 120px and on mobile 80px
Gap.Top = () => <Gap gap="54px" mobileGap="24px" />

/**
 * pouziti:
 * - min - minimalni velikost, pravdepodobne ji nebude dosazeno (hlavne na desktopu v row), spis takovy cil
 * - grow, kalsicky grow v danem kontextu
 * - max je max...
 *
 * obdobne pro mobile, lze nastavit vlastni BP  bp={123}
 * pokud chci na mobilu fixni, tak tuto schovam a dam si tam
 * normalni, lepsi nez tu delat gulas - napr. <Gap.Fluid desktop />
 * ukazka - changes.js v intro sekci
 *
 * TODO: zatim testovano jen na Row
 */

Gap.Fluid = ({
  min = '16px',
  mobileMin,
  grow = '1',
  mobileGrow = '1',
  max = '90px',
  mobileMax,
  ...rest
}) => (
  <Gap
    gap={min}
    mobileGap={mobileMin}
    basis={min}
    mobileBasis={mobileMin || min}
    grow={grow}
    mobileGrow={mobileGrow}
    maxWidth={max}
    mobileMaxWidth={mobileMax}
    // shrink="1"
    {...rest}
  />
)

export const presets = {
  Standard: {
    gap: '40px',
    mobileGap: '24px',
  },
}

Object.keys(presets).forEach((key) => {
  Gap[key] = (props) => <Gap {...presets[key]} {...props} />
})

export default Gap

//  TODO: fluid variant??
// const FluidGap = styled.div`
//   flex: 0 0 32px;
//   ${up(breakpoint)} {
//     flex: 5 3 64px;
//   }
// `
