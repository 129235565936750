import React from 'react'

import { useExperiments } from '../hooks/useExperiments'
import GraphResultsView from './ResultsView.Graph'
import GraphResultsText from './ResultsView.Text'

export default function ResultsView(props) {
  const { dimensions } = useExperiments()

  return !dimensions.expIsNewCalculator ? (
    <GraphResultsView {...props} />
  ) : (
    <GraphResultsText {...props} />
  )
}
