import React from 'react'
import styled, { css } from 'styled-components'
import { up, addFont, addResponsivity } from '../lib/styles'

const TextComponent = styled.div`
  ${({
    theme: { colors },
    fontSize = '16px',
    fontWeight = 400,
    lineHeight = '24px',
    padding,
    margin,
    upperCase,
    letterSpacing,
    maxWidth,
    mobileFontSize,
    mobileLineHeight,
    bp = 'mobile',
    noWrap,
    color,
    fontStyle,
    textAlign,
    alignSelf,
    displayAs = 'block',
    appendCss,
  }) => css`
    align-self: ${alignSelf};
    font-weight: ${fontWeight};
    text-align: ${textAlign};
    color: ${colors[color] || color || colors.text};
    ${addFont('signika')}
    white-space: ${noWrap && 'nowrap'};
    padding: ${padding};
    margin: ${margin};
    text-transform: ${upperCase && 'uppercase'};
    letter-spacing: ${letterSpacing};
    max-width: ${maxWidth};
    display: inline;
    font-style: ${fontStyle};

    font-size: ${mobileFontSize || fontSize};
    line-height: ${mobileLineHeight || lineHeight};

    ${up(bp)} {
      font-size: ${fontSize};
      line-height: ${lineHeight};
    }

    ${addResponsivity({ displayAs })}

    ${appendCss}
  `}
`

// define basic styles used on page

// toto je vychozi text na 60 % stranky
// nekdy je na mobilu responzivni --> zvetsi se
// pouziti napr: blog widget item (pod clanekm); nadace intro text + pribehy widget item + event, FAQ, image title, dotaz paticka - text + label
const Text = (props) => (
  <TextComponent
    fontSize="18px"
    lineHeight="26px"
    mobileFontSize={props.responsive ? '18px' : undefined}
    mobileLineHeight={props.responsive ? '30px' : undefined}
    {...props}
  />
)

Text.BlogText = styled(TextComponent).attrs(() => ({
  fontSize: '18px',
  lineHeight: '26px',
  fontWeight: '300',
}))`
  & strong {
    font-weight: 600;
  }
`

// vetsi intro text na hore strany
// promeny intro (jina barva), zakroky intro (barva), tym intro...
// pouzit i v co rikaji nasi klienti, ale lehce jina barva
Text.Intro = (props) => (
  <TextComponent fontSize="18px" lineHeight="30px" {...props} />
)
// blog perex, nadace podtitul,
// --> jako IntroText ale jina line-height: 28px;
// Text.BlogContentText = props => (
//   <Text fontSize="16px" lineHeight="26px" {...props} />
// ) --> udelat primo v blogu, unikatni pouziti

// kurziva - na webu jen jedna - blog author, nadace event date, co rikaji nasi klienti
Text.Italic = (props) => (
  <TextComponent
    fontSize="14px"
    lineHeight="26px"
    italic
    italicGray
    mobileFontSize={props.responsive ? '18px' : undefined}
    mobileLineHeight={props.responsive ? '27px' : undefined}
    {...props}
  />
)

// nejmensi normalni text ma 15px
// blog - banner; nadace claims, podtitul zakroku, zakrok item perex
Text.Small = (props) => (
  <TextComponent
    fontSize="15px"
    lineHeight="23px"
    mobileFontSize={props.responsive ? '18px' : undefined}
    mobileLineHeight={props.responsive ? '30px' : undefined}
    {...props}
  />
)

Text.Error = styled(Text)`
  ${({ theme: { colors } }) => css`
    color: ${colors.red};
    font-weight: 700;
  `}
`

Text.OrderDetailBasic = (props) => (
  <TextComponent
    fontSize="18px"
    lineHeight="32px"
    textAlign="center"
    maxWidth="550px"
    {...props}
  />
)

Text.SmallGrayText = (props) => (
  <TextComponent
    fontSize="16px"
    lineHeight="26px"
    textAlign="center"
    color="textGrey"
    {...props}
  />
)

export default Text
