const {
  CALC_BEECH,
  CALC_FIREPLACE,
  CALC_AMOUNT,
  NEW_WOODMAKER,
  HOMEPAGE,
  CALCULATOR_IFRAME,
  PRICE_MAP,
  BLOG,
  GLOSSARY,
  CART,
  CART_STEP_2,
  CART_STEP_3,
  ORDER_FEEDBACK,
} = require('./src/lib/links').links
// eslint-disable-next-line no-multi-assign
module.exports = exports = {
  [HOMEPAGE]: {
    menu: {
      absolute: true,
    },
    // used for editor page list, if omitted, page wont be listed
    // only fill for static pages
    pageName: 'Homepage',
    to: '/',

    // used for multiple similar static pages generation
    // variantPaths: [CALC_BEECH, CALC_FIREPLACE],
  },
  [CALC_BEECH]: {
    menu: {
      absolute: true,
    },
    // used for editor page list, if omitted, page wont be listed
    // only fill for static pages
    pageName: 'Kalkulacka bukove drevo',
    to: CALC_BEECH,
  },
  [CALC_FIREPLACE]: {
    menu: {
      absolute: true,
    },
    // used for editor page list, if omitted, page wont be listed
    // only fill for static pages
    pageName: 'Kalkulacka drevo do krbu',
    to: CALC_FIREPLACE,
  },
  [CALCULATOR_IFRAME]: {
    bare: true, // iframes, no standard page features, menus apod.
  },
  // [CALC_AMOUNT]: {
  //   menu: {
  //     absolute: true,
  //   },
  // },
  [NEW_WOODMAKER]: {
    // used for editor page list, if omitted, page wont be listed
    // only fill for static pages
    pageName: 'Novy dodavatel',
    to: NEW_WOODMAKER,
  },
  [BLOG]: {
    pageName: 'Blog',
    to: BLOG,
  },
  [PRICE_MAP]: {
    pageName: 'Cenova mapa',
    to: PRICE_MAP,
  },
  [CART]: {
    pageName: 'Košík',
    to: CART,
    menu: {
      dark: true,
      minimal: true,
    },
    footer: {
      hide: true,
    },
  },
  [CART_STEP_2]: {
    pageName: 'Košík 2',
    to: CART_STEP_2,
    menu: {
      dark: true,
      minimal: true,
    },
    footer: {
      hide: true,
    },
  },
  [CART_STEP_3]: {
    pageName: 'Košík 3',
    to: CART_STEP_3,
    menu: {
      dark: true,
      minimal: true,
    },
    footer: {
      hide: true,
    },
  },
  [ORDER_FEEDBACK]: {
    pageName: 'Hodnoceni formular',
    to: ORDER_FEEDBACK,
    menu: {
      dark: true,
      minimal: true,
    },
    footer: {
      hide: true,
    },
  },
  [GLOSSARY]: {
    pageName: 'Slovnicek',
    to: GLOSSARY,
  },
  '/thanks': {
    enabledPopups: false,
    menu: {
      dark: true,
    },
  },
  '/thanks-partner': {
    enabledPopups: false,
    bare: true, // iframes, no standard page features, menus apod.
    menu: {
      dark: true,
    },
  },
  PostTemplate: {
    withSlugs: true, // all template needs slugs
    fallbackPage: '/', // key to page
    menu: {
      dark: true,
    },
  },
  MdPostTemplate: {
    fallbackPage: '/', // key to page
    menu: {
      dark: true,
    },
  },
  MdGlossaryTemplate: {
    fallbackPage: '/', // key to page
    menu: {
      dark: true,
    },
  },
}
