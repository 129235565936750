import React, { useCallback, useEffect } from 'react'
import { nanoid } from 'nanoid'
import Bugsnag from '@bugsnag/js'
import Loading from '@kiwicom/orbit-components/lib/Loading'
import styled from 'styled-components'
import { navigate } from 'gatsby'
import CalcForm from './CalcForm'
import { useMergedState } from '../hooks/useMergedState'
import { useCustomerId } from '../hooks/useUserId'
import { getInitialValuesByVariant } from './initialValues'
import { useServerCalculateMutation, withShareCalcResults } from './hooks'
import { useCalcMutation } from '../hooks/useQueries'
import {
  useCalculationExperiments,
  useOpenCartExperiments,
} from './experiments'
import { Col, Gap, Link, Row, Title } from '..'
import { up } from '../../lib/styles'
import { CalcLoader } from './Loader'
import ResultsView from './ResultsView'
import { OrderPopup, OrderPopupWithScrollLock } from './OrderPopup'
import { useGlobalContext } from '../GlobalContext'
import { useExperiments } from '../hooks/useExperiments'
import { links } from '../../lib/links'
import { usePopup, usePopups } from '../hooks/usePopups'
import { popupTypes } from '../PopupsProvider'

const saveAmountIndexToLocalStorage = (amountIndex) => {
  typeof window !== 'undefined' &&
    localStorage.setItem('calc-amountIndex', amountIndex)
}

const getAmountIndexFromLocalStorage = () => {
  const retrieved =
    typeof window !== 'undefined' && localStorage.getItem('calc-amountIndex')

  // console.log('retrievedretrieved', retrieved)

  if (typeof retrieved === 'undefined' || retrieved === null) {
    return undefined
  }

  const parsed = parseInt(retrieved, 10)

  if (Number.isNaN(parsed) || !retrieved) {
    return undefined
  }

  return parsed
}

// wrapper for data flow, form, and results
function Calculator(props) {
  const {
    variant,
    woodVariant,
    noArrows,
    onCalcFormikSubmit,
    onCalcDataFetched,
    instantCalc,
    overwriteValues,
    noTitle,
    noSharing,
  } = props

  const { dimensions } = useExperiments()
  const { sendCalculationEvents } = useCalculationExperiments()
  const { sendOpenCart } = useOpenCartExperiments()
  const {
    globalInputs,
    setGlobalInputs,
    orderCalcInputs,
    setOrderCalcInputs,
    orderCalcResult,
    setOrderCalcResult,
    setWasRecentlyCalculated,
  } = useGlobalContext()

  const defaultAmountIndexNewCalc = getAmountIndexFromLocalStorage() || 7

  const [state, setStateCore] = useMergedState({
    showOrderPopup: false,
    invalidResults: false,
    wasCalced: false,
    amountsResultId: null,
    submittedCalcValues: null,
    // amountIndex: -1, // this is shared amount slider index
    // amountIndex: dimensions.expIsNewCalculator ? 7 : -1,
    amountIndex: dimensions.expIsNewCalculator ? defaultAmountIndexNewCalc : -1,
    // calcFormKey: nanoid(),
  })

  // console.log(
  //   'dimensions',
  //   dimensions,
  //   dimensions.expIsNewCalculator,
  //   defaultAmountIndexNewCalc,
  //   state.amountIndex,
  // )

  useEffect(() => {
    if (state.amountIndex === -1 && dimensions.expIsNewCalculator) {
      setState({ amountIndex: 7 })
      console.log('index amount is -1, update it to 7')
    }
  }, [dimensions.expIsNewCalculator, state.amountIndex])

  const setState = useCallback(
    (newState) => {
      if (newState.amountIndex !== undefined) {
        saveAmountIndexToLocalStorage(newState.amountIndex)
      }
      setStateCore(newState)
    },
    [setStateCore],
  )

  useEffect(() => {
    if (state.amountIndex !== undefined) {
      saveAmountIndexToLocalStorage(state.amountIndex)
    }
  }, [state.amountIndex])

  const {
    calculateAsync,
    isCalculating,
    amountOffers,
    calcResultData,
    restrictedMinimum,
    pickedOffer,
    ...rest
  } = useServerCalculateMutation({ pickedIndex: state.amountIndex })

  useEffect(() => {
    if (
      restrictedMinimum &&
      restrictedMinimum?.amountIndex !== -1 &&
      state.amountIndex === -1
    ) {
      setState({ amountIndex: restrictedMinimum.amountIndex })
    }
  }, [restrictedMinimum])

  const initialValues = {
    ...getInitialValuesByVariant(variant, woodVariant),
    ...overwriteValues,
  }

  useEffect(() => {
    if (instantCalc) {
      handleCalcFormikSubmit(initialValues, {}, { skipExperimentsEvents: true })
    }
  }, [])

  // user clicked on calc button, can be triggered from outside
  const handleCalcFormikSubmit = async (
    values,
    { setSubmitting = () => {} } = {},
    { skipExperimentsEvents } = {},
  ) => {
    setSubmitting(true)
    onCalcFormikSubmit?.(values)

    if (!skipExperimentsEvents) {
      sendCalculationEvents(values)
    }

    // console.log('sending calc with values ', values)

    try {
      setWasRecentlyCalculated()
      const data = await calculateAsync(values)

      // console.log('!!!datadatadata', data.isLatest)

      if (data.isLatest) {
        // only if latest data for latest inputs
        setState({
          submittedCalcValues: values,
          invalidResults: false,
          wasCalced: true,
        })
      }

      onCalcDataFetched?.(values, data)

      // TODO: check with other versions like popup, upravit resultId aby slo cachedDat a ne techto
      setTimeout(() => {
        // console.log('scrolling', data?.data?.amountsResultId, data)
        Link.scrollTo(data?.data?.amountsResultId, -30)
        Link.scrollTo(data?.data?.amountsResultId, -30, {
          container: document.getElementById('popup-body'),
        })
      }, 100)
    } catch (error) {
      console.log('server error', error)
      alert('Nastala chyba, kontaktujte nas prosim.')
      console.log('server calc error', error)
      Bugsnag.notify(error)
    }

    setSubmitting(false)
  }

  const handleAmountChange = (index, cb) => {
    setState({ amountIndex: index }, () => cb?.())
  }

  const closeOrderPopup = (callback) => {
    setState({ showOrderPopup: false })

    // setTimeout(() => {
    //   // reacreate form to reset it
    //   setState({ calcFormKey: nanoid() })
    // }, 400)
  }

  const openOrderPopup = () => {
    setState({
      showOrderPopup: true,
    })
  }

  const editCalcPopup = usePopup(popupTypes.EDIT_CALC)
  const { closeAllPopups } = usePopups()

  // console.log('pickedOfferpickedOffer', pickedOffer)
  const handleStartOrder = ({ amountIndex = state.amountIndex } = {}) => {
    const pickedOffer = amountOffers[amountIndex]

    sendOpenCart()

    // set global context state for popup or cart
    setOrderCalcInputs({
      ...state.submittedCalcValues,
      amountIndex,
    })
    setOrderCalcResult(pickedOffer)

    if (dimensions.expIsNewCart) {
      navigate(links.CART)
      // editCalcPopup.closePopup()
      closeAllPopups()
    } else {
      openOrderPopup()
    }
  }

  const formProps = {
    variant,
    woodVariant,
    noArrows,
    initialValues,
    overwriteValues,
    wasCalced: state.wasCalced,
    isCalculating,
    onCalcFormikSubmit: handleCalcFormikSubmit,
    onFormikValuesChange: (values, errors) => {
      setState({ invalidResults: true })
    },
    amountIndex: state.amountIndex,
    handleAmountChange,
    amountOffers,
  }

  const resultsProps = {
    invalidResults: state.invalidResults,
    wasCalced: state.wasCalced,
    isCalculating,
    isSubmitting: isCalculating,
    variant,
    woodVariant,
    amountIndex: state.amountIndex,
    amountsResultId: calcResultData?.amountsResultId,
    amountsResultShortId: calcResultData?.amountsResultShortId,
    calcValues: state.submittedCalcValues,
    amountOffers,
    handleAmountChange,
    handleStartOrder,
    pickedOffer,
    noSharing,
  }

  // console.log('CALC - init, over', initialValues, overwriteValues, formProps, resultsProps)

  return (
    <>
      {!noTitle && (
        <>
          <Title.SectionTitle color="orange" textAlign="center">
            Jaká je vaše cena?
          </Title.SectionTitle>

          <Gap gap="32px" mobileGap="40px" />
        </>
      )}

      <Row
        alignItems="flex-start"
        justifyContent="center"
        responsive
        bp={dimensions.expIsNewCalculator ? 800 : 1200}
        mobileAlignItems="center"
      >
        <CalcForm
          {...formProps}
          // key={state.calcFormKey}
        />

        {state.wasCalced && (
          <Gap.Fluid min="40px" max="80px" mobileMin="24px" />
        )}

        {isCalculating && !state.wasCalced && (
          <Row>
            <Gap gap="40px" mobileGap="0px" />
            <Col>
              <Gap gap="180px" mobileGap="40px" />
              <CalcLoader />
            </Col>
          </Row>
        )}

        {state.wasCalced && <ResultsView {...resultsProps} />}

        {state.showOrderPopup && (
          <OrderPopupWithScrollLock
            variant={variant}
            woodVariant={woodVariant}
            onPopupOverlayClick={closeOrderPopup}
            closePopup={closeOrderPopup}
          />
        )}
      </Row>
    </>
  )
}

export default withShareCalcResults(Calculator)

export const CalculatorWithoutShareResults = Calculator
