import React, { useState, useMemo, useRef } from 'react'
import useLocalStorageState from 'use-local-storage-state'
import { useMergedPersistentState } from './hooks/useMergedState'

// Create a new context
const GlobalContext = React.createContext()

// TODO: provider mozna presunout nekam do ssr / browser, aby se nemusel renderovat na kazde strance
export function GlobalContextProvider({ children, ...props }) {
  // TODO: localStorage everywhere

  // mainly for the address
  const [globalInputs, setGlobalInputs] = useLocalStorageState(
    'globalInputs',
    {},
  )

  const [isOrderPopupOpen, setIsOrderPopupOpen] = useState(false)
  const [wasRecentlyCalculated, setWasRecentlyCalculated] = useState(false)

  const recentlyCalculatedTimer = useRef(null)

  // when you add calc to cart / order popup
  // these are calc inputs / calcValues
  // includes picked amount index
  const [orderCalcInputs, setOrderCalcInputs, resetOrderCalcInputs] =
    useMergedPersistentState('globalOrderCalcInputs', {})
  // when you add calc to cart / order popup
  // result of the calc for order popup / cart
  // includes picked amount index
  const [orderCalcResult, setOrderCalcResult, resetOrderCalcResult] =
    useMergedPersistentState('globalOrderCalcResult', {})

  const [cartState, setCartState, resetCartState] = useMergedPersistentState(
    'globalCart',
    {
      visitedStep2: false,
      discountCode: null,
      // products: [], // in the future product can be merged object of input and result
      // TODO: customer info... formik?
    },
  )

  const value = useMemo(
    () => ({
      globalInputs,
      setGlobalInputs,
      cartState,
      setCartState,
      orderCalcInputs,
      setOrderCalcInputs,
      orderCalcResult,
      setOrderCalcResult,
      isOrderPopupOpen,
      setIsOrderPopupOpen,

      wasRecentlyCalculated,
      setWasRecentlyCalculated: () => {
        setWasRecentlyCalculated(true)
        clearTimeout(recentlyCalculatedTimer.current)
        recentlyCalculatedTimer.current = setTimeout(() => {
          setWasRecentlyCalculated(false)
          console.log('reset wasRecentlyCalculated')
        }, 3 * 60 * 1000)
      },

      resetGlobalOrderCartState: () => {
        resetOrderCalcInputs()
        resetOrderCalcResult()
        resetCartState()

        // cuz of redirection didnt have time to happen
        setCartState({ orderSent: true })
        setTimeout(() => {
          resetCartState()
        }, 1000)
      },
      isCartEmpty: () => !Object.keys(orderCalcInputs)?.length ||
        !Object.keys(orderCalcResult)?.length,
    }),
    [
      globalInputs,
      setGlobalInputs,
      cartState,
      setCartState,
      orderCalcInputs,
      setOrderCalcInputs,
      orderCalcResult,
      setOrderCalcResult,
      isOrderPopupOpen,
      setIsOrderPopupOpen,
      wasRecentlyCalculated,
    ],
  )
  // console.log('🚀 global context render', value)

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  )
}

export const useGlobalContext = () => React.useContext(GlobalContext)
