import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import { useCollapse } from 'react-collapsed'
import { addResponsivity } from '../lib/styles'

// Visibility and Responsivity helper
const VH = styled.div`
  ${addResponsivity()}

  ${({ show, displayAs = 'block' }) => css`
    display: ${show ? displayAs : 'none'};
  `}
`

export default VH

// const AnimatedVHWrapper = styled.div`
//   ${addResponsivity()}

//   ${({ show, displayAs = 'block', maxHeight = '200px' }) => css`
//     display: ${displayAs};
//     overflow: hidden;
//     transition: 0.4s ease-in-out;
//     /* max-height: ${show ? maxHeight : '0'}; */
//     opacity: ${show ? '1' : '0'};
//     transition-delay: ${show ? '0.2s' : '0s'};
//   `}
// `

export const useAnimatedVH = (props) => {
  const { collapsed } = props
  const { getCollapseProps, getToggleProps, setExpanded } = useCollapse({
    isExpanded: !collapsed,
  })

  const AnimatedVH = <VH {...props} {...getCollapseProps()} />

  return { AnimatedVH, getToggleProps, setExpanded }
}

export const AnimatedVH = (props) => {
  const { show, ...rest } = props

  const [internalShow, setInternalShow] = useState(show)

  const { getCollapseProps, getToggleProps, setExpanded } = useCollapse({
    isExpanded: show,
    defaultExpanded: show,
    // duration: 300,
  })

  // useEffect(() => {
  //   setExpanded(show)

  //   console.log('setting', show)
  // }, [show])

  return <VH show {...rest} {...getCollapseProps()} />
}
