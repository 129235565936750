import useLocalStorageState from 'use-local-storage-state'
import React, { useCallback, useEffect, useState } from 'react'
import { isBefore, subDays, subMinutes, subSeconds } from 'date-fns'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import styled, { css } from 'styled-components'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import Popup from './Popup'
import { Input } from './fields'
import Title from './Title'
import Gap from './Gap'
import Button from './Button'
import { PersistFormikValues } from '../lib/PersistFormikValues'
import Col from './Col'
import Text from './Text'
import HintTooltip, { InfoIcon } from './fields/HintTooltip'
import CloseIcon from './CloseIcon'
import { useFeedbackPopupMutation } from './hooks/useQueries'
import { feedbackSchema } from '../validations/forms'
import usePopupFormAndMutation from './hooks/usePopupFormAndMutation'
import { Link, RH, Row } from '.'

const PopupContent = styled.div`
  width: 100%;
  max-width: 800px;
  width: min(800px, 85vw);
`

const absoluteContentCss = css`
  right: 16px;
  bottom: -4px;
`

function useMouseTracking(callback) {
  useEffect(() => {
    function trackMouse(event) {
      if (
        event.clientY <= 0 ||
        event.clientX <= 0 ||
        event.clientX >= window.innerWidth ||
        event.clientY >= window.innerHeight
      ) {
        console.log("I'm out")
        callback()
      }
    }

    document.addEventListener('mouseleave', trackMouse, false)

    return () => {
      document.removeEventListener('mouseleave', trackMouse)
    }
  }, [callback])
}

function FeedbackPopup({
  isOpen,
  setPopupState,
  openPopup,
  closePopup,
  type,
  storageKey = 'do-not-leave',
  showFaq = true,
  messagePlaceholder = 'Třeba že...  “nechápu tu kalkulačku...”, “chci vidět historii cen”, “chci tady tohle a tamto”,...',
  title = 'Nenašli jste, co jste hledali?',
  appendValues,
}) {
  const [lastShownAt, setLastShownAt] = useLocalStorageState(storageKey, null)

  const {
    formMutation,
    onSubmit,
    formikPersistInstance,
  } = usePopupFormAndMutation({
    type,
    appendValues,
  })

  // disable auto show popup for now
  // const memoizedSetShowPopup = useCallback(() => {
  //   const shouldShow =
  //     !lastShownAt ||
  //     // isBefore(new Date(lastShownAt), subSeconds(new Date(), 10))
  //     isBefore(new Date(lastShownAt), subDays(new Date(), 7))

  //   if (shouldShow) {
  //     openPopup()
  //     setLastShownAt(new Date())
  //   }
  // }, [openPopup, setLastShownAt, lastShownAt])

  // useMouseTracking(memoizedSetShowPopup)

  function closeThisPopup() {
    closePopup()
    if (!formMutation.isLoading) {
      formMutation.reset()
    }
  }

  return isOpen ? (
    <Popup
      // desktop
      // onPopupOverlayClick={closeThisPopup}
      absoluteContentCss={absoluteContentCss}
      absoluteContent={(
        <div>
          <RH showAfter={680}>
            <StaticImage
              src="../data/images/popup-bg.png"
              width={640}
              layout="constrained"
            />
            {' '}
          </RH>
        </div>
      )}
    >
      <PopupContent>
        <CloseIcon onClick={closeThisPopup} />
        <Title.SectionTitle>{title}</Title.SectionTitle>
        <Gap gap="8px" />
        <Title.SectionSubTitle textAlign="left">
          Napište nám to!
        </Title.SectionSubTitle>
        <Gap gap="8px" />
        <Formik
          initialValues={{
            message: '',
            contact: '',
          }}
          validationSchema={feedbackSchema}
          onSubmit={onSubmit}
        >
          {({ submitForm, setFieldValue, isSubmitting, ...rest }) => (
            <>
              <Form>
                {formikPersistInstance}
                <Col maxWidth="445px" alignItems="flex-start" width="100%">
                  <Input
                    disabled={formMutation.isSuccess || formMutation.isLoading}
                    as="textarea"
                    customCss={css`
                      height: 100px;
                    `}
                    name="message"
                    type="text"
                    label="Vaše zpráva"
                    placeholder={messagePlaceholder}
                    withoutGap
                    width="100%"
                  />
                  {showFaq && (
                    <Col alignItems="flex-end" width="100%">
                      <HintTooltip
                        noIcon
                        title={(
                          <span>
                            Pokud jste našli levnějšího dodavatele než naše
                            kalkulačka, pošlete nám na něj odkaz a popis vaší
                            objednávky: množství, typ dřeva - tvrdé / měkké,
                            syrové / suché a vaší adresu. Zkusíme vám najít
                            ještě lepší cenu. Pokud se nám to nepodaří, zkusíme
                            s dodavatelem navázat spolupráci a pokud to vyjde,
                            tak vás čeká peněžitá odměna za doporučení.
                          </span>
                        )}
                      >
                        <div>Je to jinde levnější?</div>
                      </HintTooltip>
                      <Link
                        to="/"
                        anchor="faq"
                        target="_blank"
                        onClick={() => {
                          closePopup()
                        }}
                      >
                        Často kladené otázky
                      </Link>
                    </Col>
                  )}

                  <Gap gap="16px" />

                  <Input
                    required
                    disabled={formMutation.isSuccess || formMutation.isLoading}
                    name="contact"
                    type="text"
                    label="Email a/nebo telefon"
                    customCss={css`
                      width: 100%;
                      width: 240px;
                    `}
                  />

                  <Gap gap="16px" />
                  {!formMutation.isSuccess && (
                    <Button.FormikSubmitButton>
                      {formMutation.isLoading ? 'Odesílám...' : 'Odeslat'}
                    </Button.FormikSubmitButton>
                  )}

                  {formMutation.isSuccess && (
                    <Text color="green">✅ &nbsp; Odesláno, děkujeme!</Text>
                  )}
                  <Gap gap="32px" />
                </Col>
              </Form>
            </>
          )}
        </Formik>
      </PopupContent>
    </Popup>
  ) : null
}

export default FeedbackPopup
