import React, { useMemo } from 'react'
import Button from '../Button'
import SortableTable from '../SortableTable'
import { BasicTooltip } from '../fields/HintTooltip'
import Col from '../Col'
import { classicRound, formatCurrency } from '../../lib/helpers'
import {
  colorizePrice,
  formatPriceVatMessage,
  formatTotalPriceVatMessage,
} from './helpers'
import { ColorizedSpan } from '../Colorizer'

const tableCtaFormatCell = (raw, row) => (
  <Button preset="small" onClick={row.handleCtaClick}>
    Vybrat
  </Button>
)

export const AmountResultsTableView = (props) => {
  const { data, handleAmountChange, handleStartOrder } = props

  // console.log('datadata', data)

  const tableData = useMemo(
    () => data.map((item) => ({
      ...item,
      'amount-2': item.amount * 0.625,
      handleCtaClick: () => {
        handleAmountChange(item.amountIndex)
        handleStartOrder({ amountIndex: item.amountIndex })
      },
      color: colorizePrice({
        amountOffers: data,
        amountIndex: item.amountIndex,
      }),
    })),
    [data],
  )

  return (
    <Col padding="24px 32px">
      <SortableTable headers={headers} data={tableData} minWidth="100%" />
    </Col>
  )
}

const numberHeaderProps = {
  isNumberSort: true,
  textProps: {
    noWrap: true,
  },
}

const headers = [
  {
    title: 'm³ sypané',
    key: 'amount',
    ...numberHeaderProps,
  },
  {
    title: 'm³ rovnané',
    key: 'amount-2',
    ...numberHeaderProps,
    formatCell: (raw) => classicRound(raw, 2),
  },
  {
    title: 'Cena za m³ vč. dopravy',
    key: 'price',
    ...numberHeaderProps,
    formatCell: (raw, row) => (
      <BasicTooltip
        hint={<Col textWrap="pretty">{formatPriceVatMessage(row)}</Col>}
        leaveDelay={100}
      >
        <ColorizedSpan color={row.color}>
          {formatCurrency(raw, 0)}
        </ColorizedSpan>
      </BasicTooltip>
    ),
  },
  {
    title: 'Celková cena s dopravou',
    key: 'finalTotalPrice',
    ...numberHeaderProps,
    formatCell: (raw, row) => (
      <BasicTooltip
        hint={<Col textWrap="pretty">{formatTotalPriceVatMessage(row)}</Col>}
        leaveDelay={100}
      >
        <span>{formatCurrency(raw, 0)}</span>
      </BasicTooltip>
    ),
  },
  {
    title: '',
    key: 'cta',
    notSortable: true,
    formatCell: tableCtaFormatCell,
  },
]
