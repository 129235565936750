import useLocalStorageState from 'use-local-storage-state'
import React, { useCallback, useEffect, useState } from 'react'
import { isBefore, subDays, subMinutes, subSeconds } from 'date-fns'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import styled, { css } from 'styled-components'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useLocation } from '@reach/router'
import Popup from './Popup'
import { Input } from './fields'
import Title from './Title'
import Gap from './Gap'
import Button from './Button'
import { PersistFormikValues } from '../lib/PersistFormikValues'
import Col from './Col'
import Text from './Text'
import HintTooltip, { InfoIcon } from './fields/HintTooltip'
import CloseIcon from './CloseIcon'
import { useFeedbackPopupMutation } from './hooks/useQueries'
import { feedbackSchema } from '../validations/forms'
import usePopupFormAndMutation from './hooks/usePopupFormAndMutation'
import { Link, RH, Row } from '.'
import Calculator, {
  CalculatorWithoutShareResults,
} from './Calculator/Calculator'

const CalculatorContent = () => (
  <Col>
    <Gap gap="32px" />
    <CalculatorWithoutShareResults variant="amount" noTitle noArrows />
    <Gap gap="32px" />
  </Col>
)

export default CalculatorContent
