import { useField } from 'formik'
import React from 'react'
import ReactSlider from 'react-slider'
import styled, { css, keyframes } from 'styled-components'
import { addFont } from '../../lib/styles'
import Col from '../Col'
import Gap from '../Gap'
import Row from '../Row'
import Text from '../Text'
import HintTooltip from './HintTooltip'
import Label from './Label'

const SliderValue = styled.label`
  ${({ theme: { colors } }) => css`
    ${addFont('signika')}
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  `}
`

const StyledSlider = styled(ReactSlider)`
  ${({ orientation }) => css`
    ${orientation === 'horizontal' &&
    css`
      height: 26px;
      width: 100%;
    `}
    ${orientation === 'vertical' &&
    css`
      height: 200px;
      width: 26px;
    `}
    position: relative;
    box-sizing: border-box;
    /* If slider's movement is too quick --> disable popup initial open animation! */
  `}
`

const StyledThumb = styled.div`
  ${({ theme: { colors } }) => css`
    height: 24px;
    line-height: 24px;
    width: 24px;
    text-align: center;
    background-color: ${colors.primary};
    background-color: white;
    box-shadow: 0px 0 2px 1px rgb(0 0 0 / 30%);

    color: #fff;
    border-radius: 50%;
    cursor: grab;
    transform: translateY(-50%);
    top: 50%;
    transition: 0.3s box-shadow;

    &:hover {
      box-shadow: 0px 0 7px 1px rgb(0 0 0 / 30%);
    }
    &:active {
      box-shadow: 0px 0 7px 3px rgb(0 0 0 / 30%);
    }
  `}
`
// you can print value here via state.valueNow
const Thumb = (props, state) => <StyledThumb {...props} />

const StyledTrack = styled.div`
  ${({ theme: { colors }, index }) => css`
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 999px;

    background: #ebebeb;
    border: solid 1px #dbdbdb;
    background: ${index === 0 && 'white'};

    ${index === 0 &&
    css`
      /* border-radius: 999px 999px 0 0;
      border-bottom: none; */
    `}

    ${index === 1 &&
    css`
      /* border-top: none;
      border-radius: 0 0 999px 999px; */
    `}
  `}
`

const Track = (props, state) => <StyledTrack {...props} index={state.index} />

function SliderRangeBase({
  minLabel,
  maxLabel,
  orientation = 'horizontal',
  ...props
}) {
  const Wrapper = orientation === 'vertical' ? Col : Row

  return (
    <Wrapper alignItems="center">
      {minLabel && (
        <>
          <Text>{minLabel}</Text>
          <Gap gap="8px" />
        </>
      )}
      <StyledSlider
        renderTrack={Track}
        renderThumb={Thumb}
        orientation={orientation}
        {...props}
      />
      {maxLabel && (
        <>
          <Gap gap="8px" />
          <Text>{maxLabel}</Text>
        </>
      )}
    </Wrapper>
  )
}

const printValueDefault = (value) => `${value} m³ sypaných / ${Math.ceil(value * 0.625 * 10) / 10} m³ rovnaných`

export const SliderBase = ({
  hint,
  name,
  label,
  value,
  min,
  max,
  onChange,
  printValue,
}) => (
  <Col alignItems="stretch">
    <Col alignSelf="flex-start">
      <Row alignItems="center">
        <Label htmlFor={name}>{label}</Label>
        <HintTooltip hint={hint} />
      </Row>
    </Col>
    <Gap />
    <Col alignItems="center">
      <SliderValue>{printValue(value)}</SliderValue>
    </Col>
    <Gap gap="4px" />
    <SliderRangeBase
      max={max}
      min={min}
      value={typeof value === 'number' ? value : 0}
      onChange={onChange}
    />

    {/* no errors, possibly do it in the future */}
  </Col>
)

const SliderField = ({
  label,
  hint,
  max,
  min,
  name,
  printValue = printValueDefault,
  onChange,
  ...props
}) => {
  const [field, meta, helpers] = useField(name)
  const { value } = meta
  const { setTouched, setValue } = helpers

  const handleSliderChange = (newValue) => {
    setTouched()
    setValue(newValue)
  }

  return (
    <SliderBase
      hint={hint}
      name={name}
      label={label}
      value={field.value}
      min={min}
      max={max}
      onChange={handleSliderChange}
      printValue={printValue}
    />
  )
}

export const SliderControlled = (props) => {
  const {
    label,
    hint,
    max,
    min,
    name,
    printValue = printValueDefault,
    onChange,
    value,
  } = props

  const handleSliderChange = (newValue) => {
    onChange(newValue)
  }

  return (
    <SliderBase
      hint={hint}
      name={name}
      label={label}
      value={value}
      min={min}
      max={max}
      onChange={handleSliderChange}
      printValue={printValue}
    />
  )
}

// make array of objects with amount property from 1 to 30
const amountOffersDefault = Array.from({ length: 30 }, (_, i) => ({
  amount: i + 1,
}))

export const AmountSlider = (props) => {
  const {
    amountOffers = amountOffersDefault,
    amountIndex,
    handleAmountChange,
  } = props

  const printValue = (amountIndex) => printValueDefault(amountOffers[amountIndex]?.amount) || ''

  return (
    <SliderControlled
      max={amountOffers.length - 1}
      min={0}
      step={1}
      label="Množství"
      onChange={handleAmountChange}
      value={amountIndex}
      printValue={printValue}
      hint={(
        <>
          Hledejte požadované množství (s vyšším množstvím je obvykle cena
          nižší), přepočet na 1 metr se zobrazí ve výsledku pod otazníkem.
          <br />
          <br />
          Průměrný český dům spotřebuje na vytápění asi 10 sypaných m³ ročně.
          Levnější je mít zásoby pro případ tuhé zimy. (Někdy se lze setkat také
          s jednotkou PRMS a PRMR, což je akorát jiné pojmenování pro m³ sypané
          a rovnané).
        </>
      )}
      {...props}
    />
  )
}

export default SliderField
