import React from 'react'
import styled, { css } from 'styled-components'
import Icon from '../Icon'

export const CheckIcon = styled(Icon).attrs({
  size: '24px',
  name: 'InputCheck',
})`
  ${({ name, last }) => css`
    margin: 0 8px;
    color: ${'green'};
    transition: 0.2s ease-in-out;

    opacity: 0.8;

    position: absolute;

    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  `}
`
