// has to be filename of ./pages/*
// the actual route paths are defined in translations

exports.links = {
  HOMEPAGE: '/',
  ABOUT: '/o-nas',
  BLOG: '/blog',
  GLOSSARY: '/slovnicek',
  CALC_BEECH: '/bukove-drevo',
  CALC_FIREPLACE: '/drevo-do-krbu',
  CALC_AMOUNT: '/optimalizace-mnozstvi',
  CALC_UNITS: '/prms-prmr',
  PRICE_MAP: '/cenova-mapa',
  PRICE_PLACE: '/cena-palivove-drevo-{0}',
  CALCULATOR_IFRAME: '/calculator',
  NEW_WOODMAKER: '/novy-dodavatel',
  ORDER_DETAIL: '/objednavka',
  ORDER_FEEDBACK: '/hodnoceni',
  CART: '/kosik',
  CART_STEP_2: '/kosik-2',
  CART_STEP_3: '/kosik-3',
}

exports.getLink = (link, ...args) => {
  let result = link
  args.forEach((arg, index) => {
    result = result.replace(`{${index}}`, arg)
  })

  return result
}
