import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { up } from '../../lib/styles'

const fadeInKeyframes = keyframes`
 0%{
    opacity: 0;
    transform: scale(0.5);

  }

  100%{
    opacity: 1;
    transform: scale(1);
  }
`

const fadeInSlideKeyframes = keyframes`
 0%{
    opacity: 0;
    transform: scale(0.9);
    max-width: 10px;
  }

  99%{
    max-width: 500px;
  }
  100%{
    opacity: 1;
    transform: scale(1);
  }
`

export const InvalidWrapper = styled.div`
  ${({ invalid }) => css`
    opacity: ${invalid ? 0.2 : 1};
    transition: 0.3s;
  `}
`

export const ResultsWrapper = styled.div`
  ${({ invalid, withoutWidthAnimation, mobileMargin = '0 -16px' }) => css`
    opacity: ${invalid ? 0.2 : 0};
    opacity: 1;
    position: relative;

    transition: opacity 0.3s;

    animation-name: ${withoutWidthAnimation
    ? fadeInKeyframes
    : fadeInSlideKeyframes};
    animation-duration: 600ms;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;

    ${invalid &&
    css`
      animation-fill-mode: none;
      & > * {
        pointer-events: none;
      }
    `}

    margin: ${mobileMargin};
    ${up('mobile')} {
      margin: 0;
    }
  `}
`
