import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { nanoid } from 'nanoid'
import styled, { css } from 'styled-components'
import { allowedAddressSources } from '../../lib/geo'
import { getInitialValuesByVariant } from './initialValues'
import { saveAddress } from './address'
import Link from '../Link'
import { useCalculationExperiments } from './experiments'
import { useMergedState } from '../hooks/useMergedState'
import Row from '../Row'
import { RadioGroup, SLocationInput } from '../fields'
import { beechOrBeechMixOptions, woodCategoryOptions } from './options'
import { PersistFormikValues } from '../../lib/PersistFormikValues'
import Gap from '../Gap'
import CheckboxField from '../fields/Checkbox'
import SliderRange, {
  AmountSlider,
  SliderControlled,
} from '../fields/SliderRange'
import { addressHint, addressHintLabel } from './helpers'
import Button from '../Button'
import Col from '../Col'

import arrowPath from '../../data/images/calcArrow.svg'
import arrowMobilePath from '../../data/images/calcArrowMobile.svg'
import { up } from '../../lib/styles'
import { useGlobalContext } from '../GlobalContext'
import {
  GlobalSLocationInput,
  validateFormikAddress,
} from '../fields/SLocationInput'
import { useExperiments } from '../hooks/useExperiments'
import { boxHoverCss } from '../Box'

const gtag = typeof window !== 'undefined' && window.gtag

const CalcWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    border-radius: 16px;
    background: white;
    /* box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25); */
    width: 390px;
    max-width: 100%;
    display: flex;
    flex-direction: column;

    padding: 40px 20px;
    ${up('mobile')} {
      padding: 50px 44px;
    }

    ${boxHoverCss}
  `}
`

const ArrowImg = styled.img`
  ${({ reverse }) => css`
    position: absolute;

    left: 0;
    top: 50%;
    transform: translate(-120%, -50%);

    ${reverse &&
    css`
      left: auto;
      right: 0;
      transform: translate(120%, -50%) scaleX(-1);
    `}

    display: none;
    ${up(770)} {
      display: block;
    }
  `}
`
const ArrowMobileImg = styled.img`
  ${({ reverse }) => css`
    position: absolute;

    right: 0;
    top: 0%;
    transform: translate(0, -68%);

    ${up(770)} {
      display: none;
    }
  `}
`

export default function CalcForm(props) {
  const {
    variant,
    woodVariant,
    noArrows,
    overwriteValues,
    onCalcFormikSubmit,
    onDebouncedCalcFormikSubmit,
    isCalculating,
    onFormikValuesChange,
    wasCalced,
    initialValues,
    amountIndex,
    handleAmountChange,
    amountOffers,
  } = props

  const { dimensions } = useExperiments()

  // debounce for auto submit
  const timerDebounce = React.useRef(null)
  const triggerDebounce = (values) => {
    // console.log('DEOUNCEEEEEE TRIGGER', values)
    clearTimeout(timerDebounce.current)
    timerDebounce.current = setTimeout(() => {
      // console.log('DEOUNCEEEEEE' ,values)
      onDebouncedCalcFormikSubmit?.()
      onCalcFormikSubmit?.(values)
    }, 600)
  }

  // console.log('FORMIK INI', initialValues, overwriteValues)
  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        const errors = {
          ...validateFormikAddress(values),
        }

        // hijack validate method to know, that sth is changed
        onFormikValuesChange?.(values, errors)

        if (Object.keys(errors).length === 0 && wasCalced) {
          // onCalcFormikSubmit?.(values)
          // console.log('deboucing with values ', values)

          triggerDebounce(values)
        }

        return errors
      }}
      onSubmit={onCalcFormikSubmit}
    >
      <Col maxWidth="100%" alignItems="center" position="relative">
        {!wasCalced && !noArrows && (
          <>
            <ArrowImg src={arrowPath} />
            {!isCalculating && <ArrowImg src={arrowPath} reverse />}
            <ArrowMobileImg src={arrowMobilePath} />
          </>
        )}
        <CalcWrapper>
          <Form>
            <PersistFormikValues
              name={
                !variant
                  ? 'calc-form-2'
                  : `calc-form-2-${variant}-${woodVariant}`
              }
              ignoreValues={['address']}
              overwriteValues={overwriteValues}
            />
            {woodVariant !== 'beech' && (
              <RadioGroup
                name="woodCategory"
                options={woodCategoryOptions}
                label="Tvrdost"
                labelHint="(štípaná polena cca 33 cm)"
                interactiveHint
                hint={(
                  <>
                    Tvrdé dřevo zabere méně místa a nebudete muset tak často
                    přikládat. Měkké dřevo rychleji vyschne a praská.
                    <br />
                    <br />
                    Můžete objednat i délku 25 cm, jen počítejte s příplatkem
                    cca 100 Kč na kubík. Objednejte normálně a domluvte se s
                    dodavatelem při domlouvání termínu závozu na zkrácení.
                    <br />
                    <br />
                    Nevíte jestli měkké nebo tvrdé? Mrkněte na náš článek o
                    <Link.TextLink to="/vyhrevnost-dreva" target="_blank">
                      výhřevnosti dřeva
                    </Link.TextLink>
                    .
                  </>
                )}
              />
            )}
            {woodVariant === 'beech' && (
              <RadioGroup
                name="beechOrBeechMix"
                options={beechOrBeechMixOptions}
                label="Druh dřeva"
                hint={(
                  <>
                    Pokud chcete spočítat cenu dřeva, kde není výhradní
                    zastoupení buku, pak přejděte na
                    {' '}
                    <Link to={Link.HOMEPAGE} anchor="calculator">
                      obecnou kalkulačku.
                    </Link>
                  </>
                )}
                interactiveHint
              />
            )}
            <Gap gap="48px" mobileGap="40px" />

            <CheckboxField
              extendedPadding
              name="isDry"
              label="Pouze suché dřevo"
              interactiveHint
              hint={(
                <>
                  Obvykle dražší suché dřevo se používá k okamžité spotřebě,
                  abyste nezanášeli komín a získali ze dřeva maximum tepla.
                  <br />
                  <br />
                  Nevíte jestli suché nebo syrové? Mrkněte na náš článek o
                  <Link.TextLink to="/vyhrevnost-dreva" target="_blank">
                    výhřevnosti dřeva
                  </Link.TextLink>
                  .
                </>
              )}
            />
            <Gap gap="48px" mobileGap="40px" />

            {dimensions.expIsNewCalculator && (
              <>
                <AmountSlider
                  amountIndex={amountIndex}
                  amountOffers={amountOffers}
                  handleAmountChange={handleAmountChange}
                />
                <Gap gap="40px" />
              </>
            )}

            <GlobalSLocationInput
              name="address"
              localFieldName="address"
              label="Vaše lokalita"
              // globalNamespace="calcAddress" // this is default
              hint={addressHint}
              hintLabel={addressHintLabel}
              hintNonAccurateLabel="Nepřesná adresa"
              hintNonAccurate={addressHint}
              initialValue={initialValues.address}
            />

            <Gap gap="32px" />

            <Button.FormikSubmitButton
              width="100%"
              isSubmitting={isCalculating}
              realistic={dimensions.expIsNewCalculator}
            >
              {({ isSubmitting }) => isSubmitting
                ? 'Hledám...'
                : wasCalced
                  ? 'Hledat'
                  : variant === 'amount'
                    ? 'Najít nejvýhodnější nabídku'
                    : 'Najít nejvýhodnější nabídku'}
            </Button.FormikSubmitButton>
          </Form>
        </CalcWrapper>
      </Col>
    </Formik>
  )
}
