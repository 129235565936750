import React from 'react'
import { createGlobalStyle, css } from 'styled-components'
import styledNormalize from 'styled-normalize'
import getTokens from '@kiwicom/orbit-components/lib/getTokens'

import OrbitProvider from '@kiwicom/orbit-components/lib/OrbitProvider'

import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { theme } from '../lib/styles'
import fontFiles from '../lib/fonts'
import superFormReset from '../lib/superFormReset'
import '../lib/fragments' // has to be imported somewhere to gatsby notice that
import packageInfo from '../../package.json'
import PopupsProvider from '../components/PopupsProvider'
import ExperimentsProvider from '../components/ExperimentsProvider'
import { GlobalContextProvider } from '../components/GlobalContext'

console.log('version', packageInfo.version)

// if (typeof window !== 'undefined') {
//   window.history.pushState(null, document.title, window.location.href)
//   window.addEventListener('popstate', (event) => {
//     console.log('!!!!!!! opopstate', event, window.location, window.history)
//     console.log('!!!!!!! opopstate taets', event.target.location.href, event.currentTarget.location.href)
//     // window.history.pushState(null, document.title, window.location.href)
//   })
// }

const GlobalStyle = createGlobalStyle`${({ theme: { colors } }) => css`
  ${superFormReset}
  ${styledNormalize}
  ${fonts}

  html {
    /* scroll-behavior: smooth; */
  }

  body {
    background: white;
    font-family: 'Signika', sans-serif;
    color: ${colors.text};
  }
  * {
    box-sizing: border-box !important;
  }
  p {
    margin: 0;
    padding: 0;
  }
  a {
    text-decoration: none;
    color: inherit;
    transition: 0.4s;
    /* display: inline-block;
    color: ${colors.green};
    &:hover {
      color: ${colors.darkGreen};
    } */
  }
`}`

const customTokens = getTokens({
  colorTextLoading: 'red',
  palette: {
    product: {
      colorTextLoading: 'orange',
    },
    colorTextLoading: 'green',
  },
})

const queryClient = new QueryClient()
class App extends React.Component {
  render() {
    const { pageConfig } = this.props

    return (
      <OrbitProvider
        theme={{
          ...theme,
          orbit: customTokens,
          // transitions: false,
        }}
      >
        <>
          <GlobalStyle />
          <GlobalContextProvider>
            <QueryClientProvider client={queryClient}>
              <ExperimentsProvider>
                <PopupsProvider enabled={pageConfig.enabledPopups}>
                  {this.props.children}
                </PopupsProvider>
              </ExperimentsProvider>
              <ReactQueryDevtools initialIsOpen />
            </QueryClientProvider>
          </GlobalContextProvider>
        </>
      </OrbitProvider>
    )
  }
}

export default App

const fonts = css`
  @font-face {
    font-family: 'Signika';
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: local('Signika'), url(${fontFiles.SignikaSemiBold}) format('truetype');
  }
  @font-face {
    font-family: 'Signika';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Signika'), url(${fontFiles.SignikaRegular}) format('truetype');
  }
  @font-face {
    font-family: 'Signika';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Signika'), url(${fontFiles.SignikaLight}) format('truetype');
  }
  @font-face {
    font-family: 'Bree-Serif';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Bree Serif'), url(${fontFiles.BreeSerif}) format('truetype');
  }
  @font-face {
    font-family: 'Bree-Serif';
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local('Bree Serif'),
      url(${fontFiles.BreeSerifLight}) format('truetype');
  }
`
