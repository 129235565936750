import React from 'react'
import Col from '../Col'
import Row from '../Row'
import Link from '../Link'
import { usePopup } from '../hooks/usePopups'
import { popupTypes } from '../PopupsProvider'
import { getCalcShareLink } from '../../../lib/order'
import { useExperiments } from '../hooks/useExperiments'

export default function Sharing({
  amountsResultShortId,
  amountsResultId,
  calcValues,
}) {
  const sendCalcPopup = usePopup(popupTypes.SEND_CALC)
  const shareCalcPopup = usePopup(popupTypes.SHARE_CALC)
  const { sendEvent } = useExperiments()

  const shareLink = getCalcShareLink({
    calcResult: { ...calcValues, shortId: amountsResultShortId },
  })

  return (
    <Col width="100%" maxWidth="350px" alignItems="center">
      <Row alignItems="baseline" wrap="wrap">
        <Link.ShadowTextLink
          asSpan
          onClick={() => {
            sendCalcPopup.openPopup({ amountsResultId })
            sendEvent('open_send_calc_popup')
          }}
        >
          Poslat výsledek na email
        </Link.ShadowTextLink>
        &nbsp;nebo&nbsp;
        <Link.ShadowTextLink
          asSpan
          onClick={() => {
            shareCalcPopup.openPopup({ shareLink })
            sendEvent('open_share_calc_popup')
          }}
        >
          sdílet odkaz
        </Link.ShadowTextLink>
      </Row>
    </Col>
  )
}
