// all imports of components with StaticImage has to be via require, since
// there is bug in gatsby MDX! (and popups use StaticImage)

import { useCallback, useContext } from 'react'

export const usePopups = () => {
  const { popupContext } = require('../PopupsProvider')
  const values = useContext(popupContext)

  return values
}

export const getPopupTypes = () => {
  const { popupTypes } = require('../PopupsProvider')

  return popupTypes
}

export const usePopup = (typeKey) => {
  const { popupContext } = require('../PopupsProvider')

  const helpers = useContext(popupContext)

  const setPopupState = useCallback(
    (state, props) => {
      helpers.setPopupState(typeKey, state, props)
    },
    [helpers],
  )

  const openPopup = useCallback(
    (props) => {
      setPopupState(true, props)
    },
    [setPopupState],
  )

  const closePopup = useCallback(() => {
    setPopupState(false)
  }, [setPopupState])

  const values = {
    helpers,
    openPopup,
    closePopup,
    popupState: !!helpers.popupsState[typeKey],
  }
  return values
}
