import React, { useEffect, useState } from 'react'
import Loading from '@kiwicom/orbit-components/lib/Loading'
import Popup from '../Popup'
import { useServerCalculateMutation } from './hooks'
import { useUpdateGlobalAddress } from '../fields/SLocationInput'
import { useGlobalContext } from '../GlobalContext'
import Title from '../Title'
import Gap from '../Gap'
import {
  OrderPopupItemLabel,
  OrderPopupItemValue,
  OrderPopupItemValueNote,
} from './OrderPopup'
import { formatCurrency } from '../../lib/helpers'
import Button from '../Button'

export const useUpdatePricePopup = ({ onAccept } = {}) => {
  const [showWarningPopup, setShowWarningPopup] = useState(false)

  const {
    // inputs with order started
    orderCalcInputs,
    setOrderCalcInputs,
    // results with order started
    orderCalcResult,
    setOrderCalcResult,
  } = useGlobalContext()

  // when address is changed to accurate, we need to recalculate
  const {
    calculateAsync,
    pickedOffer: newOffer,
    cachedInputs: newOfferInputs,
  } = useServerCalculateMutation({ pickedIndex: orderCalcInputs.amountIndex })

  const { updateGlobalAddress } = useUpdateGlobalAddress()

  const onNewOfferAccept = async () => {
    setShowWarningPopup(false)
    setOrderCalcResult(newOffer)
    setOrderCalcInputs((state) => ({
      ...state,
      address: newOfferInputs.address,
    }))
    updateGlobalAddress(newOfferInputs.address)
    onAccept?.(newOfferInputs.address)
  }

  const openUpdatePricePopup = () => setShowWarningPopup(true)
  useEffect(() => {
    if (
      newOffer &&
      newOffer?.finalTotalPrice !== orderCalcResult?.finalTotalPrice
    ) {
      openUpdatePricePopup()
    }
  }, [newOffer, orderCalcResult])

  const popupProps = {
    setShown: setShowWarningPopup,
    newOffer,
    oldOffer: orderCalcResult,
    onNewOfferAccept,
  }

  const PopupComponent = (props) => showWarningPopup && <UpdatePricePopup {...props} {...popupProps} />

  return {
    UpdatePricePopup: PopupComponent,
    openUpdatePricePopup,
    calculateNewAddress: async (address) => calculateAsync({
      ...orderCalcInputs,
      address,
    }),
    newOffer,
  }
}

export default function UpdatePricePopup(props) {
  const { newOffer, oldOffer, onNewOfferAccept, setShown, newAddress } = props

  return (
    <Popup
      mobileMaxWidth="350px"
      onPopupOverlayClick={() => {
        if (!newOffer) {
          return
        }
        onNewOfferAccept()
        setShown(false)
      }}
    >
      {!newOffer && (
        <Loading
          loading
          text="Aktualizujeme cenu dle nové adresy..."
          type="pageLoader"
        />
      )}
      {newOffer && (
        <>
          <Title.SectionSubTitle textAlign="left" mobileTextAlign="left">
            Cena byla aktualizována
            <br />
            dle přesné adresy
          </Title.SectionSubTitle>
          <Gap gap="16px" />

          <OrderPopupItemLabel>Přesná adresa:</OrderPopupItemLabel>
          <OrderPopupItemValue>
            {newAddress?.address}
            ,
            {newAddress?.city?.name}
          </OrderPopupItemValue>

          <Gap gap="16px" />

          <OrderPopupItemLabel>Cena pro původní lokalitu:</OrderPopupItemLabel>
          <OrderPopupItemValue>
            {formatCurrency(oldOffer.finalTotalPrice)}
          </OrderPopupItemValue>
          <Gap gap="16px" />

          <OrderPopupItemLabel>
            Aktuální cena pro přesnou adresu:
          </OrderPopupItemLabel>
          <OrderPopupItemValue>
            {formatCurrency(newOffer.finalTotalPrice)}
            {' '}
            <OrderPopupItemValueNote>
              (změna o&nbsp;
              {formatCurrency(
                newOffer.finalTotalPrice - oldOffer.finalTotalPrice,
              )}
              )
            </OrderPopupItemValueNote>
          </OrderPopupItemValue>
          <Gap gap="16px" />
          <Button onClick={onNewOfferAccept} minWidth="100%">
            OK
          </Button>
        </>
      )}
    </Popup>
  )
}
