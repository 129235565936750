// everything with styles, theme etc. goes here

import styled, { css } from 'styled-components'
import { darken, lighten } from 'polished'

// all major colors
export const colors = {
  text: '#262626',
  red: '#f11d0d',
  white: '#ffffff',
  black: '#262626',
  orange: '#F97C08',
  btnHover: '#f99908',
  lightOrange: '#FFB951',
  darkOrange: darken(0.1, '#F97C08'),
  brown: '#B75800',
  darkBrown: '#6A2D00',
  menuDarkBrown: '#3a1900',
  footerGreen: '#54723F',
  bgGrey: '#F7F7F7',
  bgGreyDarker: '#f1f1f1',
  inputGrey: '#F5F5F5',
  checkRadioGrey: '#C4C4C4',
  blue: '#0585d2',
  darkerBlue: '#026fb1',
  version: '#0f1f04',
  textGrey: '#828282',
  textDarkerGrey: '#686868',
  green: 'green',
  // buttonSecondary: ''
}

// theme vars accessible in every component
export const theme = {
  colors,
  breakpoints: {
    // not used yet --> from bootstrap for info only
    // sm: '576px',
    // md: '768px',
    // lg: '992px',
    // xl: '1200px',

    everything: 0,
    mobile: 768, // main breakpoint for mobile
    pageWidth: 1200, // also bp for ...
    menu: 768,
  },
  bp: 'mobile', // default breakpoint
  fonts: {
    bree: '"Bree-Serif", serif',
    signika: '"Signika", sans-serif',
  },
  pageSidePadding: '24px',
  mobilePageSidePadding: '20px',
  sizes: {
    // number constants
    pageWidth: 1200,
    maxPageWidth: 1200,
    cartPageWidth: 1060,
  },
}

// style helpers and snippets
// media queries helpers, if arg is string, then it's bp name, otherwise it's pixel value
export const down = (value) => `@media (max-width: ${
  typeof value === 'string' ? theme.breakpoints[value] : value
}px)`
export const between = (value, value2) => `@media (min-width: ${
  typeof value === 'string' ? theme.breakpoints[value] : value
}px) and 
  (max-width: ${
  typeof value2 === 'string' ? theme.breakpoints[value2] : value2
}px)`

export const up = (value) => `@media (min-width: ${
  typeof value === 'string' ? theme.breakpoints[value] : value
}px)`

// use dynamic BP
export const upBp = () => css`
  ${({ bp, theme: { bp: themeBp } }) => up(bp || themeBp)}
`

// use dynamic prop
// prop is taken from component prop, if undefined then from theme, then from default
export const prop = (name, defaultValue = '') => css`
  ${(props) => {
    const instanceProp = props[name]
    const themeProp = props.theme[name]

    return instanceProp || themeProp || defaultValue
  }}
`

export const addFont = (family) => `font-family: ${theme.fonts[family] || family};`

// adds snippets which ResponsivityHelper uses
// props can be bp names or pixels
// to use mobile first, use **After, which uses min-width
export const addResponsivity = ({ displayAs: displayAsArg } = {}) => css`
  ${({
    hideBelow,
    showBelow,
    hideAfter,
    showAfter,
    displayAs = displayAsArg || 'block',
    hideBetween,
    showBetween,
    mobile, // useful shorthand for hideAfter="mobile", breakpoint can be changed via bp prop
    desktop, // useful shorthand as 'mobile'
    bp = 'mobile', // or can be changed to this bp!!! B-)
    show, // overrides everything
    hide, // overrides everything
  }) => css`
    display: ${displayAs};

    /* ${show &&
    css`
      display: ${displayAs} !important;
    `}
    ${hide &&
    css`
      display: none !important;
    `} */

    ${hideBelow &&
    css`
      display: ${displayAs};
      ${down(hideBelow)} {
        display: none;
      }
    `}
    ${showBelow &&
    css`
      display: none;
      ${down(showBelow)} {
        display: ${displayAs};
      }
    `}
    ${showAfter &&
    css`
      display: none;
      ${up(showAfter)} {
        display: ${displayAs};
      }
    `}
    ${hideAfter &&
    css`
      display: ${displayAs};
      ${up(hideAfter)} {
        display: none;
      }
    `}
    ${showBetween &&
    css`
      display: none;
      ${between(...showBetween)} {
        display: ${displayAs};
      }
    `}
    ${hideBetween &&
    css`
      display: ${displayAs};
      ${between(...hideBetween)} {
        display: none;
      }
    `}
    ${mobile &&
    css`
      display: ${displayAs};
      ${up(bp)} {
        display: none;
      }
    `}
    ${desktop &&
    css`
      display: none;
      ${up(bp)} {
        display: ${displayAs};
      }
    `}
  `}
`

export const addPagePadding = () => css`
  ${({ theme: { pageSidePadding, mobilePageSidePadding, breakpoints } }) => css`
    padding-left: ${mobilePageSidePadding};
    padding-right: ${mobilePageSidePadding};
    ${up(breakpoints.mobile)} {
      padding-left: ${pageSidePadding};
      padding-right: ${pageSidePadding};
    }
  `}
`

export const addFullWidth = () => css`
  position: relative;
  width: calc(100vw - 16px);
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  ${up(1440)} {
    max-width: 1440px;
  }
`
