export const woodCategoryOptions = [
  {
    label: 'Tvrdé dříví',
    value: 'hard',
    tag: 'Nejprodávanější',
  },
  {
    label: 'Měkké dříví',
    value: 'soft',
  },
]

export const beechOrBeechMixOptions = [
  {
    label: 'Čistý buk',
    value: 'beech',
  },
  {
    label: 'Buk s příměsí (dostupnější)',
    value: 'beechMix',
  },
]
