import React from 'react'
import styled from 'styled-components'
import Popup from '../Popup'
import { CalculatorWithoutShareResults } from '../Calculator/Calculator'
import Gap from '../Gap'
import Col from '../Col'
import CloseIcon from '../CloseIcon'
import { useGlobalContext } from '../GlobalContext'

const PopupContent = styled.div`
  width: 100vw;
  max-width: 800px;
  width: min(800px, 87vw);
`
export default function CalcPopup({ isOpen, openPopup, closePopup, calcInputs, calcResult }) {
  const {
    // inputs which order started with
    orderCalcInputs,
    // results which order started with
    orderCalcResult,
  } = useGlobalContext()

  return isOpen ? (
    <Popup bgColor="bgGreyDarker">
      <PopupContent>
        <CloseIcon onClick={closePopup} />

        <Col>
          <Gap gap="32px" />
          <CalculatorWithoutShareResults
            variant="amount"
            noTitle
            noArrows
            instantCalc
            overwriteValues={orderCalcInputs}
            woodVariant={orderCalcInputs?.woodVariant}
            noSharing
          />
          <Gap gap="32px" />
        </Col>
      </PopupContent>
    </Popup>
  ) : null
}
