import React from 'react'
import styled from 'styled-components'
import Loading from '@kiwicom/orbit-components/lib/Loading'
import { up } from '../../lib/styles'

const LoaderWrapperCore = styled.div`
  background: white;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0px 1px 17px -3px rgb(0 0 0 / 25%);
  width: 100%;
  ${up('mobile')} {
    width: auto;
  }
`

const LoaderWrapper = styled(LoaderWrapperCore)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const CalcLoader = () => (
  <LoaderWrapperCore>
    <Loading
      loading
      text="Usilovně počítáme, chvíli to potrvá..."
      type="pageLoader"
    />
  </LoaderWrapperCore>
)

export const AbsoluteCalcLoader = () => (
  <LoaderWrapper>
    <Loading
      loading
      text="Usilovně počítáme, chvíli to potrvá..."
      type="pageLoader"
    />
  </LoaderWrapper>
)
