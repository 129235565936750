import { useEffect, useState } from 'react'

import { useLocation, createHistory } from '@reach/router'

let lastState = null
if (typeof window !== 'undefined') {
  const history = createHistory(window)
  history.listen(({ action, location }) => {
    // console.log('!!!!@@@@args action, location', action, location)
    lastState = location
  })
  // console.log('historyhistoryhistory', history)
  window.history2 = history
}

function useBlockBackButton({ callback }) {
  const [onceCalled, setOnceCalled] = useState(false)
  const [willBeLeaving, setWillBeLeaving] = useState(false)

  useEffect(() => {
    window.history.pushState(null, null, window.location.href)
  }, [])

  useEffect(() => {
    const handleHistoryState = (isPush) => {
      console.log('@handleHistoryState', isPush, onceCalled, lastState)
      // prijde na uvodni stranku
      if (lastState.key === 'initial' && !isPush) {
        console.log(' prijde na uvodni stranku')
        // je tu poprve?
        if (!willBeLeaving) {
          console.log('je tu poprve')
          window.history.pushState(null, null, window.location.href)
          setWillBeLeaving(true)
          setOnceCalled(false)
          // jiz tu byl a odchazi znovu skrze duplikovanou historii vyse
          // jeste jsme mu neukazali popup? --- uz chce odejit, ale nic nevidel
        } else if (!onceCalled) {
          console.log('uz chce odejit, ale nic nevidel')

          callback({ fromBackButton: true })
          setOnceCalled(true)
          setWillBeLeaving(true)
          window.history.pushState(null, null, window.location.href)
        } else {
          // jiz tu byl a videl popup, nechame odejit
          console.log('konecne odchod')
          setWillBeLeaving(false)
          setOnceCalled(false)
          window.history.back()
          window.history.back()
          window.history.back()
        }
      } else {
        console.log('prijde kompletne jinam --> resetujeme')
        // prijde kompletne jinam --> resetujeme
        setWillBeLeaving(false)
        setOnceCalled(false)
      }
    }
    const handleHistoryPushState = () => handleHistoryState(true)
    const handleHistoryPopState = () => handleHistoryState(false)

    // window.addEventListener('beforeunload', handleBeforeUnload)
    window.addEventListener('popstate', handleHistoryPopState)
    window.addEventListener('pushstate', handleHistoryPushState)

    return () => {
      // window.removeEventListener('beforeunload', handleBeforeUnload)
      window.removeEventListener('popstate', handleHistoryPopState)
      window.removeEventListener('pushstate', handleHistoryPushState)
    }
  }, [onceCalled, callback])

  return { setOnceCalled }
}

export default useBlockBackButton
