import React, { memo, useCallback, useEffect, useState } from 'react'
import { PersistFormikValues } from '../../lib/PersistFormikValues'
import { useFeedbackPopupMutation } from './useQueries'

// can be used for everything not just popup
// fieldsToKeep - what should persist after sending
export default function usePopupFormAndMutation({
  type,
  appendValues,
  fieldsToKeep = ['contact'],
  useMutation = useFeedbackPopupMutation,
  mutationArgs = [],
  onError,
}) {
  const formMutation = useMutation(...mutationArgs)

  function onSubmit(
    values,
    {
      setSubmitting,
      setFieldValue,
      setFieldTouched,
      setTouched,
      setValues,
      resetForm,
      ...rest
    },
  ) {
    // console.log('asdf', rest)
    const body = {
      type,
      ...values,
      ...appendValues,
    }
    if (typeof window !== 'undefined') {
      body.pagePath = window.location.pathname
    }

    formMutation.mutate(body, {
      onSuccess: (...all) => {
        // console.log('success', all)
        const persisting = Object.keys(values)
          .filter((key) => fieldsToKeep.includes(key))
          .reduce((acc, key) => {
            acc[key] = values[key]
            return acc
          }, {})

        // console.log('persisting', persisting)
        resetForm()
        // console.log('RESETed')
        // setValues({})
        // console.log('empty obj', )
        setValues(persisting)
        // setFieldTouched()
        // console.log('touched')
      },
      onError: (error) => {
        console.log(
          'error',
          error,
          error.message,
          error.response,
          error.data,
          error.errors,
          error.originalError,
          error.name,
        )

        const onErrorRes = onError && onError(error)
        if (!onErrorRes) {
          alert(
            'Odeslání formuláře se nepodařilo. Zkuste to prosím znovu. Pokud to nebude fungovat, kontaktujte nás prosím přímo na email info@srovnejdrevo.cz. Děkujeme',
          )
        }
      },
      onSettled: () => {
        setSubmitting(false)
      },
    })
  }

  const formikPersistInstance = (
    <PersistFormikValues name={`form:${type}`} persistInvalid />
  )

  return {
    formMutation,
    onSubmit,
    formikPersistInstance,
  }
}
