import { useCallback, useState } from 'react'
import useLocalStorageState from 'use-local-storage-state'

export const useMergedState = (initialState) => {
  const [state, setState] = useState(initialState)
  const setMergedState = useCallback((newState) => {
    setState((prevState) => ({
      ...prevState,
      ...newState,
    }))
  }, [])
  return [state, setMergedState]
}

export const useMergedPersistentState = (key, initialState) => {
  const [state, setState] = useLocalStorageState(key, initialState)
  const setMergedState = useCallback((newState) => {
    setState((prevState) => {
      if (typeof newState === 'function') {
        const resultOfFnCall = newState(prevState)
        return {
          ...prevState,
          ...resultOfFnCall,
        }
      }

      return {
        ...prevState,
        ...newState,
      }
    })
  }, [])

  const reset = useCallback(() => {
    setState(initialState)
  }, [initialState])

  return [state, setMergedState, reset]
}
