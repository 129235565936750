import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import styled, { css } from 'styled-components'
import { addResponsivity, up, down, between } from '../lib/styles'

const ResponsiveImage = styled(GatsbyImage)`
  ${addResponsivity()}
`

export default ResponsiveImage
