import { addDays, isAfter } from 'date-fns'
import { classicRound } from '../src/lib/helpers'
import { formatPhoneNumber } from '../src/lib/phone'

export const getOrderDeliveryPrice = ({
  calcResult,
  finalDiscount,
  finalTotalPriceAfterDiscount,
}) => Math.round(
  calcResult.deliveryPrice +
      (calcResult.fixedCost || 0) +
      (calcResult.palettesPrice || 0),
)

export const getOrderWoodPrice = ({ calcResult, finalDiscount }) => Math.round(calcResult.finalWoodPrice - (finalDiscount || 0))

export const getOrderProductWoodTypeDescription = (
  { calcResult },
  { small } = {},
) => {
  const text =
    calcResult.woodVariant !== 'beech'
      ? calcResult.woodCategory === 'hard'
        ? 'Tvrdé dřevo'
        : 'Měkké dřevo'
      : calcResult.beechOrBeechMix === 'beech'
        ? 'Čistý buk'
        : 'Buk s příměsí'

  return small ? text.toLowerCase() : text
}

export const getOrderProductDrynessDescription = ({ calcResult }) => calcResult.isDry ? 'suché' : ''

export const getOrderProductAmountDescription = ({ calcResult }) => `${calcResult.finalAmount} m³ (sypané)`

export const getCartProductAmountDescription = (
  { calcResult },
  { long } = {},
) => `${calcResult.finalAmount} m³ sypaných${
  long
    ? ` (${classicRound(calcResult.finalAmount * 0.625)} m³ rovnaných)`
    : ''
}`

export const getOrderProductTypeDescription = ({ calcResult }) => calcResult.product.notBeautiful ? 'odřezky' : 'štípaná polena cca 33cm'

export const getOrderProductDescription = ({
  calcResult,
}) => `${getOrderProductWoodTypeDescription({ calcResult })},
${getOrderProductDrynessDescription({ calcResult })},
${getOrderProductAmountDescription({ calcResult })},
${getOrderProductTypeDescription({ calcResult })}`

// export const getOrderProductDescription = ({
//   calcResult,
// }) => `${getOrderProductWoodTypeDescription({ calcResult })},
// ${calcResult.isDry ? 'suché,' : ''}
// ${calcResult.finalAmount} m³ (sypané),
// ${calcResult.product.notBeautiful ? 'odřezky' : 'štípaná polena cca 33cm'}`

export const getOrderFullAddress = ({ calcResult }) => `${calcResult.address}, ${calcResult.city || calcResult.cityName}, ${
  calcResult.zip
}`

export const getOrderFullBillingAddress = (order) => order.differentBillingAddress
  ? `${order.billingAddress}, ${order.billingCity}, ${order.billingZip}`
  : '-'

// export const getAmountsResultLink = ({ calcResult }) => `http://srovnejdrevo.cz/${
//   calcResult.woodVariant !== 'beech' ? '' : 'bukove-drevo'
// }?woodCategory=${calcResult.woodCategory}&beechOrBeechMix=${calcResult.beechOrBeechMix}&isDry=${calcResult.isDry ? true : ''}&address=${

// `

export const getCalcShareLink = ({ calcResult }) => `https://srovnejdrevo.cz/${
  calcResult.woodVariant !== 'beech' ? '' : 'bukove-drevo'
}?share=${calcResult.shortId}`

export const getCalcInputs = ({ calcResult }) => ({
  woodCategory: calcResult.woodCategory,
  beechOrBeechMix: calcResult.beechOrBeechMix,
  isDry: calcResult.isDry,
  city: calcResult.city,
  zip: calcResult.zip,
  woodVariant: calcResult.woodVariant,
  address: {
    source: calcResult.addressSource,
    inputTextLong: getOrderFullAddress({ calcResult }),
    address: calcResult?.address,
    latLng: {
      lat: calcResult?.lat,
      lng: calcResult?.lng,
    },
    placeId: calcResult?.placeId,
    zipCode: calcResult?.zipCode,
    zip: calcResult?.zip,
    city: {
      name: calcResult?.cityName,
      coords: {
        lat: calcResult?.cityLat,
        lng: calcResult?.cityLng,
      },
      id: calcResult?.cityId,
      type: 'muni',
    },
    region: {
      type: 'regi',
      name: calcResult?.regionName,
      coords: {
        lat: calcResult?.regionLat,
        lng: calcResult?.regionLng,
      },
      id: calcResult?.regionId,
    },
    district: {
      type: 'dist',
      name: calcResult?.districtName,
      coords: {
        lat: calcResult?.districtLat,
        lng: calcResult?.districtLng,
      },
      id: calcResult?.districtId,
    },
  },
})

export const decorateOrder = (order) => {
  order.address = order.calcResult.address
  order.city = order.calcResult.city
  order.cityName = order.calcResult.cityName
  order.zip = order.calcResult.zip
  order.isDry = order.calcResult.isDry
  order.woodCategory = order.calcResult.woodCategory
  order.woodMakerPhone =
    order.calcResult?.woodMaker?.phone ||
    order.calcResult?.product?.woodMaker?.phone

  order = {
    ...order,
    formattedWoodMakerPhone: formatPhoneNumber(order.woodMakerPhone, {
      nbsp: true,
    }),
    formattedPhone: formatPhoneNumber(order.phone, {
      nbsp: true,
    }),
    orderDeliveryPrice: getOrderDeliveryPrice(order),
    orderWoodPrice: getOrderWoodPrice(order),
    productDescription: getOrderProductDescription(order),
    woodTypeDescription: getOrderProductWoodTypeDescription(order),
    woodDrynessDescription: order.isDry ? ', suché' : '',
    fullAddress: getOrderFullAddress(order),
    orderUrl: `http://srovnejdrevo.cz/objednavka?o=${order.shortId}`,
  }

  return order
}

export const isOrderEligibleForReview = (order) => isAfter(
  new Date(),
  // addDays(new Date(order?.createdAt), 14),
  addDays(new Date(order?.createdAt), 0),
)
