import React from 'react'
import styled from 'styled-components'
import loaderIndicatorPath from '../data/images/loading-indicator.gif'

const LoaderWrapper = styled.div`
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  img {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.6);
    display: block;
    position: absolute;
  }
`
function Loader(props) {
  return (
    <LoaderWrapper>
      <img src={loaderIndicatorPath} />
    </LoaderWrapper>
  )
}

export default Loader
