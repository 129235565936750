import styled, { css } from 'styled-components'

import { down } from '../lib/styles'

const presets = {
  order: {
    padding: '14px 8px',
    minWidth: '134px',
    lineHeight: 'initial',
    fontSize: '18px',
  },
  small: {
    padding: '0px 8px',
    height: '32px',
    fontSize: '13px',
    minWidth: '134px',
  },
  subscribe: {
    fontSize: '13px',
    height: '41px',
    padding: '0px 0px',
    minWidth: '134px',
  },
}

const ButtonBase = styled.button.attrs((props) => ({
  ...presets[props.preset],
}))`
  ${({
    theme: { fonts, colors },
    padding = '0px 24px',
    height = '48px',
    lineHeight,
    width,
    responsiveFullWidth,
    bp = 'mobile',
    disabled,
    fontSize = '14px',
    outlined,
    minWidth = '146px',
    position = 'relative',
  } = {}) => css`
    min-width: ${minWidth};
    /* height: ${height}; */
    /* REVIEW: resil bych bud height nebo padding / line-height ale ne oboji dohromady... s height nefunguje dvojradek */
    display: inline-block;
    border-radius: 8px;
    background-color: ${colors.orange};
    background: linear-gradient(0deg, #d16400, #f97c07);

    color: ${colors.white};
    font-family: ${fonts.signika};
    font-size: ${fontSize};
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: ${lineHeight || height};
    position: ${position};
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.4s;
    padding: ${padding};
    width: ${width};

    ${disabled &&
    css`
      opacity: 0.5;
      /* pointer-events: none; */
    `}

    ${responsiveFullWidth &&
    css`
      ${down(bp)} {
        width: 100%;
      }
    `}

    box-shadow: 0 3px 16px rgba(249,124,8,0.5);
    box-shadow: rgb(0 0 0 / 31%) 0px 1px 11px;
    &:hover {
      box-shadow: 0 2px 20px rgba(249, 124, 8, 1);
      /* box-shadow: 0 8px 16px rgba(0, 96, 86, 0.24); */

      /* background-color: ${colors.btnHover}; */
    }

    /* // border: transparent 2px solid; */

    ${outlined &&
    css`
      border: ${colors.orange} 2px solid;
      background: white;
      color: ${colors.orange};
      box-shadow: none;
      &:hover {
        box-shadow: none;
      }
    `}
  `}
`

export default ButtonBase
