import useLocalStorageState from 'use-local-storage-state'
import React, { useCallback, useEffect, useState } from 'react'
import { isBefore, subDays, subMinutes, subSeconds } from 'date-fns'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import Popup from './Popup'
import { Input } from './fields'
import Title from './Title'
import Gap from './Gap'
import Button from './Button'
import { PersistFormikValues } from '../lib/PersistFormikValues'
import Col from './Col'
import Text from './Text'
import HintTooltip, { InfoIcon } from './fields/HintTooltip'
import CloseIcon from './CloseIcon'
import { useFeedbackPopupMutation } from './hooks/useQueries'
import { feedbackSchema } from '../validations/forms'
import usePopupFormAndMutation from './hooks/usePopupFormAndMutation'
import { RH } from '.'

const PopupContent = styled.div`
  width: 100%;
  max-width: 800px;
  width: min(800px, 85vw);
`

const absoluteContentCss = css`
  right: 16px;
  bottom: -4px;
`

function QuestionPopup({ isOpen, setPopupState, openPopup, closePopup, type }) {
  const {
    formMutation,
    onSubmit,
    formikPersistInstance,
  } = usePopupFormAndMutation({
    type,
  })

  function closeThisPopup() {
    closePopup()
    if (!formMutation.isLoading) {
      formMutation.reset()
    }
  }

  return isOpen ? (
    <Popup
      // desktop
      // onPopupOverlayClick={closeThisPopup}
      absoluteContentCss={absoluteContentCss}
      absoluteContent={(
        <div>
          <RH showAfter={680}>
            <StaticImage
              src="../data/images/popup-bg.png"
              width={640}
              layout="constrained"
            />
          </RH>
        </div>
      )}
    >
      <PopupContent>
        <CloseIcon onClick={closeThisPopup} />
        <Title.SectionTitle>Máte nějakou otázku?</Title.SectionTitle>
        <Gap gap="8px" />
        <Title.SectionSubTitle textAlign="left">
          Nebojte se zeptat!
        </Title.SectionSubTitle>
        <Gap gap="8px" />
        <Formik
          initialValues={{
            message: '',
            contact: '',
          }}
          validationSchema={feedbackSchema}
          onSubmit={onSubmit}
        >
          {({ submitForm, setFieldValue, isSubmitting, ...rest }) => (
            <>
              <Form>
                {formikPersistInstance}
                <Col maxWidth="445px" alignItems="flex-start" width="100%">
                  <Input
                    disabled={formMutation.isSuccess || formMutation.isLoading}
                    as="textarea"
                    customCss={css`
                      max-width: 420px;
                      width: 100%;
                      height: 100px;
                    `}
                    name="message"
                    type="text"
                    label="Vaše zpráva"
                    placeholder="Třeba že...  “nechápu tu kalkulačku...”, “chci vidět historii cen”, “chci tady tohle a tamto”,..."
                    width="100%"
                  />

                  <Input
                    required
                    disabled={formMutation.isSuccess || formMutation.isLoading}
                    name="contact"
                    type="text"
                    label="Email a/nebo telefon"
                    customCss={css`
                      width: 100%;
                      width: 240px;
                    `}
                  />

                  <Gap gap="16px" />
                  {!formMutation.isSuccess && (
                    <Button.FormikSubmitButton>
                      {formMutation.isLoading ? 'Odesílám...' : 'Odeslat'}
                    </Button.FormikSubmitButton>
                  )}

                  {formMutation.isSuccess && (
                    <Text color="green">✅ &nbsp; Odesláno, děkujeme!</Text>
                  )}
                  <Gap gap="32px" />
                </Col>
              </Form>
            </>
          )}
        </Formik>
      </PopupContent>
    </Popup>
  ) : null
}

export default QuestionPopup
