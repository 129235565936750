import { ErrorMessage, useFormikContext } from 'formik'
import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import {
  addResponsivity,
  up,
  down,
  between,
  addFont,
  colors,
} from '../../lib/styles'

const StyledErrorWrapper = styled.div`
  position: relative;
  ${({ width }) => css`
    width: ${width};
  `}
`
const StyledError = styled.div`
  ${({ theme: { colors }, noPosition }) => css`
    ${addFont('signika')}
    font-weight: 400;
    font-size: 14px;
    color: ${colors.red};
    ${!noPosition &&
    css`
      position: absolute;
      top: 0;
    `}
  `}
`

const Error = ({ name, error, width, noPosition }) => {
  const { errors, touched } = useFormikContext()

  // console.log('errorserrors useFormikContext', errors, error)

  const errorMessage = errors[name] || error

  return (
    <StyledErrorWrapper width={width}>
      <StyledError noPosition={noPosition}>{errorMessage}</StyledError>
    </StyledErrorWrapper>
  )
}

export default Error
