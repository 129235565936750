import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import Loading from '@kiwicom/orbit-components/lib/Loading'
import { useResultsViewExperiments } from './experiments'
import AmountCalcChart from './AmountCalcChart'
import { up } from '../../lib/styles'
import Col from '../Col'
import { AbsoluteCalcLoader, LoaderWrapper } from './Loader'
import { InvalidWrapper, ResultsWrapper } from './ResultsView.components'

export default function GraphResultsView(props) {
  const {
    invalidResults,
    wasCalced,
    amountsResultId,
    amountsResultShortId,
    calcValues,
    amountOffers,
    isSubmitting,
    amountIndex,
    handleAmountChange,
    handleStartOrder,
  } = props

  return (
    <Col position="relative">
      <span id={amountsResultId} />
      <InvalidWrapper invalid={invalidResults}>
        <ResultsWrapper
          invalid={invalidResults && wasCalced}
          withoutWidthAnimation
          onClick={() => {
            if (invalidResults && wasCalced) {
              alert('Změnili jste údaje v kalkulačce, opakujte prosím hledání.')
            }
          }}
        >
          <Col justifyContent="center">
            {typeof window !== 'undefined' && amountOffers?.length && (
              <AmountCalcChart
                data={amountOffers}
                handleStartOrder={handleStartOrder}
                amountsResultId={amountsResultId}
                amountsResultShortId={amountsResultShortId}
                calcValues={calcValues}
                amountIndex={amountIndex}
                handleAmountChange={handleAmountChange}
              />
            )}
          </Col>
        </ResultsWrapper>
      </InvalidWrapper>
      {isSubmitting && wasCalced && <AbsoluteCalcLoader />}
    </Col>
  )
}
