import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { up, addResponsivity } from '../lib/styles'

const Colorizer = styled.div`
  ${({ color, theme: { colors } }) => css`
    color: ${colors[color] || color};
    ${addResponsivity()}
  `}
`

export default Colorizer

export const ColorizedSpan = styled.span`
  ${({ color, theme: { colors } }) => css`
    color: ${colors[color] || color};
    ${addResponsivity()}
  `}
`
