const React = require('react')
const Bugsnag = require('@bugsnag/js').default
const BugsnagPluginReact = require('@bugsnag/plugin-react').default
const Layout = require('./src/layouts/DefaultLayout').default

Bugsnag.start({
  apiKey: process.env.GATSBY_BUGSNAG_BROWSER_KEY || 'bbf04e99ed471a1f4bca951ee48c2761',
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.GATSBY_SERVER_ENV,
  appVersion: process.env.GATSBY_APP_VERSION,
  enabledReleaseStages: [ 'production', 'development' ],
  metadata: {
    env: {
      SERVER_ENV: process.env.GATSBY_SERVER_ENV,
      NODE_ENV: process.env.NODE_ENV,
      APP_VERSION: process.env.GATSBY_APP_VERSION,
    },
  },
})

console.log('version', process.env.GATSBY_APP_VERSION)

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

exports.wrapPageElement = ({ element, props }) => (
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  <ErrorBoundary>
    <Layout {...props}>{element}</Layout>
  </ErrorBoundary>
)
