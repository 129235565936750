import useLocalStorageState from 'use-local-storage-state'
import React, { useCallback, useEffect, useState } from 'react'
import { isBefore, subDays, subMinutes, subSeconds } from 'date-fns'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import Popup from './Popup'
import { Input } from './fields'
import Title from './Title'
import Gap from './Gap'
import Button from './Button'
import { PersistFormikValues } from '../lib/PersistFormikValues'
import Col from './Col'
import Text from './Text'
import HintTooltip, { InfoIcon } from './fields/HintTooltip'
import CloseIcon from './CloseIcon'
import {
  useCustomerComplaintMutation,
  useFeedbackPopupMutation,
} from './hooks/useQueries'
import { feedbackSchema } from '../validations/forms'
import usePopupFormAndMutation from './hooks/usePopupFormAndMutation'
import { RH } from '.'

const PopupContent = styled.div`
  width: 100%;
  max-width: 800px;
  width: min(800px, 85vw);
`

function CustomerComplaintPopup({
  isOpen,
  setPopupState,
  openPopup,
  closePopup,
  type,
  shortId,
  ...rest
}) {
  const { formMutation, onSubmit } = usePopupFormAndMutation({
    type,
    useMutation: useCustomerComplaintMutation,
    mutationArgs: [shortId],
  })

  function closeThisPopup() {
    closePopup()
    if (!formMutation.isLoading) {
      formMutation.reset()
    }
  }

  return isOpen ? (
    <Popup>
      <PopupContent>
        <CloseIcon onClick={closeThisPopup} />
        <Title.SectionTitle>
          {type === 'CUSTOMER_COMPLAINT_NO_CONTACT'
            ? 'Zaúkolovat zákaznickou podporu'
            : 'Stížnost na dodavatele'}
        </Title.SectionTitle>
        <Gap gap="8px" />

        <Formik
          initialValues={{
            message: '',
            // type: 'CUSTOMER_COMPLAINT_NO_CONTACT',
            type,
          }}
          onSubmit={onSubmit}
        >
          {({ submitForm, setFieldValue, isSubmitting, ...rest }) => (
            <>
              <Form>
                <Col maxWidth="550px" alignItems="flex-start" width="100%">
                  <Input
                    disabled={formMutation.isSuccess || formMutation.isLoading}
                    as="textarea"
                    customCss={css`
                      height: 100px;
                    `}
                    name="message"
                    type="text"
                    label={
                      type === 'CUSTOMER_COMPLAINT_NO_CONTACT'
                        ? 'Doplňující zpráva'
                        : 'Popište, co se stalo'
                    }
                    placeholder="Čím více nám toho řeknete dopředu, tím lépe můžeme problém řešit."
                    width="100%"
                  />

                  <Gap gap="16px" />
                  {!formMutation.isSuccess && (
                    <Button.FormikSubmitButton>
                      {formMutation.isLoading ? 'Odesílám...' : 'Odeslat'}
                    </Button.FormikSubmitButton>
                  )}

                  {formMutation.isSuccess && (
                    <Text color="green">
                      ✅ &nbsp; Odesláno, děkujeme! Ozveme se vám nejpozději
                      následující pracovní den.
                    </Text>
                  )}
                  <Gap gap="32px" />
                </Col>
              </Form>
            </>
          )}
        </Formik>
      </PopupContent>
    </Popup>
  ) : null
}

export default CustomerComplaintPopup
