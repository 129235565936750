import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { addDays, addMonths, format } from 'date-fns'

const SEO = ({
  title,
  titleTemplate,
  description,
  image,
  pathname,
  isArticle,
  structured,
  noIndex,
}) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitleTemplate,
          defaultDescription,
          siteUrl,
          defaultImage,
        },
      },
    }) => {
      const seo = {
        title,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || '/'}`,
        titleTemplate: titleTemplate || defaultTitleTemplate,
      }
      // {/* {structured && ( */}
      //           <script
      //             type="application/ld+json"
      //             innerHTML={`
      // {
      // "@context": "https://schema.org/",
      // "@type": "Product",
      // "name": "${structured.name}",
      // "image": [
      // "https://srovnejdrevo.cz/drevoImg.jpg"
      // ],
      // "description": "${structured.desc}",
      // "offers": [{
      //   "@type": "Offer",
      //   "url": "https://srovnejdrevo.cz",
      //   "priceCurrency": "CZK",
      //   "price": "${structured.price}",
      //   "itemCondition": "https://schema.org/NewCondition",
      //   "availability": "https://schema.org/PreOrder",
      //   "availabilityStarts": "${addDays(new Date(), 19).toISOString()}"
      //   }]
      // }`}
      //           />
      // <script
      //   type="application/ld+json"
      //   innerHTML={`
      //   {
      //     "@context": "http://schema.org/",
      //     "@type": "Product",
      //     "name": "${structured.name}",
      //     "image": ["https://srovnejdrevo.cz/drevoImg.jpg"],
      //     "description": "${structured.desc}",
      //     "offers": {
      //       "@type": "AggregateOffer",
      //       "priceCurrency": "CZK",
      //       "lowPrice": "${structured.price}",
      //       "highPrice": "${structured.price * 1.5}",
      //       "priceValidUntil": "${format(
      //     addMonths(new Date(), 1),
      //     'yyyy-MM-dd',
      //   )}",
      //       "offers": [
      //         {
      //           "@type": "Offer",
      //           "price": "${structured.price}",
      //           "itemCondition": "https://schema.org/NewCondition",
      //           "availability": "http://schema.org/PreOrder"
      //         }
      //       ]
      //     },
      //     "brand": {
      //       "@type": "Brand",
      //       "name": "SrovnejDřevo.cz"
      //     }
      //   }`}
      // />
      // )}

      return (
        <Helmet title={seo.title} titleTemplate={seo.titleTemplate}>
          <meta name="description" content={seo.description} />
          <meta name="image" content={seo.image} />
          {noIndex && <meta name="robots" content="noindex" />}

          <html lang="cs" />
          {/* FB open graph */}
          {seo.url && <meta property="og:url" content={seo.url} />}
          {isArticle ? (
            <meta property="og:type" content="article" />
          ) : (
            <meta property="og:type" content="website" />
          )}
          {seo.title && <meta property="og:title" content={seo.title} />}
          {seo.description && (
            <meta property="og:description" content={seo.description} />
          )}
          {seo.image && <meta property="og:image" content={seo.image} />}

          {/* Twitter */}
          <meta name="twitter:card" content="summary_large_image" />
          {/* {twitterUsername && (
                <meta name="twitter:creator" content={twitterUsername} />
              )} */}
          {seo.title && <meta name="twitter:title" content={seo.title} />}
          {seo.description && (
            <meta name="twitter:description" content={seo.description} />
          )}
          {seo.image && <meta name="twitter:image" content={seo.image} />}

          {/* make it nice */}
          <meta name="theme-color" content="#ecf9ff" />
        </Helmet>
      )
    }}
  />
)

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  isArticle: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  isArticle: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitleTemplate: titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`
