import React, { useMemo, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import Loading from '@kiwicom/orbit-components/lib/Loading'
import { desaturate } from 'polished'
import { useResultsViewExperiments } from './experiments'
import { up } from '../../lib/styles'
import Col from '../Col'
import { AbsoluteCalcLoader, LoaderWrapper } from './Loader'
import { InvalidWrapper, ResultsWrapper } from './ResultsView.components'
import Box from '../Box'
import Text from '../Text'
import Gap from '../Gap'
import Row from '../Row'
import { HintTooltip } from '../fields'
import { ColorizedPriceAmountSlider, StyledSlider } from '../fields/Slider'
import {
  classicRound,
  formatCurrency,
  formatNumberWithThousands,
} from '../../lib/helpers'
import ChartIcon from '../icons/ChartIcon'
import Button from '../Button'
import Popup from '../Popup'
import CloseIcon from '../CloseIcon'
import AmountCalcChart from './AmountCalcChart'
import Sharing from './Sharing'
import ToggleButtonGroup from '../fields/ToggleButtonGroup'
import { AmountResultsTableView } from './AmountResultsTableView'
import {
  colorizePrice,
  formatPriceVatMessage,
  formatTotalPriceVatMessage,
} from './helpers'
import { ColorizedSpan } from '../Colorizer'
import { BasicTooltip } from '../fields/HintTooltip'

export default function GraphResultsText(props) {
  const {
    invalidResults,
    wasCalced,
    amountsResultId,
    amountsResultShortId,
    calcValues,
    amountOffers,
    isSubmitting,
    amountIndex,
    handleAmountChange,
    handleStartOrder,
    pickedOffer,
    noSharing,
  } = props

  // console.log('pickedOfferpickedOffer', pickedOffer)

  const valueColor = colorizePrice({
    amountOffers,
    amountIndex,
  })

  return (
    <Col
      position="relative"
      width="100%"
      mobileMaxWidth="390px"
      maxWidth="420px"
    >
      <span id={amountsResultId} />
      <InvalidWrapper invalid={invalidResults}>
        <ResultsWrapper
          mobileMargin="0px -8px"
          invalid={invalidResults && wasCalced}
          withoutWidthAnimation
          onClick={() => {
            if (invalidResults && wasCalced) {
              alert('Změnili jste údaje v kalkulačce, opakujte prosím hledání.')
            }
          }}
        >
          <Col justifyContent="center" width="100%">
            {typeof window !== 'undefined' && amountOffers?.length && (
              <Box mobilePadding="24px 16px">
                <Col width="100%">
                  <BasicTooltip
                    hint={(
                      <Col textWrap="pretty">
                        {formatTotalPriceVatMessage(pickedOffer)}
                      </Col>
                    )}
                    leaveDelay={100}
                  >
                    <Col>
                      <Text fontSize="60px" lineHeight="56px" fontWeight="500">
                        {formatCurrency(pickedOffer?.finalTotalPrice)}
                      </Text>
                      <Text fontSize="20px" lineHeight="18px" fontWeight="700">
                        včetně dopravy
                      </Text>
                    </Col>
                  </BasicTooltip>

                  <BlockGap />

                  <Col>
                    <TextLabel>Cena za 1 m&sup3;:</TextLabel>
                    <Row alignItems="baseline">
                      <TextValue>
                        <ColorizedSpan color={valueColor}>
                          {formatCurrency(pickedOffer?.price)}
                        </ColorizedSpan>
                      </TextValue>

                      <TextGap />
                      <TextSuffix>(vč. dopravy)</TextSuffix>
                      <HintTooltip
                        title={
                          <span>{formatPriceVatMessage(pickedOffer)}</span>
                        }
                      />
                    </Row>
                  </Col>

                  <BlockGap />
                  <Row
                    noResponsive
                    width="100%"
                    wrap="no-wrap"
                    alignItems="baseline"
                  >
                    <TextLabel>Množství:&nbsp;</TextLabel>

                    <TextValue>
                      {pickedOffer?.amount}
                      {' '}
                      m&sup3;
                    </TextValue>

                    <TextSuffix>&nbsp;sypaných</TextSuffix>

                    <HintTooltip
                      title={(
                        <span>
                          Což je zhruba
                          {' '}
                          {Math.ceil((pickedOffer?.amount || 0) * 0.625 * 10) /
                            10}
                          {' '}
                          m&sup3; rovnaných
                          <br />
                        </span>
                      )}
                    />
                  </Row>
                  <TextGap />
                  <ColorizedPriceAmountSlider
                    amountOffers={amountOffers}
                    max={amountOffers.length - 1}
                    min={0}
                    value={amountIndex}
                    onChange={(event, value) => handleAmountChange(value)}
                  />

                  <BlockGap />

                  <DetailedViewPopup
                    data={amountOffers}
                    handleStartOrder={handleStartOrder}
                    amountsResultId={amountsResultId}
                    amountsResultShortId={amountsResultShortId}
                    calcValues={calcValues}
                    amountIndex={amountIndex}
                    handleAmountChange={handleAmountChange}
                    noShadow
                    noSharing
                    noTitle
                  >
                    {({ setShown }) => (
                      <IconLink
                        Icon={ChartIcon}
                        color="blue"
                        onClick={() => setShown(true)}
                      >
                        Zobrazit vývoj ceny za metr
                      </IconLink>
                    )}
                  </DetailedViewPopup>
                  <TextGap />

                  {/* <IconLink Icon={ChartIcon} color="blue">
                    Podrobné info o nabídce a ceně
                  </IconLink> */}

                  <BlockGap />

                  <Button
                    realistic
                    onClick={(e) => {
                      e.preventDefault()
                      handleStartOrder()
                    }}
                  >
                    Pokračovat k objednávce
                  </Button>

                  <BlockGap />
                  {!noSharing && <Sharing />}
                </Col>
              </Box>
            )}
          </Col>
        </ResultsWrapper>
      </InvalidWrapper>
      {isSubmitting && wasCalced && <AbsoluteCalcLoader />}
    </Col>
  )
}

const BlockGap = () => <Gap gap="32px" />

const TextGap = () => <Gap gap="8px" />

const TextSuffix = ({ children, ...props }) => (
  <Text fontSize="16px" mobileFontSize="15px" color="textGrey">
    {children}
  </Text>
)

const TextLabel = ({ children, ...props }) => (
  <Text fontSize="18px" lineHeight="normal" fontWeight="600">
    {children}
  </Text>
)

const TextValue = ({ children, ...props }) => (
  <Text fontSize="32px" lineHeight="normal" fontWeight="600">
    {children}
  </Text>
)

const IconLink = ({ children, Icon, color, onClick, ...props }) => {
  const a = ''
  return (
    <Row alignItems="center" onClick={onClick} cursor="pointer">
      <Icon />
      <Gap gap="8px" />
      <Text color={color} fontSize="16px" lineHeight="normal" fontWeight="600">
        {children}
      </Text>
    </Row>
  )
}

const DetailedViewPopup = (props) => {
  const { children, ...chartProps } = props

  const [shown, setShown] = useState(false)
  const closePopup = () => setShown(false)

  const defaultView = 'chart'
  const [viewType, setViewType] = useState(defaultView)

  return (
    <>
      {children({ setShown })}
      {shown && (
        <Popup
          portalId="react-portal-chart-popup"
          onPopupOverlayClick={closePopup}
          padding="0 0 0 0"
        >
          <Gap gap="32px" />
          <Col alignItems="center">
            <ToggleButtonGroup
              defaultValue={viewType}
              options={[
                { value: 'chart', label: 'Graf' },
                { value: 'table', label: 'Tabulka' },
              ]}
              onChange={setViewType}
            />
          </Col>

          {viewType === 'chart' && <AmountCalcChart {...chartProps} />}
          {viewType === 'table' && <AmountResultsTableView {...chartProps} />}
          <CloseIcon onClick={closePopup} />
        </Popup>
      )}
    </>
  )
}
