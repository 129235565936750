import useLocalStorageState from 'use-local-storage-state'
import React, { useCallback, useEffect, useState } from 'react'
import { isBefore, subDays, subMinutes, subSeconds } from 'date-fns'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import Popup from './Popup'
import { Checkbox, Input } from './fields'
import Title from './Title'
import Gap from './Gap'
import Button from './Button'
import { PersistFormikValues } from '../lib/PersistFormikValues'
import Col from './Col'
import Text from './Text'
import HintTooltip, { BasicTooltip, InfoIcon } from './fields/HintTooltip'
import CloseIcon from './CloseIcon'
import {
  useCustomerComplaintMutation,
  useFeedbackPopupMutation,
  useSendCalcMutation,
  useSubscribeMutation,
} from './hooks/useQueries'
import { feedbackSchema, sendCalcSchema } from '../validations/forms'
import usePopupFormAndMutation from './hooks/usePopupFormAndMutation'
import { RH, Row } from '.'
import { getCalcShareLink } from '../../lib/order'
import { copyToClipboard } from '../lib/helpers'
import Icon from './Icon'
import { useExperiments } from './hooks/useExperiments'

const PopupContent = styled.div`
  width: 100%;
  max-width: 450px;
  /* width: min(800px, 85vw); */
`

const colors = {
  ShareFacebook: '#3b5998',
  ShareEmail: '#d14836',
  ShareLink: '#999',
}

const ShareIcon = styled(Icon).attrs({
  size: '80px',
})`
  ${({ name, last }) => css`
    padding: 8px;
    margin-right: ${last ? 0 : '8px'};
    color: ${colors[name] || 'green'};
    cursor: pointer;
    transition: opacity 0.2s ease-in-out;

    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  `}
`

function ShareCalcPopup(props) {
  const {
    isOpen,
    setPopupState,
    openPopup,
    closePopup,
    type,
    shortId,
    amountsResultId,
    calcResult,
    shareLink,
    ...rest
  } = props

  const { sendEvent, updateUserProperties } = useExperiments()

  const [copiedStatus, setCopiedStatus] = useState()

  // console.log('proppppp', amountsResultId, props)

  const { formMutation, onSubmit, formikPersistInstance } =
    usePopupFormAndMutation({
      type,
      useMutation: useSubscribeMutation,
      // fieldsToKeep: ['email', 'newsletter'],
      // mutationArgs: [shortId],
    })

  function closeThisPopup() {
    closePopup()
    if (!formMutation.isLoading) {
      formMutation.reset()
    }
  }

  const subject = encodeURIComponent('Kalkulace ze SrovnejDřevo.cz')
  const body = encodeURIComponent(
    `Ahoj, posílám ti odkaz na kalkulaci ze SrovnejDřevo.cz. Kalkulace je na odkazu: <a href="${shareLink}">${shareLink}</a>`,
  )
  const emailShareUrl = `mailto:?subject=${subject}&body=${body}`
  const fbShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${shareLink}`

  return isOpen ? (
    <Popup>
      <PopupContent>
        <CloseIcon onClick={closeThisPopup} />
        <Title.SectionTitle>Sdílet kalkulaci</Title.SectionTitle>
        <Gap gap="8px" />

        <Text textAlign="center">
          Sdílejte odkaz na vaší kalkulaci s ostatními
        </Text>
        <Gap gap="32px" />

        <Row justifyContent="center" alignItems="center">
          <a
            href={fbShareUrl}
            onClick={async () => {
              sendEvent('share_calc_clicked', {
                eventShareCalcClickedVariant: 'facebook',
              })
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ShareIcon name="ShareFacebook" />
          </a>
          <a
            href={emailShareUrl}
            onClick={async () => {
              sendEvent('share_calc_clicked', {
                eventShareCalcClickedVariant: 'email',
              })
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ShareIcon name="ShareEmail" />
          </a>

          <BasicTooltip
            title={
              copiedStatus === 'ok'
                ? 'Odkaz zkopírován do schránky'
                : 'Klikněte pro zkopírování odkazu'
            }
            placement="bottom"
          >
            <span
              onClick={async () => {
                sendEvent('share_calc_clicked', {
                  eventShareCalcClickedVariant: 'link',
                })
                const copyResult = await copyToClipboard(shareLink)
                setCopiedStatus(copyResult ? 'ok' : 'error')
              }}
            >
              <ShareIcon
                name={copiedStatus === 'ok' ? 'ShareCheck' : 'ShareLink'}
                last
              />
            </span>
          </BasicTooltip>
        </Row>
        {copiedStatus === 'error' && (
          <Text textAlign="center">
            ⚠️&nbsp; Nepodařilo se zkopírovat odkaz. Zkuste to ručně:
            <br />
            {shareLink}
          </Text>
        )}

        <Gap gap="56px" />

        <Title.SectionSubTitle>Neprošvihněte nové funkce</Title.SectionSubTitle>
        <Gap gap="8px" mobileGap="16px" />

        <Text textAlign="center">
          Jako je hlídací pes, monitoring cen, nové kalkulačky a srovnavače, a
          aktuální dění ze světa dřeva.
          <br />
          Vložte svůj email a budete to vědět jako první.
        </Text>

        <Gap gap="16px" mobileGap="16px" />

        <Formik
          initialValues={{
            email: '',
            type,
            amountsResult: amountsResultId,
          }}
          validationSchema={sendCalcSchema}
          onSubmit={onSubmit}
        >
          {({ submitForm, setFieldValue, isSubmitting, ...rest }) => (
            <Form>
              {formikPersistInstance}
              <Col maxWidth="550px" alignItems="center" width="100%">
                <Row
                  alignItems="flex-start"
                  justifyContent="stretch"
                  responsive
                  mobileAlignItems="stretch"
                  width="100%"
                  maxWidth="400px"
                >
                  <Input
                    name="email"
                    type="email"
                    label="E-mail"
                    labelAsPlaceholder
                    width="100%"
                  />

                  <Gap gap="8px" mobileGap="0px" />

                  {!formMutation.isSuccess && (
                    <Button.FormikSubmitButton
                      preset="subscribe"
                      onClick={() => {
                        updateUserProperties({
                          userPropSubscriber: 1,
                        })
                        sendEvent('subscribe', {
                          eventSubscribeVariant: 'shareCalcPopup',
                        })
                      }}
                    >
                      {formMutation.isLoading ? 'Odesílám...' : 'Přihlásit'}
                    </Button.FormikSubmitButton>
                  )}

                  {formMutation.isSuccess && (
                    <Col>
                      <Gap gap="8px" mobileGap="0" />
                      <Text color="green">✅&nbsp;Odesláno</Text>
                    </Col>
                  )}
                </Row>
                <Gap gap="4px" mobileGap="16px" />

                <Text.SmallGrayText>
                  Žádný spam a kdykoli se můžete odhlásit.
                </Text.SmallGrayText>
                <Gap gap="24px" />
              </Col>
            </Form>
          )}
        </Formik>
      </PopupContent>
    </Popup>
  ) : null
}

export default ShareCalcPopup
