const ADDRESS_STORAGE_KEY = 'calc-form-address'
export const saveAddress = (address) => {
  try {
    window.localStorage.setItem(ADDRESS_STORAGE_KEY, JSON.stringify(address))
  } catch (error) {
    console.error('error saving address to localStorage', error)
  }
}

export const findSavedAddress = () => {
  try {
    if (typeof window === 'undefined') {
      return null
    }
    const savedData = window.localStorage.getItem(ADDRESS_STORAGE_KEY)
    if (savedData) {
      const parsed = JSON.parse(savedData)

      return parsed
    }
  } catch (error) {
    console.log('error accessing local storage', error)
  }
  return null
}
