import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import {
  addResponsivity,
  up,
  down,
  between,
  addFont,
  upBp,
  prop,
} from '../lib/styles'
import { Col, Row, Text, Title, Gap, Link } from '.'

const Wrapper = styled.div`
  ${({ theme: { colors }, vertical, maxWidth }) => css`
    display: flex;
    flex-direction: ${vertical ? 'column' : 'row'};
    align-items: center;
    text-align: ${vertical && 'center'};
    max-width: ${maxWidth};

    ${upBp} {
    }
  `}
`

// const Number = styled.div`
//   ${({
//     theme: { colors, mobileNumberSize: mobileNumberSizeTheme = '102px' },
//     mobileNumberSize,
//   }) => css`
//     ${addFont('bree')}
//     font-size: ${mobileNumberSize || mobileNumberSizeTheme};
//     color: ${colors.brown};
//     ${upBp} {
//       font-size: 102px;
//     }
//   `}
// `

const Number = styled.div`
  ${({ theme: { colors } }) => css`
    ${addFont('bree')}
    font-size: ${prop('mobileNumberSize', '102px')};
    color: ${colors.brown};
    ${upBp} {
      font-size: 102px;
    }
  `}
`

class NumberClaim extends Component {
  render() {
    const { children, value, vertical, maxWidth, mobileNumberSize } = this.props
    return (
      <Wrapper vertical={vertical} maxWidth={maxWidth}>
        <Number mobileNumberSize={mobileNumberSize}>{value}</Number>
        <Gap />
        <Text fontSize="26px" lineHeight="32px">
          {children}
        </Text>
      </Wrapper>
    )
  }
}

export default NumberClaim
