import React, { useCallback, useContext, useEffect, useState } from 'react'
import CustomerComplaintPopup from './CustomerComplaintPopup'
import FeedbackPopup from './FeedbackPopup'
import QuestionPopup from './QuestionPopup'
import ExitIntentPopup from './ExitIntentPopup'
import SendCalcPopup from './SendCalcPopup'
import ShareCalcPopup from './ShareCalcPopup'
import CalcPopup from './Cart/CalcPopup'

// README: add your popup component to config below
// use const popup = usePopup(type) hook
// call popup.openPopup(props) -- props are injected to your popup

const popupsConfig = {
  DO_NOT_LEAVE: {
    // type: 'DO_NOT_LEAVE',
    Component: FeedbackPopup,
  },
  // EXIT_INTENT: {
  //   Component: ExitIntentPopup,
  // },
  QUESTION: {
    Component: QuestionPopup,
  },
  PRICE_MAP: {
    Component: FeedbackPopup,
    defaultProps: {
      storageKey: 'popup-price-map',
      showFaq: false,
      messagePlaceholder: '',
    }, // popup can have default props
  },
  CUSTOMER_COMPLAINT: {
    Component: CustomerComplaintPopup,
    defaultProps: { justExample: true }, // popup can have default props
  },
  SEND_CALC: {
    Component: SendCalcPopup,
    // defaultProps: { justExample: true }, // popup can have default props
  },
  SHARE_CALC: {
    Component: ShareCalcPopup,
    dismountOnClose: true,
  },
  EDIT_CALC: {
    Component: CalcPopup,
    dismountOnClose: true,
  },
}

export const popupTypes = Object.keys(popupsConfig).reduce((acc, key) => {
  acc[key] = key

  return acc
}, {})

const createPopupsContext = () => {
  const popupContext = React.createContext({})
  const { Provider, Consumer } = popupContext

  const PopupsProvider = ({ children, enabled = true }) => {
    const [popupsState, setPopupsState] = useState({
      // for testing purposes
      // DO_NOT_LEAVE: true,
      // EXIT_INTENT: true,
    })
    const [popupsProps, setPopupsProps] = useState({})

    const setPopupState = useCallback((typeKey, state, props = {}) => {
      setPopupsState((prevState) => ({ ...prevState, [typeKey]: state }))
      setPopupsProps((prevState) => ({ ...prevState, [typeKey]: props }))
    }, [])

    const openPopup = useCallback(
      (typeKey, props) => {
        setPopupState(typeKey, true, props)
      },
      [setPopupState],
    )

    const closePopup = useCallback(
      (typeKey, props) => {
        setPopupState(typeKey, false, props)
      },
      [setPopupState],
    )

    const sharedValues = {
      popupsState,
      popupsProps,
      setPopupState,
      openPopup,
      closePopup,
      popupTypes,
      closeAllPopups: () => {
        Object.keys(popupsConfig).forEach((typeKey) => {
          closePopup(typeKey)
        })
      },
    }

    return (
      <Provider value={sharedValues}>
        {children}
        {enabled &&
          Object.keys(popupsConfig).map((typeKey) => {
            const { Component, defaultProps, dismountOnClose } =
              popupsConfig[typeKey]
            const props = popupsProps[typeKey]

            if (dismountOnClose && !popupsState[typeKey]) {
              return null
            }
            return (
              <Component
                type={typeKey}
                key={typeKey}
                isOpen={popupsState[typeKey]}
                openPopup={() => {
                  openPopup(typeKey)
                }}
                closePopup={() => {
                  closePopup(typeKey)
                }}
                setPopupState={(state) => {
                  setPopupState(typeKey, state)
                }}
                {...defaultProps}
                {...props}
              />
            )
          })}
      </Provider>
    )
  }

  return {
    PopupsProvider,
    popupContext,
  }
}

export const { PopupsProvider, popupContext } = createPopupsContext()

export default PopupsProvider
