import React, { Component, useEffect } from 'react'

import styled, { css } from 'styled-components'
import { Formik, useField } from 'formik'
import Loading from '@kiwicom/orbit-components/lib/Loading'
import HintTooltip from './HintTooltip'
import Error from './Error'
import Label from './Label'
import { StyledInput } from './Input'
import {
  addResponsivity,
  up,
  down,
  between,
  addFont,
  colors,
} from '../../lib/styles'
import { Col, Row, Text, Title, Gap, Link, VH, Button } from '..'
import SMapPlaces from './SMapPlaces'
import {
  accurateSources,
  allowedAddressSources,
  nonAccurateSources as defaultNonAccurateSources,
} from '../../lib/geo'
import CrosshairIcon from '../icons/CrosshairIcon'
import { useGlobalContext } from '../GlobalContext'
import { initialAddress } from '../Calculator/initialValues'
import Success from './Success'

const DropdownPositioner = styled.div`
  position: relative;
`
const InputLoadingPositioner = styled.div`
  position: absolute;
  top: 2px;
  right: 0px;
  width: 44px;
  height: 37px;
`

const StyledBlock = styled.div`
  ${({ styles }) => css`
    ${styles}
  `}
`

const DropdownWrapper = styled.div`
  ${({ theme: { colors }, hide }) => css`
    border-radius: 8px;
    border: solid thin ${colors.checkRadioGrey};
    overflow: hidden;
    background: white;
    margin: 0 2px;
    position: absolute;
    z-index: 2;
    max-height: 320px;
    overflow-y: auto;
    width: calc(100% - 4px);

    ${hide &&
    css`
      display: none;
    `}
  `}
`

const DropdownItem = styled.div`
  ${({ theme: { colors }, active }) => css`
    ${addFont('signika')}
    padding: 8px 15px;
    cursor: pointer;

    &:hover {
      background: ${colors.inputGrey};
    }
  `}
`

const DropdownItemDescription = styled.div`
  ${({ theme: { colors }, active }) => css`
    font-size: 12px;
  `}
`
const DefaultHint = styled.span`
  ${({ theme: { colors }, active }) => css`
    ${addFont('signika')}
    font-size: 14px;
    color: ${colors.textGrey};
    /* align-self: flex-start; */
  `}
`

let isGeoLocationSuggestionClick = false

const localNavigator = typeof navigator !== 'undefined' ? navigator : {}

export class SLocation extends React.Component {
  state = {
    suggestions: [],
    prevInputValue: this.props.initialValue,
    inputValue: this.props.initialValue,
    lastValidValue: this.props.initialValue,
    showSuggestions: false,
    // showSuggestions: true,
    itemWasSelected: false,
    showSelectViaGeoLocation: !!localNavigator.geolocation,
    isGeoLocationLoading: false,
  }

  static getDerivedStateFromProps(props, state) {
    // because of lazy load persistent formik is passing address text value

    // console.log(
    //   'getDerivedStateFromProps',
    //   props.id,
    //   props.initialValue,
    //   state.prevInitialValue,
    //   props.initialValue !== state.prevInitialValue,
    // )
    // if (props.initialValue && !state.prevInitialValue) {
    if (props.initialValue && props.initialValue !== state.prevInitialValue) {
      // console.log(
      //   'INITIAL VALUES APPLIED',
      //   props.initialValue,
      //   state.prevInitialValue,
      // )
      return {
        prevInitialValue: props.initialValue,
        inputValue: props.initialValue,
        lastValidValue: props.initialValue,
      }
    }
    return null
  }

  componentWillUnmount() {
    clearTimeout(this.blurTimeout)
  }

  handleChange = (address) => {
    this.setState({ address })
  }

  handleBlur = (suggestions, e) => {
    clearTimeout(this.blurTimeout)

    this.blurTimeout = setTimeout(() => {
      console.log('handleBlur timeout', isGeoLocationSuggestionClick)
      if (isGeoLocationSuggestionClick) {
        return
      }
      // only trigger if we have item and item was not already picked
      // this is for usecase - I write address, I am happy,
      // but I dont select it, only blur it
      if (suggestions?.[0] && !this.state.itemWasSelected) {
        this.handleSelect(suggestions?.[0])
        console.log('handleBlur timeout --> select')
      }
      this.setState({ itemWasSelected: false, showSuggestions: false })
      this.props.onBlur(e)
    }, 1000)
  }

  handleFocus = () => {
    this.setState({ showSuggestions: true })
  }

  handleSelect = (item, isGeoLocation = false) => {
    console.log('handleSelect', item)

    isGeoLocationSuggestionClick = false

    const inputTextLong = `${item.phrase}, ${
      item.secondRow.startsWith('Adresa, ')
        ? item.secondRow.substring(8)
        : item.secondRow
    }`

    // console.log('item inputTextLong', item, inputTextLong)

    // removes prev setup blur timeout, when field loses focus
    clearTimeout(this.blurTimeout)

    this.setState(
      {
        inputValue: inputTextLong,
        lastValidValue: inputTextLong,
        showSuggestions: false,
        // blur is triggered on selecting item
        itemWasSelected: true,
      },
      () => {
        const geo = new SMap.Geocoder.Reverse(
          SMap.Coords.fromWGS84(item.longitude, item.latitude),
          (geocoder) => {
            const results = geocoder.getResults()
            let zip = 'none'
            try {
              zip = results.label.split(',')[2].trim()
              console.log('zip', zip, results)
            } catch (error) {
              console.log('err parsing zip', error)
            }

            const city =
              results.items.find((item) => item.type === 'muni') || {}
            const district =
              results.items.find((item) => item.type === 'dist') || {}
            const region =
              results.items.find((item) => item.type === 'regi') || {}

            // trigger parent formik update
            this.props.onChange({
              address: item.phrase,
              inputTextLong,
              placeId: item.id,
              secondRow: item.secondRow,
              source: item.source,
              zip,
              latLng: {
                lat: item.latitude,
                lng: item.longitude,
              },
              city,
              district,
              region,
            })
            setTimeout(() => {
              this.props.onBlur()
            }, 500)
          },
        )
      },
    )
  }

  render() {
    const { error, id, placeholder, success } = this.props
    const {
      inputValue,
      showSuggestions,
      isGeoLocationLoading,
      showSelectViaGeoLocation,

      prevInitialValue,

      lastValidValue,
    } = this.state

    // console.log('SLOCATION errorerrorerror', error)
    // console.log(
    //   'SLOCATION inputValue',
    //   id,
    //   inputValue,
    //   prevInitialValue,
    //   lastValidValue,
    //   this.props.initialValue,
    // )

    /// TODO: brat formik addressu nejak - asi do initial? resp. p=okud bude existovat, tak si ji vzit a nacpat do inputu
    // bacha ovsem na to, ze input se meni pri psani
    // nebo to vyresit pres reinicialize??? -- updatnout initialData - tady bacha, ze bude rozdil - fresh reload a ze jsem prave vyplnil adresu (v tom rooginal inputu)
    //

    return (
      <SMapPlaces value={inputValue}>
        {({ suggestions, loading }) => {
          const a = 'b'
          return (
            <Col>
              <Col position="relative">
                <StyledInput
                  success={success}
                  placeholder={placeholder}
                  error={error}
                  onBlur={(e) => this.handleBlur(suggestions, e)}
                  value={inputValue}
                  onFocus={() => {
                    this.setState({
                      showSuggestions: true,
                    })
                  }}
                  onChange={(e) => {
                    this.setState({
                      inputValue: e.target.value,
                      showSuggestions: true,
                    })
                  }}
                />
                {isGeoLocationLoading && (
                  <InputLoadingPositioner>
                    <Loading loading type="buttonLoader" />
                  </InputLoadingPositioner>
                )}
              </Col>
              <DropdownPositioner>
                <DropdownWrapper hide={!showSuggestions}>
                  {showSelectViaGeoLocation && (
                    <DropdownItem
                      key="geolocation"
                      active
                      onClick={() => {
                        console.log('geolocation click')
                        isGeoLocationSuggestionClick = true
                        this.setState({
                          showSelectViaGeoLocation: false,
                          isGeoLocationLoading: true,
                        })
                        localNavigator?.geolocation.getCurrentPosition(
                          (position) => {
                            console.log('position', position)

                            const { latitude, longitude } = position.coords

                            const geo = new SMap.Geocoder.Reverse(
                              SMap.Coords.fromWGS84(longitude, latitude),
                              (geocoder) => {
                                const results = geocoder.getResults()

                                // save the most detailed source we have
                                let source = null
                                results.items.forEach((item) => {
                                  if (
                                    !accurateSources.includes(source) &&
                                    allowedAddressSources.includes(item.type)
                                  ) {
                                    source = item.type
                                  }
                                })

                                const itemForSelect = {
                                  phrase: results.label,
                                  secondRow: results.label,
                                  source,
                                  latitude,
                                  longitude,
                                }

                                this.setState({
                                  showSelectViaGeoLocation: true,
                                  isGeoLocationLoading: false,
                                })
                                this.handleSelect(itemForSelect, true)
                              },
                            )
                          },
                          (error) => {
                            console.log('error', error)
                            this.setState({
                              showSelectViaGeoLocation: true,
                              isGeoLocationLoading: false,
                            })

                            let errorMessage = error.message
                            switch (error.code) {
                              case error.PERMISSION_DENIED:
                                errorMessage =
                                  'Nepovolili jste zjištění polohy.'
                                break
                              case error.POSITION_UNAVAILABLE:
                                errorMessage = 'Poloha není dostupná.'
                                break
                              case error.TIMEOUT:
                                errorMessage =
                                  'Vypršel časový limit zjišťování polohy. Zkuste to prosím znovu nebo zvolte adresu ručně.'
                                break
                              default:
                                errorMessage =
                                  'Nepodařilo se zjistit vaši polohu.'
                                break
                            }

                            alert(errorMessage)
                          },
                        )
                      }}
                    >
                      {/* iconType: "geo"
                        iconUrl: ""
                        id: 9903382
                        latitude: 50.1458282335335
                        longitude: 13.6611274276519
                        phrase: "Chrášťany 85"
                        poiTypeId: 0
                        secondRow: "Chrášťany, okres Rakovník, Středočeský kraj, Česko"
                        source: "addr"
                        thirdRow: "Adresa"
                        title: "Chrášťany 85" */}
                      <Row alignItems="center">
                        <StyledBlock styles="margin-left:-4px">
                          <CrosshairIcon width={24} height={24} color="#000" />
                        </StyledBlock>
                        <Gap gap="4px" />
                        <div>Zvolit adresu podle aktuální polohy</div>
                      </Row>
                    </DropdownItem>
                  )}
                  {loading && isGeoLocationLoading && (
                    <DropdownItem>Nahrávám...</DropdownItem>
                  )}
                  {suggestions.map((suggestion) => (
                    <DropdownItem
                      key={suggestion.id}
                      active={suggestion.active}
                      onClick={() => {
                        this.handleSelect(suggestion)
                      }}
                    >
                      {/* iconType: "geo"
                        iconUrl: ""
                        id: 9903382
                        latitude: 50.1458282335335
                        longitude: 13.6611274276519
                        phrase: "Chrášťany 85"
                        poiTypeId: 0
                        secondRow: "Chrášťany, okres Rakovník, Středočeský kraj, Česko"
                        source: "addr"
                        thirdRow: "Adresa"
                        title: "Chrášťany 85" */}
                      <div>{suggestion.phrase}</div>
                      <DropdownItemDescription>
                        {suggestion.secondRow.startsWith('Adresa, ')
                          ? suggestion.secondRow.substring(8)
                          : suggestion.secondRow}
                      </DropdownItemDescription>
                    </DropdownItem>
                  ))}
                </DropdownWrapper>
              </DropdownPositioner>
            </Col>
          )
        }}
      </SMapPlaces>
    )
  }
}

const SLocationInput = ({
  name,
  label,
  hintLabel = 'Proč je to potřeba?',
  hint, // has to be filled to show error msg
  hintNonAccurateLabel = 'Adresa není přesná',
  hintNonAccurate = 'Adresa není přesná',
  nonAccurateSources = defaultNonAccurateSources,
  onChange,
  id,
  initialValue,
  hideHint,
  placeholder,
  showSuccess,
  successLabel,
}) => {
  const [field, meta, helpers] = useField(name)
  const { value } = meta
  const { setTouched, setValue } = helpers

  // console.log('SLocationInput field, meta, helpers', field, meta, helpers)

  const handleChange = (value) => {
    setValue(value)

    console.log('SLocationInput updated', value)
    onChange?.(value)
    setTouched()
  }
  const handleBlur = (event, ignore) => {
    console.log('ignore', ignore)
    if (ignore) {
      return
    }
    setTouched()
  }

  const showError = meta.touched && meta.error

  // console.log(
  //   'showErrorshowErrorshowError meta.initialValue',
  //   id,
  //   showError,
  //   meta.touched,
  //   meta.error,
  //   initialValue,
  //   initialValue?.inputTextLong,
  // )

  // console.log('valuevaluevaluevaluevalue', value.source)

  const isNonAccurate = nonAccurateSources.includes(value.source)

  // console.log('isNonAccurateisNonAccurate',isNonAccurate , value.source, showError, meta.error)

  const ErrorHintTooltip = hint ? HintTooltip : React.Fragment

  return (
    <Col>
      {label && (
        <>
          <Row>
            <Label htmlFor={field.name}>{label}</Label>
            <HintTooltip hint={hint} />
          </Row>
          <Gap />
        </>
      )}
      <SLocation
        id={id}
        onChange={handleChange}
        onBlur={handleBlur}
        error={showError}
        initialValue={initialValue?.inputTextLong}
        // this resets whole component on address string change!
        // key={field.value && field.value.address}
        // key={meta.initialValue?.inputTextLong}
        placeholder={placeholder}
        success={showSuccess && meta.touched && !meta.error && field.value}
      />
      <Col alignItems={!showError && 'flex-start'}>
        {showError && (
          <ErrorHintTooltip hint={hint}>
            <Error noPosition name={name} error={showError} width="100%" />
          </ErrorHintTooltip>
        )}
        {showSuccess &&
          meta.touched &&
          !meta.error &&
          field.value &&
          successLabel && <Success text={successLabel} />}
        {!showError && !isNonAccurate && (
          <HintTooltip hint={hint} noIcon>
            <DefaultHint>{hintLabel}</DefaultHint>
          </HintTooltip>
        )}
        {!showError && isNonAccurate && (
          <HintTooltip hint={hintNonAccurate} infoIcon>
            <DefaultHint>{hintNonAccurateLabel}</DefaultHint>
          </HintTooltip>
        )}
      </Col>
      <Gap gap="16px" />
    </Col>
  )
}

export default SLocationInput

export const GlobalSLocationInput = (props) => {
  const {
    globalNamespace = 'calcAddress',
    localFieldName = 'address',
    ...inputProps
  } = props

  const [field, meta, helpers] = useField(props.name)

  const { globalInputs, setGlobalInputs } = useGlobalContext()
  const { updateGlobalAddress } = useUpdateGlobalAddress(globalNamespace)

  const initialValues = globalInputs[globalNamespace] || {
    address: initialAddress,
  }
  const onChange = (value) => {
    updateGlobalAddress(value)
  }

  useEffect(() => {
    // on did mount, push revived address to parent formik

    helpers.setValue(initialValues.address)
  }, [initialValues.address])

  return (
    <SLocationInput
      {...inputProps}
      type="text"
      initialValue={initialValues.address}
      onChange={onChange}
    />
  )
}

export const GlobalSLocationInput2 = (props) => {
  const {
    globalNamespace = 'calcAddress',
    localFieldName = 'address',
    // if you pass null, it will not use parent formik
    useParentFormikField = useField,
    ...inputProps
  } = props

  const { globalInputs, setGlobalInputs } = useGlobalContext()
  const { updateGlobalAddress } = useUpdateGlobalAddress(globalNamespace)

  const initialValues = globalInputs[globalNamespace] || {
    address: initialAddress,
  }

  const [field, meta, helpers = {}] =
    useParentFormikField?.(localFieldName) || []

  const {
    setTouched: setParentAddressTouched,
    setValue: setParentAddressValue,
  } = helpers

  if (!globalNamespace) {
    console.error('GlobalSLocationInput missing global name', globalNamespace)
    return null
  }

  useEffect(() => {
    // on did mount, push revived address to parent formik
    if (useParentFormikField) {
      console.log(
        'setting to parent formik ON DID MOUNT',
        initialValues.address,
      )
      setParentAddressValue(initialValues.address)
    }
  }, [])

  const onChange = (value) => {
    updateGlobalAddress(value)
    if (useParentFormikField) {
      console.log('setting to parent formik', value)
      setParentAddressValue(value)
      setParentAddressTouched()
    }
  }

  // console.log('initialValues --- ADDDDD', initialValues.address?.address)

  console.log(
    'inputPropsinputPropsinputPropsinputProps',
    inputProps,
    initialValues.address,
  )

  return (
    <Formik
      initialValues={
        // this is initial values for this formik only, used for validations and field state
        initialValues
      }
      // enableReinitialize
      validate={(values) => {
        console.log('global address validation!!', values)
        const errors = validateFormikAddress(values)
        return errors
      }}
    >
      <SLocationInput
        {...inputProps}
        // in this formik it is always address
        name="address"
        type="text"
        initialValue={initialValues.address}
        onChange={onChange}
      />
    </Formik>
  )
}

export const useUpdateGlobalAddress = (globalNamespace = 'calcAddress') => {
  const { globalInputs, setGlobalInputs } = useGlobalContext()

  const updateGlobalAddress = (value) => {
    setGlobalInputs((prev) => ({
      ...prev,
      [globalNamespace]: {
        ...prev[globalNamespace],
        address: value,
      },
    }))
  }

  return {
    updateGlobalAddress,
  }
}

// TODO: make validations customizable for cart etc.
export const validateAddress = (value) => {
  let error
  // custom validate address
  if (!value || !value.address) {
    error = 'Bez adresy cenu nevíme'
  } else if (!allowedAddressSources.includes(value.source)) {
    // not full address
    error = 'Zadejte přesnější adresu'
  }

  return error
}

export const validateOrderAddress = (value) => {
  let error
  // custom validate address
  if (!value || !value.address) {
    error = 'Bez adresy nelze objednat'
  } else if (value.source !== 'addr') {
    // not full address
    error = 'Zadejte přesnou adresu'
  }

  return error
}

export const validateFormikAddress = (
  values,
  { name = 'address', validation = validateAddress } = {},
) => {
  const errors = {}
  const addressError = validation(values[name])
  if (addressError) {
    errors[name] = addressError
  }

  return errors
}
