import React from 'react'
/**
 * Almost all of our helper functions goes here
 * Style helpers go to ./style.js
 */

// extract nodes from edges, if no data returns empty array like in rest
// (graphql returns null if nothing is found in db...)
export const extractNodes = (data) => (data && data.edges && data.edges.map((e) => e.node)) || []

// extract all edges - nodes data from all data results from DB / graphql
export const extractNodesFromData = (propsData = {}) => {
  const transformed = { ...propsData }
  Object.keys(propsData).forEach((key) => {
    // some data does not have to have edges, skip them
    if (propsData[key]?.edges) {
      transformed[key] = propsData[key].edges.map((e) => e.node)
    }
  })

  return transformed
}

export const withSameTags = (arrayToFilter = [], tags = []) => arrayToFilter.filter(
  (p) => p.tags &&
      p.tags.some((tag) => tags && tags.find((tag2) => tag2._id === tag._id)),
)

export const getMinutesFormat = (num) => {
  if (!num) {
    return ''
  }
  if (num === 1) {
    return 'minuta'
  }
  return num < 5 ? 'minuty' : 'minut'
}

export function fullNormalizeForSearch(text = '') {
  return (
    text
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // remove diacritics
      // .replace(/\.|:|,/g, ' ') // remove dots, colons, commas
      .replace(/:|-|_/g, ' ') // remove colons, dashes, underscores
      .replace(/,/g, '.') // replace commas with dots
      .replace(/\s\s+/g, ' ') // multiple spaces
      .trim()
  ) // trim spaces
}

export function fullNormalizeDiscountCode(text = '') {
  return (
    text
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // remove diacritics
      // .replace(/\.|:|,/g, ' ') // remove dots, colons, commas
      // .replace(/:|-|_/g, ' ') // remove colons, dashes, underscores
      .replace(/,/g, '.') // replace commas with dots
      .replace(/\s\s+/g, ' ') // multiple spaces
      .trim()
  ) // trim spaces
}

export function getFormikErrorsFromMutationError(err) {
  const formErrors = err?.errors?.reduce?.((acc, e) => {
    acc[e.path] = e.message
    return acc
  }, {})

  return formErrors
}
export function getFormikErrorsFromAxiosError(e) {
  const formErrors = e?.response?.data?.errors?.reduce?.((acc, e) => {
    acc[e.path] = e.message
    return acc
  }, {})

  return formErrors
}

export function classicRound(num, places = 1) {
  return Math.round(num * 10 ** places) / 10 ** places
}

export function formatNumberWithThousands(x, places) {
  return classicRound(x, places)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const nbsp = String.fromCharCode(160)

export function formatCurrency(x, places, nonBreakableSpace) {
  const result = `${formatNumberWithThousands(x, places)} Kč`
  return nonBreakableSpace ? <>{result.split(' ').join(nbsp)}</> : result
}

// sorting numbers - create typed array and call sort
// const sortedPrices = new Float32Array([1,99,2]).sort()

export const getUrlParam = (key) => {
  if (typeof window === 'undefined') {
    return null
  }
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(key)
}

export const copyToClipboard = async (text) => {
  // return false
  try {
    await navigator.clipboard.writeText(text)
    console.log('Text copied to clipboard')
    return true
  } catch (error) {
    console.error('Could not copy text: ', error)
    return false
  }
}

export function mapMdxPostToDbPost(p, type) {
  return {
    ...p,
    isMdx: true,
    slug: p.frontmatter.slug.substring(1),
    title: p.frontmatter.title,
    content: p.body,
    content_readingTimeMinutes: p.fields.readingTime.minutesRounded,
    content_plainTextSimple:
      p.perex || type === 'glossary' ? p.glossaryExcerpt : p.excerpt,
    appendCalculator: p.frontmatter.appendCalculator,
    calculatorWoodVariant: p.frontmatter.calculatorWoodVariant,
    publishedAt: p.frontmatter.date,

    /// TODO: fix this
    featuredImage: {
      description: p.frontmatter.heroImageAlt,
      image: {
        full: p.frontmatter.heroImage,
        thumb: p.frontmatter.featuredImageThumb,
      },
    },
  }
}

export function getPostPath(post) {
  return `/${post.slug}`
}
