import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ResponsiveLine } from '@nivo/line'
// import { linearGradientDef } from '@nivo/core'

import styled, { css, keyframes } from 'styled-components'
// import { nanoid } from 'nanoid'
import { nanoid } from 'nanoid'
import { StyledSlider } from '../fields/Slider'
import Colorizer, { ColorizedSpan } from '../Colorizer'
import { HintTooltip } from '../fields'
import { Col, Row, Text, Title, Gap, Link, VH, Button, RH } from '..'
import { formatNumberWithThousands } from '../../lib/helpers'
import { up, colors } from '../../lib/styles'
// import amountBracketPath from '../data/images/amountBracket.svg'
// import AmountBracket from './AmountBracket'
import { apiData } from './AmountCalcChartExampleData'

import { usePopup } from '../hooks/usePopups'
import { popupTypes } from '../PopupsProvider'
import { getCalcShareLink } from '../../../lib/order'
import { useExperiments } from '../hooks/useExperiments'
import { PalettesInfo, colorizePrice } from './helpers'
import Sharing from './Sharing'

const CHART_MOBILE_BP = 700

function isChartMobile() {
  if (typeof window === 'undefined') {
    return false
  }

  const isMobile = !window.matchMedia('only screen and (min-width: 700px)')
    .matches

  return isMobile
}

function useWindowResizeCallback({ debounce = 400, callback }) {
  const timerRefInitial = useRef(null)

  useEffect(() => {
    function handleResize() {
      clearTimeout(timerRefInitial.current)
      timerRefInitial.current = setTimeout(() => {
        requestAnimationFrame(callback)
      }, debounce)
    }

    window.addEventListener('resize', handleResize)

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, [callback])

  return null
}

export function useChartState() {
  const [isMobile, setIsChartMobile] = useState(isChartMobile())
  const onWindowResize = useCallback(() => {
    console.log('resized', isChartMobile())

    setIsChartMobile(isChartMobile())
  }, [])

  useWindowResizeCallback({ callback: onWindowResize })

  return {
    isMobile,
  }
}

const GraphTooltipRow = styled.div`
  &:not(:last-child) {
    margin: 0 0 12px 0;
  }
`
const GraphTooltipWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    color: black;
    font-size: 16px;

    background: white;
    border-radius: 10px;
    padding: 16px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);

    display: none;
    ${up('mobile')} {
      display: block;
    }

    & strong {
      font-size: 20px;
    }
    & span {
      font-size: 15px;
      color: ${colors.textGrey};
    }
  `}
`

const ChartWrapperInner = styled.div`
  ${({ theme: { colors } }) => css`
    max-width: 800px;
    /* max-height: 500px; */
    height: 200px;
    width: 91vw;
    /* height: 100%; */
    position: relative;

    ${up(CHART_MOBILE_BP)} {
      width: 90vw;
      height: 350px;
    }
  `}
`

const ChartWrapper = styled.div`
  ${({ theme: { colors }, noShadow }) => css`
    max-width: 840px;
    width: 100%;

    background: white;
    border-radius: 10px;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    /* box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25); */

    ${!noShadow &&
    css`
      box-shadow: 0px 8px 24px rgb(0 0 0 / 25%);
    `}

    padding: 20px 8px 32px;
    ${up(CHART_MOBILE_BP)} {
      padding: 20px 16px 40px;
    }

    .tooltip-wrap > div > div > div {
      background: white !important;
      border-radius: 10px !important;
      padding: 10px !important;
      box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);

      & > div {
        ${up(CHART_MOBILE_BP)} {
          border-right-color: white !important;
          border-left-color: white !important;
        }
      }
    }

    .tickLabel {
      font-size: 15px !important;
      fill: #505050 !important;
      font-weight: 500;
      font-family: 'Signika' !important;
    }

    .gridLine {
      stroke: rgb(0 0 0 / 100%);
      stroke-dasharray: 3 4;
    }
  `}
`
const HintTooltipPositioner = styled.div`
  position: absolute;
  bottom: -10px;
  right: 44px;
`

const BoxWrapper = styled.div`
  ${({ theme: { colors } }) => css`
    background: white;
    border-radius: 10px;
    padding: 24px 32px 32px;

    display: flex;
    flex-direction: column;
    align-items: center;

    box-shadow: 0px 8px 24px rgb(0 0 0 / 25%);
  `}
`

const MobileLegend = styled.div`
  ${({ theme: { colors }, top, left, bottom, right }) => css`
    position: absolute;
    font-size: 13px;

    top: ${top};
    left: ${left};
    bottom: ${bottom};
    right: ${right};

    ${up(CHART_MOBILE_BP)} {
      display: none;
    }
  `}
`
const MobileAmountLegend = styled.div`
  ${({ theme: { colors }, top, left, bottom, right }) => css`
    font-size: 13px;

    ${up(CHART_MOBILE_BP)} {
      display: none;
    }
  `}
`

const TextLabel = ({ children, ...props }) => (
  <Text fontSize="20px" mobileFontSize="18px">
    {children}
  </Text>
)

const TextValue = ({ children, ...props }) => (
  <Text fontSize="28px" mobileFontSize="26px" fontWeight="600">
    {children}
  </Text>
)

const TextSuffix = ({ children, ...props }) => (
  <Text fontSize="16px" mobileFontSize="15px" color="textGrey">
    {children}
  </Text>
)

function CustomTooltip({ point }) {
  // console.log('point', point)
  return point ? (
    <GraphTooltipWrapper>
      <GraphTooltipRow>
        Množství:
        {' '}
        <strong>
          {point.data.x}
          {' '}
          m&sup3;
        </strong>
        {' '}
        sypaných
        <br />
        <span>
          cca
          {' '}
          {Math.ceil(point?.data?.x * 0.625 * 10) / 10}
          {' '}
          m&sup3; rovnaných
        </span>
      </GraphTooltipRow>
      <GraphTooltipRow>
        Cena za 1 m&sup3;:
        {' '}
        <strong>
          {formatNumberWithThousands(point.data.y)}
          {' '}
          Kč
        </strong>
      </GraphTooltipRow>
      <GraphTooltipRow>
        Celková cena:
        {' '}
        <strong>
          {formatNumberWithThousands(point.data.totalPrice)}
          {' '}
          Kč
          {' '}
        </strong>
        (vč. dopravy)
      </GraphTooltipRow>

      {!!point.data.offer.numOfPalettes && (
        <PalettesInfo result={point.data.offer} />
      )}

      {/* <Gap gap="8px" />
      <Colorizer color="red">
        Pro objednání konkrétního množství,
        <br />
        klikněte na modrou tečku v grafu
      </Colorizer> */}
    </GraphTooltipWrapper>
  ) : null
}

function AmountCalcChart({
  data = apiData,
  handleStartOrder,
  amountsResultId,
  amountsResultShortId,
  calcValues,
  amountIndex,
  handleAmountChange,
  noShadow,
  noSharing,
  noTitle,
}) {
  // function AmountCalcChart({ data, handleStartOrder }) {
  const { isMobile } = useChartState()
  const { dimensions } = useExperiments()

  // console.log('amountsResultIdamountsResultId', amountsResultId)

  // const [refreshedData, setRefreshedData] = useState(data)
  // this is just for refresh the component after data update to trigger
  // multiple renders to keep graph line not broken :O
  const [wasCleared, setWasCleared] = useState(false)
  const [dataKey, setDataKey] = useState(nanoid())
  const [seriesData, setSeriesData] = useState([])

  const inputData = React.useMemo(() => {
    // console.log('data updated --> input data')

    // const thisNanoId = nanoid()

    setWasCleared((wasCleared) => !wasCleared)

    return [
      ...data.map((d, index) => ({
        x: d.amount,
        y: d.price,
        totalPrice: d.totalPrice,
        offer: d.offer,
        // key: `${thisNanoId}-${index}`,
      })),
    ]
  }, [data])

  useEffect(() => {
    setSeriesData([
      {
        id: 'priceAmount',
        data: inputData,
      },
    ])
  }, [inputData])

  const sortedData = [...inputData].sort((a, b) => (a.y < b.y ? -1 : 1))
  const sortedDataForExtremes = [...inputData]
    // only first 10 points for extremes
    .splice(0, 10)
    .sort((a, b) => (a.y < b.y ? -1 : 1))

  // console.log('sortedDataForExtremes', sortedDataForExtremes)

  const defaultMinPoint = sortedDataForExtremes[0]
  const minPoint = sortedData[0]
  const maxPoint = sortedData[sortedData.length - 1]
  const yHardMax = maxPoint?.y + (isMobile ? 50 : 60)
  const yHardMin = minPoint?.y - (isMobile ? 50 : 60)

  const minPointIndex = inputData.indexOf(defaultMinPoint)

  useEffect(() => {
    console.log('data updated')
    // if data updated, set new active index
    // setamountIndex(minPointIndex)
  }, [data])

  const handleSliderChange = (event, newValue) => {
    handleAmountChange(newValue)
  }

  const activeDatum = inputData[amountIndex] || inputData[minPointIndex]

  const valueColor = colorizePrice({
    amountOffers: apiData,
    amountIndex,
  })

  const hintBlock = (
    <Col alignSelf="center" alignItems="center">
      <Text textAlign="center" fontSize="16px" color="red">
        Tip: Pro nejlevnější množství hledejte nejnižší bod v grafu.
      </Text>
      {/* <Gap gap="8px" /> */}
    </Col>
  )

  const hintTooltip = (
    <HintTooltipPositioner>
      <RH showAfter={CHART_MOBILE_BP}>
        <Text textAlign="right" fontSize="16px">
          <HintTooltip
            withoutGap
            noIcon
            enablePortal
            title={(
              <span>
                Graf je plně interaktivní!
                <br />
                <br />
                Tip: Pro nejlevnější množství hledejte nejnižší bod v grafu.
                <br />
                <br />
                Graf ukazuje vývoj ceny dle množství. Fakt je ten, že v ceně
                objednávky je mnoho proměnných - jako je doprava. Každý
                dřevorubec má jinak velké auto - často tak vychází nejlépe
                zaplnit celé auto. Různí dřevorubci zaváží různé množství různě
                daleko.
                <br />
                <br />
                Výsledky pak vidíte v grafu a tak přehledně najdete nejlepší
                cenu za 1 m&sup3;. Pokud tedy hledáte nejlepší poměr cena /
                množství, pak vám tato kalkulačka ukáže ideální množství s
                nejlepší cenou.
                <br />
                <br />
                Pro objednání klikněte na bod v grafu nebo posuňte táhlem níže a
                objednejte kliknutím na tlačítko.
              </span>
            )}
          >
            <Colorizer color="blue">
              {/* Nápověda: Jak pracovat s grafem / objednat? */}
              Nápověda
            </Colorizer>
          </HintTooltip>
        </Text>
      </RH>
    </HintTooltipPositioner>
  )

  const activeAmountDetailBlock = (
    <Col
      width="100vw"
      mobileWidth="87vw"
      maxWidth="100%"
      mobileMaxWidth="328px"
      alignItems="flex-start"
      padding="0 0 0 2px"
    >
      {/* <RH showAfter={CHART_MOBILE_BP}> */}
      <Row noResponsive width="100%" wrap="no-wrap" alignItems="baseline">
        <TextLabel>Množství:&nbsp;</TextLabel>

        <TextValue>
          <strong>
            {activeDatum?.x}
            {' '}
            m&sup3;
          </strong>
        </TextValue>

        <TextSuffix>&nbsp;sypaných</TextSuffix>
        {activeDatum?.x && (
          <HintTooltip
            title={(
              <span>
                Což je zhruba
                {' '}
                {Math.ceil(activeDatum?.x * 0.625 * 10) / 10}
                {' '}
                m&sup3; rovnaných
                <br />
              </span>
            )}
          />
        )}
      </Row>
      <Gap gap="8px" />

      {/* </RH> */}
      <StyledSlider
        max={inputData.length - 1}
        // max={isMobile ? 23 : inputData.length - 1}
        min={0}
        value={amountIndex}
        onChange={handleSliderChange}
      />
      {/* <RH hideAfter={CHART_MOBILE_BP}>
          <Text>
            Množství: <strong>{activeDatum?.x} m&sup3;</strong> sypaných
          </Text>
        </RH> */}

      <Gap gap="16px" />

      <Row alignItems="baseline" wrap="wrap">
        <TextLabel>Cena za 1 m&sup3;:</TextLabel>
        <TextValue>
          <ColorizedSpan color={valueColor}>
            &nbsp;
            {formatNumberWithThousands(activeDatum?.y)}
            &nbsp;Kč&nbsp;
          </ColorizedSpan>
        </TextValue>
        <TextSuffix>(vč. dopravy)</TextSuffix>
      </Row>
      <Gap gap="16px" />

      <Row alignItems="baseline" wrap="wrap">
        <TextLabel>Celková cena:</TextLabel>
        <TextValue>
          &nbsp;
          {formatNumberWithThousands(activeDatum?.totalPrice)}
          &nbsp;Kč&nbsp;
        </TextValue>
        <TextSuffix>(vč. dopravy)</TextSuffix>
      </Row>

      {!!activeDatum?.offer.numOfPalettes && (
        <>
          <Gap gap="8px" />
          <PalettesInfo result={activeDatum?.offer} />
        </>
      )}
      <Gap gap="24px" />
      <Col width="100%" maxWidth="350px">
        <Button
          realistic={dimensions.expIsNewCalculator}
          onClick={(e) => {
            e.preventDefault()
            handleStartOrder()
          }}
        >
          Pokračovat k objednávce
        </Button>

        {!noSharing && (
          <>
            <Gap gap="16px" />

            <Sharing
              amountsResultShortId={amountsResultShortId}
              amountsResultId={amountsResultId}
              calcValues={calcValues}
            />
          </>
        )}
      </Col>
    </Col>
  )

  if (typeof window === 'undefined') {
    return null
  }

  const handleChartClick = (node, event) => {
    handleAmountChange(node.index)
  }

  const axisLeft = {
    orient: 'bottom',
    tickSize: 5,
    tickPadding: 5,
    format: (value) => `${value} Kč`,
    textStyle: {
      fontWeight: 600,
    },
    tickValues: 4,
  }

  const axisBottom = {
    orient: 'bottom',
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    // legend: 'Množství v m³ sypaných',
    // legend: 'Množství',
    legendOffset: 36,
    legendPosition: 'middle',
    tickValues: 8,

    format: (value) => `${value} m³`,
  }

  return (
    <>
      <ChartWrapper noShadow={noShadow}>
        <Gap gap="8px" />
        {!noTitle && (
          <>
            <Text textAlign="center" fontSize="29px">
              Nejlevněji vychází
              {' '}
              {minPoint?.x}
              {' '}
              m&sup3;!
            </Text>
            <Gap gap="16px" />
            <Text textAlign="center" fontSize="18px" color="#616161">
              Vývoj ceny za 1 m³ pro různá množství
            </Text>
          </>
        )}
        <Gap gap="16px" />
        <ChartWrapperInner>
          <svg style={{ position: 'absolute', visibility: 'hidden' }}>
            <defs>
              <linearGradient id="gradient1" x1="0" x2="0" y1="1" y2="0">
                {/* <stop offset="0%" stopColor="#FCE38A" />
              <stop offset="50%" stopColor="#F38181" />
              <stop offset="100%" stopColor="#red" /> */}
                <stop offset="0%" stopColor="green" />
                {/* <stop offset="50%" stopColor="darkorange" /> */}
                <stop offset="100%" stopColor="red" />
              </linearGradient>
            </defs>
          </svg>
          <ResponsiveLine
            onClick={handleChartClick}
            data={seriesData}
            colors={['url(#gradient1)']}
            curve="monotoneX"
            tooltip={CustomTooltip}
            // enablePoints={false}
            // key={dataKey}
            markers={[
              {
                axis: 'x',
                value: activeDatum.x,
                lineStyle: { stroke: colors.orange, strokeWidth: 4 },
                textStyle: {
                  transform:
                    amountIndex > 17
                      ? 'translate(-8px, 14px)'
                      : 'translate(8px, 14px)',
                  textAnchor: amountIndex > 17 && 'end',
                  fontSize: isMobile ? 14 : undefined,
                  background: 'white',
                },
                legend: !isMobile && `Objednávám ${activeDatum.x}m³`,
              },
            ]}
            margin={{
              top: 0,
              right: isMobile ? 10 : 50,
              right: isMobile ? 15 : 50,
              left: isMobile ? 10 : 80,
              bottom: isMobile ? 4 : 50,
              bottom: isMobile ? 40 : 50,
            }}
            xScale={{ type: 'linear', min: 1 }}
            yScale={{
              type: 'linear',
              min: yHardMin,
              max: yHardMax,
              stacked: true,
              reverse: false,
            }}
            axisLeft={!isMobile && axisLeft}
            axisBottom={!isMobile && axisBottom}
            axisBottom={axisBottom}
            // enableGridY={!isMobile}
            theme={{
              fontSize: 12,
              axis: {
                ticks: {
                  line: {
                    stroke: '#777777',
                    strokeWidth: 1,
                  },
                  text: {
                    fontSize: 13,
                    fontWeight: 600,
                  },
                },
                domain: {
                  line: {
                    stroke: '#777777',
                    strokeWidth: 1,
                  },
                },
              },
              grid: {
                line: {
                  strokeDasharray: [3, 3],
                },
              },
            }}
            lineWidth={isMobile ? 3 : 6}
            pointSize={isMobile ? 0 : 11}
            pointColor="white"
            pointBorderWidth={5}
            pointBorderColor={colors.orange}
            pointLabelYOffset={-12}
            // enableSlices="x"
            useMesh
          />

          <MobileLegend left="2px" top="4px">
            {yHardMax}
            {' '}
            Kč
          </MobileLegend>
          <MobileLegend left="2px" bottom="41px">
            {yHardMin}
            {' '}
            Kč
          </MobileLegend>
          {hintTooltip}
        </ChartWrapperInner>

        {/* <MobileAmountLegend>1 - 30 m&sup3; sypaných</MobileAmountLegend> */}

        {/* <Gap gap="8px" mobileGap="16px" bp={CHART_MOBILE_BP} /> */}

        {/* <RH showAfter={CHART_MOBILE_BP}>{hintBlock}</RH> */}

        <Gap gap="24px" mobileGap="32px" bp={CHART_MOBILE_BP} />
        <Col
          width="100%"
          maxWidth="350px"
          alignItems="flex-start"
          mobileAlignItems="center"
          bp={CHART_MOBILE_BP}
        >
          {/* <RH showAfter={CHART_MOBILE_BP}>
            <Title.SectionSubTitle id="amount-order-detail-box">
              Objednávka:
            </Title.SectionSubTitle>
            <Gap gap="8px" />
          </RH> */}
          {activeAmountDetailBlock}
        </Col>
      </ChartWrapper>

      <Gap gap="24px" />
    </>
  )
}

export default AmountCalcChart
