import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { darken, lighten } from 'polished'
import { useField } from 'formik'
import HintTooltip from './HintTooltip'
import Error from './Error'
import Label from './Label'
import {
  addResponsivity,
  up,
  down,
  between,
  addFont,
  colors,
} from '../../lib/styles'
import { Col, Row, Text, Title, Gap, Link, VH, Button } from '..'

const StyledRadioWrapper = styled.div`
  ${({
    theme: { colors },
    error,
    checked,
    padding = '16px 11px',
    noBg,
    disabled,
  }) => css`
    position: relative;
    background: ${!noBg && colors.inputGrey};
    border-radius: 8px;
    padding: ${padding};
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    border: solid 2px transparent;
    margin-bottom: 8px;

    ${disabled &&
    css`
      opacity: 0.5;
      cursor: auto;
    `}

    &:hover {
      background: ${!noBg && darken(0.02, colors.inputGrey)};
    }
    &:last-child {
      margin-bottom: 0;
    }

    ${error &&
    css`
      border-color: ${colors.red};
    `}

    ${checked &&
    css`
      /* border-color: ${colors.lightOrange}; */
      font-weight: 700;
      background: ${!noBg && darken(0.08, colors.inputGrey)};
      &:hover {
        background: ${!noBg && darken(0.08, colors.inputGrey)};
      }
    `}
  `}
`
const StyledRadio = styled.div`
  ${({ theme: { colors }, error, checked }) => css`
    background: ${colors.inputGrey};
    background: white;
    border-radius: 200px;
    border: solid 2px ${colors.checkRadioGrey};
    width: 24px;
    height: 24px;
    position: relative;
    flex-shrink: 0;

    ${error &&
    css`
      border-color: ${colors.red};
    `}

    ${checked &&
    css`
      /* background: white;
     */
      &:after {
        display: block;
        position: absolute;
        width: 12px;
        height: 12px;
        border-radius: 30px;
        content: '';
        background: ${colors.orange};
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `}
  `}
`

const Tag = styled.div`
  ${({ theme: { colors } }) => css`
    background: ${colors.brown};
    border-radius: 4px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px 8px;
    transform: translate(5px, -5px);
    color: white;
    font-weight: 300;
    font-size: 14px;
    letter-spacing: 1px;
  `}
`

export const RadioOption = ({
  opt,
  value,
  handleChange = () => {},
  noBg,
  padding,
  disabled,
  hint,
  noIcon,
  hintEnablePortal,
}) => {
  const HintWrapper = hint && noIcon ? HintTooltip : React.Fragment

  return (
    <HintWrapper noIcon hint enablePortal={hintEnablePortal}>
      <StyledRadioWrapper
        checked={opt.value === value}
        key={opt.value}
        onClick={() => handleChange(opt.value)}
        noBg={noBg}
        padding={padding}
        disabled={disabled}
      >
        <StyledRadio checked={opt.value === value} />
        <Gap />
        {opt.label}
        {opt.tag && <Tag>{opt.tag}</Tag>}
        {hint && !noIcon && (
          <HintTooltip hint={hint} enablePortal={hintEnablePortal} />
        )}
      </StyledRadioWrapper>
    </HintWrapper>
  )
}

const RadioGroup = ({
  label,
  labelHint,
  hint,
  interactiveHint,
  options,
  name,
  width,
  maxWidth,
}) => {
  const [field, meta, helpers] = useField(name)
  const { value } = meta
  const { setTouched, setValue } = helpers

  // console.log('helpers', helpers, field, meta,)

  const handleChange = (value) => {
    setTouched()
    setValue(value)
  }

  return (
    <Col width={width} maxWidth={maxWidth}>
      {label && (
        <>
          <Row>
            <Label>
              {label}
              {' '}
              <span>{labelHint}</span>
            </Label>
            <HintTooltip hint={hint} interactive={interactiveHint} />
          </Row>
          <Gap />
        </>
      )}
      {options.map((opt) => (
        <RadioOption
          opt={opt}
          key={opt.value}
          value={value}
          handleChange={handleChange}
        />
      ))}
      {/* {touched[name] && errors[name] ? <div>{errors[name]}</div> : null} */}
      {/* <ErrorMessage name={name} component={StyledError} /> */}
      {meta.touched && meta.error && <Error name={name} />}

    </Col>
  )
}

export default RadioGroup
