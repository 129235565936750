import React, { useEffect, useMemo, useRef, useState } from 'react'
import Loading from '@kiwicom/orbit-components/lib/Loading'
import { getUrlParam } from '../../lib/helpers'
import { useExperiments } from '../hooks/useExperiments'
import {
  useCalcMutation,
  useCalcResult,
  useNewOrderMutation,
} from '../hooks/useQueries'
import {
  useCalculationExperiments,
  useSendOrderExperiment,
} from './experiments'
import { Link } from '..'
import { useGlobalContext } from '../GlobalContext'
import { useCustomerId } from '../hooks/useUserId'
import { usePartner } from '../hooks/useParner'
import { useUpdateGlobalAddress } from '../fields/SLocationInput'
import { vatMultiplier } from '../../config'

export const useShareCalcResults = () => {
  const shareResultId = getUrlParam('share')
  const { data } = useCalcResult(shareResultId, { enabled: !!shareResultId })

  const { sendEvent } = useExperiments()
  useEffect(() => {
    if (shareResultId) {
      sendEvent('open_shared_calc_page', {
        eventOpenShareResultId: shareResultId,
      })
    }
  }, [])

  return {
    values: data,
    shareResultId,
  }
}

export function withShareCalcResults(Component) {
  return (props) => {
    const { shareResultId, values } = useShareCalcResults()

    const { updateGlobalAddress } = useUpdateGlobalAddress()

    const addressUpdatedRef = useRef(false)
    useEffect(() => {
      if (values && !addressUpdatedRef.current) {
        addressUpdatedRef.current = true
        updateGlobalAddress(values.address)
      }
    }, [values])

    if (!shareResultId) {
      return <Component {...props} />
    }

    if (shareResultId && !values) {
      return <Loading loading text="Načítám sdílený výsledek..." />
    }

    // console.log('!valuesvaluesvaluesvalues', values)

    return (
      <Component overwriteValues={values} instantCalc={!!values} {...props} />
    )
  }
}

export const useServerCalculateMutation = ({ pickedIndex } = {}) => {
  const mutation = useCalcMutation()

  // console.log('mutation', mutation)

  const [cachedData, setCachedData] = useState(null)
  const [cachedInputs, setCachedInput] = useState(null)

  useEffect(() => {
    if (mutation.data && (!cachedData || mutation.data.ts >= cachedData?.ts)) {
      setCachedData(mutation.data)
      console.log('>>> UPDATING DATA')
    } else {
      console.log('>>> skipping update')
    }
  }, [mutation.data])

  // process results data
  const amountOffers = useMemo(
    () => cachedData?.amountOffers
      ?.map((o, index) => ({
        amount: o.offer.finalAmount,
        price: Math.round(o.offer.finalPricePerOnePrms),
        totalPrice: Math.round(o.offer.finalTotalPrice),
        finalTotalPrice: Math.round(o.offer.finalTotalPrice),
        numOfPalettes: o.offer.numOfPalettes,
        offer: o.offer,
        amountIndex: index,
        amountsResultId: cachedData.amountsResultId,
        includesVat: o.offer.includesVat,
        totalPriceWithoutVat: o.offer.includesVat
          ? Math.round(o.offer.finalTotalPrice / vatMultiplier)
          : o.offer.finalTotalPrice,
        priceWithoutVat: o.offer.includesVat
          ? Math.round(o.offer.finalPricePerOnePrms / vatMultiplier)
          : o.offer.finalPricePerOnePrms,
      }))
      .map((o) => ({
        ...o,
        priceVat: o.price - o.priceWithoutVat,
        totalPriceVat: o.totalPrice - o.totalPriceWithoutVat,
      })),
    [cachedData],
  )

  const restrictedMinimum = useMemo(
    () => amountOffers
      ?.slice(5, 17)
      .reduce(
        (acc, curr) => curr.price <= acc.price
          ? { amountIndex: curr.amountIndex, price: curr.price }
          : acc,
        { amountIndex: -1, price: Infinity },
      ),
    [amountOffers],
  )

  const pickedOffer = amountOffers?.[pickedIndex]

  const latestTsRef = useRef()

  const result = {
    mutation,
    calculateAsync: async (values, ...rest) => {
      const body = {
        ...values,
        ts: Date.now(),
      }
      setCachedInput(body)

      latestTsRef.current = body.ts

      const data = await mutation.mutateAsync(body, ...rest)

      console.log('getLatestSentTs()', latestTsRef.current, data.ts)
      const isLatest = latestTsRef.current === data.ts

      return { data, isLatest }
    },
    isCalculating: mutation.isLoading,
    amountOffers,
    cachedInputs,
    // minimum,
    pickedOffer,
    restrictedMinimum,
    calcResultData: mutation.data,
    // ^^ data returns
    // amountOffers
    // amountsResultId
    // amountsResultShortId
  }

  // console.log('result - useServerCalculateMutation', result)
  return result
}

export const useSendGlobalOrder = () => {
  const { sendNewOrderEvents } = useSendOrderExperiment()
  const { dimensions } = useExperiments()

  const mutation = useNewOrderMutation()
  const { orderCalcInputs, orderCalcResult } = useGlobalContext()
  const { partner } = usePartner()

  // console.log('GLOBAL - send ', orderCalcInputs, orderCalcResult)

  const { getOrGenerateCustomerId } = useCustomerId()

  const sendOrderAsync = async (orderFormValues) => {
    const orderValues = {
      ...orderFormValues,
      calcResult: orderCalcResult.amountsResultId,
      customerId: getOrGenerateCustomerId(),

      pickAmountIndex: orderCalcInputs.amountIndex,
      isAmountCalc: true,
      path: window.location.pathname,
      href: window.location.href,
      partnerCode: partner,
      expCalculator: dimensions.expCalculator,
      expCart: dimensions.expCart,
    }

    sendNewOrderEvents({
      orderValues,
      orderCalcInputs,
      orderCalcResult,
    })

    return mutation.mutateAsync(orderValues)
  }

  return {
    sendOrderAsync,
  }
}
