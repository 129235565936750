import useLocalStorageState from 'use-local-storage-state'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { isBefore, subDays, subMinutes, subSeconds } from 'date-fns'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import styled, { css } from 'styled-components'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useLocation, createHistory } from '@reach/router'
import Popup from './Popup'
import { Input } from './fields'
import Title from './Title'
import Gap from './Gap'
import Button from './Button'
import { PersistFormikValues } from '../lib/PersistFormikValues'
import Col from './Col'
import Text from './Text'
import HintTooltip, { InfoIcon } from './fields/HintTooltip'
import CloseIcon from './CloseIcon'
import { useFeedbackPopupMutation } from './hooks/useQueries'
import { feedbackSchema } from '../validations/forms'
import usePopupFormAndMutation from './hooks/usePopupFormAndMutation'
import { Link, RH, Row } from '.'
import CalculatorContent from './ExitIntentPopup.Calculator'
import { useExperiments } from './hooks/useExperiments'
import FeedbackContent from './ExitIntentPopup.Feedback'
import useBlockBackButton from './hooks/useBlockBackButton'
import useMobileScrollUp from './hooks/useMobileScrollUp'
import { useBasicTimeout } from './hooks/useTimeout'
import useIdleTimeout from './hooks/useIdleTimeout'
import useLostFocus from './hooks/useLostFocus'
import { usePopups } from './hooks/usePopups'
import { useGlobalContext } from './GlobalContext'

const PopupContent = styled.div`
  width: min(800px, 85vw);
  max-width: min(800px, 85vw);
  max-width: 100%;
`

const PopupContentCalculator = styled.div`
  width: 100%;
  max-width: min(1200px, 95vw);
`

const absoluteContentCss = css`
  right: 16px;
  bottom: -4px;
`

const variants = {
  default: {
    title: 'Nenašli jste, co jste hledali?',
    Content: FeedbackContent,
    triggers: ['mouseLeave', 'timeout300', 'backButton', 'idle60'],
    paths: ['/thanks-partner', '/thanks', '/objednavka', '/calculator'],
  },
  myPriceDefault: {
    // paths: ['/cenova-mapa', '/cena-palivove-drevo-'],
    title: 'Cena vč. dopravy pro mou lokalitu?',
    bgColor: 'bgGreyDarker',
    Content: CalculatorContent,
    PopupContentComponent: PopupContentCalculator,
    triggers: [
      'mouseLeave',
      'mobileScrollUp',
      'timeout300',
      'backButton',
      'idle60',
      'lostFocus',
    ],
  },
}

const disabledPaths = [
  '/kosik',
  '/thanks-partner',
  '/thanks',
  '/objednavka',
  '/calculator',
  '/hodnoceni',
]

// TODO: v budoucnu lze pridat treba check, ze dal neco do kosiku a pak ukazat jiny popup kdyz bude chtit odejit
// TODO: nebo ze je na sekci s kalkulaci -- pak asi ukazat proc odchazis atd.

const defaultVariantKey = 'myPriceDefault'

const findVariant = (pathname) => {
  if (disabledPaths.some((path) => pathname.startsWith(path))) {
    console.log('popup - disabled path')
    return null
  }

  let variant = Object.entries(variants).find(([key, { paths }]) => paths?.some((path) => pathname.startsWith(path)))

  if (!variant) {
    variant = [defaultVariantKey, variants[defaultVariantKey]]
  }

  return variant ? { key: variant[0], ...variant[1] } : null
}

function useMouseTracking(callback) {
  useEffect(() => {
    function trackMouse(event) {
      if (
        event.clientY <= 0 ||
        event.clientX <= 0 ||
        event.clientX >= window.innerWidth ||
        event.clientY >= window.innerHeight
      ) {
        console.log("I'm out")
        callback()
      }
    }

    document.addEventListener('mouseleave', trackMouse, false)

    return () => {
      document.removeEventListener('mouseleave', trackMouse)
    }
  }, [callback])
}

const emptyFn = () => {}
const getCallback = (variant, key, callback) => variant?.triggers?.includes(key) ? callback : emptyFn

function ExitIntentPopup({
  isOpen,
  openPopup: openPopupProp,
  closePopup,
  storageKey = 'exit-intent-popup',
  appendValues,
}) {
  const { sendEvent } = useExperiments()
  // const { popupsState } = usePopups()
  // console.log('popupsState', popupsState)

  const location = useLocation()
  let variant = findVariant(location.pathname)

  // dont use when user is already ordering, or was recently calculated (15 sec)
  const { isOrderPopupOpen, wasRecentlyCalculated } = useGlobalContext()
  if (!isOpen && (isOrderPopupOpen || wasRecentlyCalculated)) {
    // console.log(
    //   'isOrderPopupOpen || wasRecentlyCalculated',
    //   isOrderPopupOpen,
    //   wasRecentlyCalculated,
    // )
    variant = null
  }

  // const [variant, setVariant] = useState(foundVariant)
  const [lastShownAt, setLastShownAt] = useLocalStorageState(
    `${storageKey}-variant-${variant?.key}`,
    null,
  )

  // console.log('location, variant', location, variant)

  const openPopup = useCallback(() => {
    if (isOpen) {
      console.log('already open, skipping')
      return
    }
    console.log('opening popup')
    openPopupProp()
    sendEvent('exit_intent_popup', {
      popupVariant: variant?.key,
    })
  }, [openPopupProp, isOpen])

  const memoizedSetShowPopup = useCallback(
    ({ fromBackButton } = {}) => {
      if (!variant) {
        return
      }
      const shouldShow =
        !lastShownAt ||
        // TODO:
        // isBefore(new Date(lastShownAt), subSeconds(new Date(), 10))
        isBefore(new Date(lastShownAt), subDays(new Date(), 2))

      if (shouldShow) {
        openPopup()
        setLastShownAt(new Date())
      } else {
        // console.log('already shown, skipping', variant.key)
        if (fromBackButton) {
          console.log('is fromBackButton, so navigate away')
          window.history.back()
        }
      }
    },
    [openPopup, setLastShownAt, lastShownAt],
  )

  // this triggers watchers
  useMouseTracking(getCallback(variant, 'mouseLeave', memoizedSetShowPopup))
  useBlockBackButton({
    callback: getCallback(variant, 'backButton', memoizedSetShowPopup),
  })
  useMobileScrollUp({
    callback: getCallback(variant, 'mobileScrollUp', memoizedSetShowPopup),
    triggerDestination: 100,
  })
  useBasicTimeout(
    getCallback(variant, 'timeout300', memoizedSetShowPopup),
    300_000,
  )
  useIdleTimeout(getCallback(variant, 'idle60', memoizedSetShowPopup), 60_000)
  useLostFocus(getCallback(variant, 'lostFocus', memoizedSetShowPopup))

  const onCloseRef = useRef()
  const closeThisPopup = () => {
    closePopup()
    onCloseRef.current?.()
  }

  const PopupContentComponent = variant?.PopupContentComponent || PopupContent

  return isOpen && variant ? (
    <Popup
      // desktop
      bgColor={variant.bgColor}
      onPopupOverlayClick={closeThisPopup}
      absoluteContentCss={absoluteContentCss}
      absoluteContent={(
        <div>
          <RH showAfter={680}>
            <StaticImage
              src="../data/images/popup-bg.png"
              width={640}
              layout="constrained"
            />
            {' '}
          </RH>
        </div>
      )}
    >
      <PopupContentComponent>
        <CloseIcon onClick={closeThisPopup} />
        <Title.SectionTitle>{variant.title}</Title.SectionTitle>
        <Gap gap="8px" />
        <variant.Content onCloseRef={onCloseRef} closePopup={closePopup} />
      </PopupContentComponent>
    </Popup>
  ) : null
}

export default ExitIntentPopup
