import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import moment from 'moment'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Text from './Text'
import Row from './Row'
import Col from './Col'
import Gap from './Gap'
import Link from './Link'
import { up, addPagePadding } from '../lib/styles'

const Wrapper = styled.div`
  ${({ theme: { sizes, colors } }) => css`
    background-color: ${colors.bgGreyDarker};
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 8px;
    position: fixed;
    bottom: 0;
    box-shadow: 0px 0px 25px rgb(0 0 0 / 25%);
  `}
`

const StickyFooter = (props) => (
  <Wrapper>
    <Col alignSelf="center">
      <Text fontSize="14px" fontWeight="300">
        Chybí vám tu něco?
        {' '}
        <Link asSpan popup="QUESTION">
          <Text fontSize="14px" fontWeight="500">
            Dejte nám vědět!
          </Text>
        </Link>
      </Text>
    </Col>
  </Wrapper>
)

StickyFooter.defaultProps = {}

StickyFooter.propTypes = {}

export default StickyFooter
