/* eslint-disable import/prefer-default-export */
import { nanoid } from 'nanoid'
import { useEffect, useRef, useState } from 'react'
import Bugsnag from '@bugsnag/js'

export const useCustomerId = () => {
  const rendered = useRef(false)

  const getCustomerIdFromLocalStorage = () => {
    try {
      return window?.localStorage?.getItem('customerId')
    } catch (e) {
      return null
    }
  }

  let customerId = null
  if (!rendered.current) {
    customerId = getCustomerIdFromLocalStorage()
    // console.log('first render', customerId)
  }
  const [id, setId] = useState(customerId)

  const getCustomerId = () => {
    if (id) {
      return id
    }
    return getCustomerIdFromLocalStorage()
  }

  const createAndStoreId = () => {
    const customerId = nanoid()
    window.localStorage.setItem('customerId', customerId)
    return customerId
  }

  const getOrGenerateCustomerId = () => {
    let id = getCustomerId()
    if (!id) {
      try {
        id = createAndStoreId()
      } catch (error) {
        console.log('localstorage error while saving customer id', error)
      }
    }

    return id
  }

  useEffect(() => {
    rendered.current = true

    // setup customerId
    try {
      const storage = window.localStorage
      let customerId = storage.getItem('customerId')

      if (!customerId) {
        customerId = createAndStoreId()
      }

      // console.log('!!customerId is', customerId)
      setId(customerId)
    } catch (error) {
      setId(`localStorageError-${nanoid()}`)
      Bugsnag.notify(error, (event) => {
        event.severity = 'warn'
        event.addMetadata('custom', { msg: 'local storage error?' })
      })
    }
  }, [])

  // console.log('!customerId is', id)

  return { customerId: id, getOrGenerateCustomerId }
}
