import React from 'react'
import styled, { css } from 'styled-components'
import { up, addResponsivity } from '../lib/styles'

const TitleNote = styled.span``

// default values belong to Page title
const Title = styled.span`
  ${({
    theme: { colors, fonts },
    fontSize = '44px',
    fontWeight = 400,
    lineHeight,
    mobileFontSize,
    mobileLineHeight,
    maxWidth,
    textAlign,
    alignSelf,
    mobileTextAlign,
    display = 'block',
    bp = 'mobile', // can be px or bp name
    margin,
    color,
    textFont,
  }) => css`
    font-weight: ${fontWeight};
    font-family: ${textFont ? fonts.signika : fonts.bree};
    max-width: ${maxWidth};
    align-self: ${alignSelf};
    display: ${display};
    color: ${colors[color] || color};

    font-size: ${mobileFontSize || fontSize};
    line-height: ${mobileLineHeight || lineHeight};
    text-align: ${mobileTextAlign || textAlign};

    /* reset */
    margin: 0;
    margin: ${margin};
    padding: 0;

    ${up(bp)} {
      font-size: ${fontSize};
      line-height: ${lineHeight};
      text-align: ${textAlign};
    }

    ${addResponsivity({ displayAs: display })}

    ${TitleNote} {
      font-size: 0.7em;
    }
  `}
`

Title.TitleNote = TitleNote

// define basic styles used on page
// PROTIP: preddefinovane mobile / desktop velikosti, lze ovlivnit nastavenim vlastniho BP

// all pages h1 title
Title.PageTitle = (props) => (
  <Title
    fontSize="46px"
    mobileFontSize="40px"
    mobileLineHeight="48px"
    color="white"
    as={props.innerAs || 'h1'}
    {...props}
  />
)

Title.PageSubTitle = (props) => (
  <Title
    fontSize="26px"
    color="lightOrange"
    fontWeight="300"
    as={props.innerAs || 'h2'}
    {...props}
  />
)

// usually h2 title, bigger version
// eg. hp sections, blog title...
// !! auto-responsive!
Title.SectionTitle = (props) => (
  <Title
    fontSize="44px"
    lineHeight="66px"
    mobileFontSize="32px"
    mobileLineHeight="40px"
    color="brown"
    textAlign="center"
    as={props.innerAs || 'h2'}
    {...props}
  />
)
Title.SectionSubTitle = (props) => (
  <Title
    fontSize="26px"
    lineHeight="40px"
    mobileFontSize="24px"
    mobileLineHeight="32px"
    color="darkBrown"
    textFont
    textAlign="center"
    // mobileTextAlign="center"
    as={props.innerAs || 'h2'}
    {...props}
  />
)

Title.PostH2 = (props) => (
  <Title
    fontSize="26px"
    lineHeight="40px"
    margin="24px 0 16px"
    mobileFontSize="24px"
    mobileLineHeight="32px"
    color="darkBrown"
    textAlign="left"
    mobileTextAlign="left"
    as="h2"
    {...props}
  />
)

Title.SmallTitle = (props) => (
  <Title
    fontSize="28px"
    lineHeight="36px"
    as={props.innerAs || 'h3'}
    {...props}
  />
)

export default Title
