import React from 'react'
import { desaturate } from 'polished'
import Row from '../Row'
import Col from '../Col'
import Gap from '../Gap'
import HintTooltip, { InfoIcon } from '../fields/HintTooltip'
import Text from '../Text'
import { formatCurrency, formatNumberWithThousands } from '../../lib/helpers'

export const addressHint = (
  <>
    K čemu je potřeba vaše adresa?
    <br />
    <br />
    Nedílnou součástí ceny je
    {' '}
    <strong>doprava</strong>
    . Abychom mohli jednotlivé
    dodavatele
    {' '}
    <strong>porovnat</strong>
    , je klíčové vědět, kam pojedou. Jedině tak zjistíme cenu dopravy a
    porovnáme nabídky.
    <br />
    <br />
    Vyhledávání funguje i s nepřesnou adresou na úrovni města, mějte ale na
    paměti, že výsledná cena závozu se může lišit.
    <br />
    <br />
    Do konečné objednávky vždy uveďte přesnou adresu.
  </>
)

export const addressHintLabel = 'Proč je to potřeba?'

export const PalettesInfo = ({ result }) => (
  <Col alignSelf="flex-start">
    <HintTooltip
      noIcon
      title={(
        <span>
          Někteří dodavatelé dodávají dřevo na paletách a na ty se vejde vždy
          nějaké přesné množství. Kalkulačka najde nejlepší cenu pro vámi zadané
          množství a pokud vyjde dodavatel s paletami, tak upraví množství a
          cenu dle kapacity palety. Máte tak jistotu, že v přepočtu ceny /
          množství dostanete nejlepší možnou nabídku. Do budoucna plánujeme do
          kalkulačky přidat možnost omezit vyhledávání jen na přesné množství.
          <br />
          Pro porovnání: cena za hledaných
          {' '}
          {result.amount}
          {' '}
          m&sup3; by byla
          {' '}
          {formatNumberWithThousands(
            result.finalPricePerOnePrms * result.amount,
            0,
          )}
          {' '}
          Kč.
        </span>
      )}
    >
      <div>
        <Row>
          <Col>
            <Gap gap="2px" />
            <InfoIcon darker />
          </Col>
          <Gap gap="8px" />

          <Col>
            <Text fontWeight="400" fontSize="16px">
              Dodáno na paletách.
            </Text>
            {/* <Text fontWeight="400" fontSize="16px">
          <BlueLinkButton>
          Najít pouze přesné množství: {calcValues.amount}{' '}
          PRMS
          </BlueLinkButton>
        </Text> */}
          </Col>
        </Row>
      </div>
    </HintTooltip>
  </Col>
)

export const formatTotalPriceVatMessage = (result) => result?.includesVat
  ? `Včetně dopravy a DPH (15 %). Cena bez DPH je ${formatCurrency(
    result.totalPriceWithoutVat,
    0,
  )}. Lze odečíst DPH ${formatCurrency(
    result.totalPriceVat,
    0,
  )}. Konečnou výši DPH vám sdělí dodavatel - DPH na dřevo je 15 %, na dopravu 21 %. Je tedy možné, že odpočet bude ve finále o něco málo vyšší.`
  : 'Konečná cena vč. dopravy. Dodavatel není plátce dph, dph se neúčtuje.'

export const formatTotalPriceSubtractedVatMessage = (result) => result?.includesVat
  ? 'Konečnou výši DPH vám sdělí dodavatel - DPH na dřevo je 15 %, na dopravu 21 %. Je tedy možné, že odpočet bude ve finále o něco málo vyšší.'
  : 'Konečná cena vč. dopravy. Dodavatel není plátce dph, dph se neúčtuje.'

export const formatPriceVatMessage = (result) => result?.includesVat
  ? `Včetně dopravy a DPH (15 %). Cena bez DPH je ${formatCurrency(
    result.priceWithoutVat,
    0,
  )}. Lze odečíst DPH ${formatCurrency(
    result.priceVat,
    0,
  )}. Konečnou výši DPH vám sdělí dodavatel - DPH na dřevo je 15 %, na dopravu 21 %. Je tedy možné, že odpočet bude ve finále o něco málo vyšší.`
  : 'Konečná cena vč. dopravy. Dodavatel není plátce dph, dph se neúčtuje.'

export const getMinMaxPrice = (amountOffers) => {
  const sorted = [...amountOffers].sort((a, b) => a.price - b.price)

  const min = sorted[0].price
  const max = sorted[sorted.length - 1].price
  const diff = max - min

  // na zacatku grafu to jde prudce dolu, usekneme prvnich xx %
  const trimmedDiff = diff * 0.65

  return {
    min,
    max,
    trimmedDiff,
    diff,
  }
}

export function getColor(ratio, color1 = [0, 255, 0], color2 = [255, 0, 0]) {
  const r = Math.round(color1[0] * (1 - ratio) + color2[0] * ratio)
  const g = Math.round(color1[1] * (1 - ratio) + color2[1] * ratio)
  const b = Math.round(color1[2] * (1 - ratio) + color2[2] * ratio)
  return `rgb(${r},${g},${b})`
}

export const colorizePrice = ({ amountOffers, amountIndex }) => {
  if (!amountOffers || !amountOffers.length || amountIndex === -1) {
    return 'rgb(0, 0, 0)'
  }

  const { min, trimmedDiff, diff } = getMinMaxPrice(amountOffers)

  const { price } = amountOffers[amountIndex] || {}

  if (!price) {
    return 'rgb(0, 0, 0)'
  }

  const diffFromMin = price - min

  const percentage = Math.min(diffFromMin / trimmedDiff, 1)

  // console.log('percentage', percentage)

  // const color = `hsl(${percentage * 120}, 100%, 50%)`

  // console.log(
  //   'getColor(percentage)',
  //   percentage,
  //   diffFromMin,
  //   trimmedDiff,
  //   getColor(percentage),
  //   min,
  //   trimmedDiff,
  //   diff,
  //   amountIndex,
  // )
  return desaturate(0.5, getColor(percentage))
}
