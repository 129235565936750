/* eslint-disable import/prefer-default-export */

// 'muni', - praha
// 'addr',  - poznanska
// 'ward', - bohnice
// 'quar' - praha 8
export const allowedAddressSources = ['muni', 'addr', 'ward', 'quar', 'stre']

export const nonAccurateSources = ['muni', 'ward', 'quar']

export const accurateSources = ['addr', 'stre']
