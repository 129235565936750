import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Text from './Text'
import useStickyHeader from './hooks/useStickyHeader'

const OverflowWrapper = styled.div`
  /* scroll bar width, for use in mask calculations */
  --scrollbar-width: 0px;

  /* mask fade distance, for use in mask calculations */
  /* --mask-height: 32px; */
  --mask-height: 24px;

  /* If content exceeds height of container, overflow! */
  /* overflow-y: auto; */

  /* Our height limit */
  /* height: 300px; */

  /* Need to make sure container has bottom space,
  otherwise content at the bottom is always faded out */
  padding: var(--mask-height);

  /* Keep some space between content and scrollbar */
  /* padding-right: 20px; */

  /* The CSS mask */

  /* The content mask is a linear gradient from top to bottom */
  --mask-image-content: linear-gradient(
    to right,
    transparent,
    black var(--mask-height),
    black calc(100% - var(--mask-height)),
    transparent
  );

  /* Here we scale the content gradient to the width of the container 
  minus the scrollbar width. The height is the full container height */
  --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;

  /* The scrollbar mask is a black pixel */
  --mask-image-scrollbar: linear-gradient(black, black);

  /* The width of our black pixel is the width of the scrollbar.
  The height is the full container height */
  --mask-size-scrollbar: var(--scrollbar-width) 100%;

  /* Apply the mask image and mask size variables */
  mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
  mask-size: var(--mask-size-content), var(--mask-size-scrollbar);

  /* Position the content gradient in the top left, and the 
  scroll gradient in the top right */
  mask-position: 0 0, 100% 0;

  /* We don't repeat our mask images */
  mask-repeat: no-repeat, no-repeat;
  overflow: auto;
  width: 100%;
`

const Wrapper = styled.div`
  ${({ width, maxWidth, minWidth }) => css`
    box-shadow: 0 10px 60px -10px rgba(4, 62, 134, 0.2);
    box-shadow: 0 2px 23px 0px rgb(4 62 134 / 20%);
    border-radius: 6px;
    width: fit-content;
    overflow: hidden;

    width: ${width};
    max-width: ${maxWidth};
    min-width: ${minWidth};

    margin: auto;
    /* transform: none;
    max-height: 100%; 
    overflow: auto;
    position: relative;  */
  `}
`
const TableComponent = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-radius: 6px;
  background-color: #ffffff;
  position: relative;
  /* min-width: 630px; */

  border-collapse: separate;
  border-spacing: 0;

  th {
    position: sticky;
    top: 0;
    /* background-color: #ffffff; */
    z-index: 1;
  }
`

const commonStyle = css`
  border-collapse: collapse;
`

const Table = ({
  withOverflowGradient,
  minWidth,
  width,
  maxWidth,
  ...props
}) => {
  const { tableRef, isSticky } = useStickyHeader()

  // console.log('isSticky22', isSticky)

  const table = (
    <Wrapper width={width} maxWidth={maxWidth} minWidth={minWidth}>
      <TableComponent ref={tableRef} {...props} />
    </Wrapper>
  )

  // na sticky header neni jednoduche reseni...
  if (withOverflowGradient) {
    return <OverflowWrapper>{table}</OverflowWrapper>
  }

  return table
}

Table.HeaderRow = styled.tr`
  ${commonStyle}
  border-radius: 6px 6px 0 0;

  ${({ theme: { colors } }) => css`
    background: ${colors.lightOrange};
  `}

  cursor: pointer;
  /* min-height: 72px;
  height: 72px; */
`
Table.Header = styled.th`
  ${commonStyle}
  padding: 24px;
  position: sticky;
  top: 0;
`
Table.Row = styled.tr`
  ${commonStyle}/* min-height: 72px;
  
  height: 72px; */
`

Table.Col = styled.td`
  ${commonStyle}
  padding: 12px 24px;
`

export default Table

// helpers for texts...
export const HeaderText = (props) => (
  <Text
    textAlign="left"
    fontSize="16px"
    lineHeight="24px"
    fontWeight="700"
    {...props}
  />
)

export const ColText = (props) => (
  <Text fontSize="16px" lineHeight="16px" fontWeight="400" {...props} />
)

Table.TextCol = ({ children, ...props }) => (
  <Table.Col>
    <ColText {...props}>{children}</ColText>
  </Table.Col>
)
