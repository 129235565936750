import React from 'react'

function CrosshairIcon({ color, width, height, ...props }) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H24V24H0z" />
        <path
          d="M12 15c.552 0 1 .448 1 1v4c0 .552-.448 1-1 1s-1-.448-1-1v-4c0-.552.448-1 1-1zm-4.584-1c.504 1.154 1.431 2.08 2.584 2.584v2.126c-2.257-.672-4.038-2.452-4.71-4.709h2.126zm9.168 0l2.126.001c-.672 2.257-2.452 4.037-4.709 4.709v-2.126c1.153-.504 2.079-1.43 2.583-2.583zM20 11c.552 0 1 .448 1 1s-.448 1-1 1h-4c-.552 0-1-.448-1-1s.448-1 1-1h4zM8 11c.552 0 1 .448 1 1s-.448 1-1 1H4c-.552 0-1-.448-1-1s.448-1 1-1h4zm2-5.71v2.126C8.847 7.92 7.92 8.846 7.416 10H5.29C5.962 7.742 7.742 5.962 10 5.29zm4.001 0c2.257.672 4.037 2.453 4.71 4.71h-2.127c-.504-1.153-1.43-2.08-2.583-2.584V5.29zM12 3c.552 0 1 .448 1 1v4c0 .552-.448 1-1 1s-1-.448-1-1V4c0-.552.448-1 1-1z"
          fill={color}
        />
      </g>
    </svg>
  )
}

export default CrosshairIcon
