import React from 'react'

const BurgerIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="4"
      width="3"
      height="25"
      rx="1.5"
      transform="rotate(-90 0 4)"
      fill="#FFB951"
    />
    <rect
      y="14"
      width="3"
      height="25"
      rx="1.5"
      transform="rotate(-90 0 14)"
      fill="#FFB951"
    />
    <rect
      y="24"
      width="3"
      height="25"
      rx="1.5"
      transform="rotate(-90 0 24)"
      fill="#FFB951"
    />
  </svg>
)

const CloseIcon = () => (
  <svg
    width="27"
    height="25"
    viewBox="0 0 27 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="2.12988"
      width="3"
      height="32.3315"
      rx="1.5"
      transform="rotate(-45.231 1 2.12988)"
      fill="#FFB951"
    />
    <rect
      x="3.06445"
      y="24.8904"
      width="3"
      height="32.2003"
      rx="1.5"
      transform="rotate(-135 3.06445 24.8904)"
      fill="#FFB951"
    />
  </svg>
)

export default function Burger({ closed }) {
  return closed ? <BurgerIcon /> : <CloseIcon />
}
