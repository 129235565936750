import { useExperiments } from '../hooks/useExperiments'

export const useCalculationExperiments = () => {
  const { sendEvent, sendPageEvent, sendSessionEvent, updateUserProperties } =
    useExperiments()

  // when user calculates
  const sendCalculationEvents = (values) => {
    updateUserProperties({
      userPropCalculated: 1,
      userPropFunnel: 'calculator',
    })

    sendEvent('calculate', {
      calculate_count: 1,
    })

    sendPageEvent('page_first_calculate')

    sendSessionEvent('session_first_calculate')
  }

  return { sendCalculationEvents }
}

export const useOpenCartExperiments = () => {
  const { sendEvent, updateUserProperties, setUserProperties } =
    useExperiments()

  const sendOpenCart = (offer) => {
    console.log('setting user funnel to cart')
    setUserProperties({
      userPropCart: 1,
      userPropFunnel: 'cart',
    })

    // setTimeout(() => {
    //   updateUserProperties({
    //     userPropCart: 1,
    //     userPropFunnel: 'cart',
    //   })

    //   setTimeout(() => {
    //     updateUserProperties({
    //       userPropCart: 1,
    //       userPropFunnel: 'cart',
    //     })
    //   }, 5000)
    // }, 5000)

    setTimeout(() => {
      console.log('sending open cart event')
      sendEvent('open_cart', {
        // TODO: add all props
        // eventCartOffer: datum.offer,
        // eventCartTotalPrice: datum.totalPrice,
        // eventCartFinalAmount: datum.offer?.finalAmount,
        // eventCartIsDry: datum.offer?.p?.isDry,
        // eventCartWoodCategory: datum.offer?.p?.woodCategory,
      })
    }, 1)
  }

  return {
    sendOpenCart,
  }
}

export const useCartStepsExperiments = () => {
  const { sendEvent, updateUserProperties, latestUserProperties } =
    useExperiments()

  const sendCartStep = (stepNumber) => {
    if (stepNumber === 1) {
      console.log('skipping cart_step_1')
      return
    }

    // only update funnel when stepNumber is higher than the latest one
    if (
      !latestUserProperties.userPropCartStepNumber ||
      stepNumber > latestUserProperties.userPropCartStepNumber
    ) {
      updateUserProperties({
        [`userPropCartStep${stepNumber}`]: 1, // not yet used in ga4 defs
        userPropCartStepNumber: stepNumber, // not yet used in ga4 defs
        userPropFunnel: `cart_step_${stepNumber}`,
      })
    }

    // sendEvent('cart_step', {
    //   eventCartStepNumber: stepNumber,
    //   [`eventCartStep${stepNumber}Counter`]: 1,
    // })
    sendEvent(`cart_step_${stepNumber}`, {
      eventCartStepCounter: 1, // not yet used in ga4 defs
    })
  }

  return {
    sendCartStep,
  }
}

export const useSendOrderExperiment = () => {
  const { sendEvent, updateUserProperties } = useExperiments()

  const sendNewOrderEvents = ({
    orderValues,
    orderCalcInputs,
    orderCalcResult,
  }) => {
    updateUserProperties({
      userPropPurchaser: 1,
      userPropFunnel: 'purchase',
    })
    console.log('purchase values', {
      transaction_id: orderValues.calcResult,
      value: orderCalcResult.finalTotalPrice,
      currency: 'CZK',
      purchase_count: 1,
      id: orderValues.calcResult,
      name: orderCalcResult?.offer?.p?.name,
      list_name: 'Search Results',
      category: orderCalcResult?.offer?.p?.woodCategory,
      quantity: orderCalcResult?.offer?.finalAmount,
      price: orderCalcResult?.finalTotalPrice,
      items: [
        {
          id: orderValues.calcResult,
          name: orderCalcResult?.offer?.p?.name,
          list_name: 'Search Results',
          category: orderCalcResult?.offer?.p?.woodCategory,
          quantity: orderCalcResult?.offer?.finalAmount,
          price: orderCalcResult?.finalTotalPrice,
        },
      ],
    })

    sendEvent('purchase', {
      transaction_id: orderValues.calcResult,
      value: orderCalcResult.finalTotalPrice,
      currency: 'CZK',
      purchase_count: 1,
      id: orderValues.calcResult,
      name: orderCalcResult?.offer?.p?.name,
      list_name: 'Search Results',
      category: orderCalcResult?.offer?.p?.woodCategory,
      quantity: orderCalcResult?.offer?.finalAmount,
      price: orderCalcResult?.finalTotalPrice,
      items: [
        {
          id: orderValues.calcResult,
          name: orderCalcResult?.offer?.p?.name,
          list_name: 'Search Results',
          category: orderCalcResult?.offer?.p?.woodCategory,
          quantity: orderCalcResult?.offer?.finalAmount,
          price: orderCalcResult?.finalTotalPrice,
        },
      ],
    })
  }

  return {
    sendNewOrderEvents,
  }
}
