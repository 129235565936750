import useLocalStorageState from 'use-local-storage-state'
import React, { useCallback, useEffect, useState } from 'react'
import { isBefore, subDays, subMinutes, subSeconds } from 'date-fns'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import Popup from './Popup'
import { Checkbox, Input } from './fields'
import Title from './Title'
import Gap from './Gap'
import Button from './Button'
import { PersistFormikValues } from '../lib/PersistFormikValues'
import Col from './Col'
import Text from './Text'
import HintTooltip, { InfoIcon } from './fields/HintTooltip'
import CloseIcon from './CloseIcon'
import {
  useCustomerComplaintMutation,
  useFeedbackPopupMutation,
  useSendCalcMutation,
} from './hooks/useQueries'
import { feedbackSchema, sendCalcSchema } from '../validations/forms'
import usePopupFormAndMutation from './hooks/usePopupFormAndMutation'
import { RH } from '.'
import { useExperiments } from './hooks/useExperiments'

const PopupContent = styled.div`
  width: 100%;
  max-width: 800px;
  /* width: min(800px, 85vw); */
`

function SendCalcPopup(props) {
  const {
    isOpen,
    setPopupState,
    openPopup,
    closePopup,
    type,
    shortId,
    amountsResultId,
    ...rest
  } = props

  const { sendEvent, updateUserProperties } = useExperiments()

  // console.log('proppppp', amountsResultId, props)

  const { formMutation, onSubmit, formikPersistInstance } =
    usePopupFormAndMutation({
      type,
      useMutation: useSendCalcMutation,
      fieldsToKeep: ['email', 'newsletter'],
      // mutationArgs: [shortId],
      onError: (error) => {
        console.log('error', error)
        if (error.name === 'TooManySendCalcRequests') {
          alert(
            'Příliš časté odesílání kalkulace na email. Abychom zabránili spamu, limitujeme počet odeslaných kalkulací na email. Zkuste to prosím později.',
          )

          return true
        }
      },
    })

  function closeThisPopup() {
    closePopup()
    if (!formMutation.isLoading) {
      formMutation.reset()
    }
  }

  return isOpen ? (
    <Popup>
      <PopupContent>
        <CloseIcon onClick={closeThisPopup} />
        <Title.SectionTitle>Zaslat kalkulaci na email</Title.SectionTitle>
        <Gap gap="8px" />

        <Text>
          Zadejte svůj email a my vám pošleme vaší kalkulaci na email.
        </Text>
        <Gap gap="16px" />
        <Formik
          initialValues={{
            email: '',
            type,
            amountsResult: amountsResultId,
            newsletter: true,
          }}
          validationSchema={sendCalcSchema}
          onSubmit={onSubmit}
        >
          {({ submitForm, setFieldValue, isSubmitting, ...rest }) => (
            <Form>
              {formikPersistInstance}
              <Col maxWidth="550px" alignItems="flex-start" width="100%">
                <Input
                  name="email"
                  label="Email"
                  placeholder="Váš email"
                  type="email"
                  required
                />
                <Gap gap="8px" />

                <Checkbox
                  small
                  name="newsletter"
                  label="Chci také zařadit do odběru novinek (volitelné)"
                />
                <Gap gap="24px" />
                {!formMutation.isSuccess && (
                  <Button.FormikSubmitButton
                    onClick={() => {
                      sendEvent('send_calc_to_email')
                    }}
                  >
                    {formMutation.isLoading ? 'Odesílám...' : 'Odeslat'}
                  </Button.FormikSubmitButton>
                )}

                {formMutation.isSuccess && (
                  <Text color="green">✅ &nbsp; Odesláno</Text>
                )}
                <Gap gap="32px" />
              </Col>
            </Form>
          )}
        </Formik>
      </PopupContent>
    </Popup>
  ) : null
}

export default SendCalcPopup
