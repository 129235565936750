// https://github.com/kolengri/formik-persist-values#readme
import { FC, memo, useCallback, useEffect, useMemo } from 'react'

import { FormikValues, useFormikContext } from 'formik'
import useDebounce from 'react-use/lib/useDebounce'
import omit from 'lodash.omit'

const KEY_DELIMITER = '_'

/**
 * Hash function to do not persist different initial values
 * @param obj
 */
const getHash = (obj, specificity = 7) => {
  let hc = 0
  try {
    const chars = JSON.stringify(obj).replace(/\{|\"|\}|\:|,/g, '')
    const len = chars.length
    for (let i = 0; i < len; i++) {
      // Bump 7 to larger prime number to increase uniqueness
      hc += chars.charCodeAt(i) * specificity
    }
  } catch (error) {
    hc = 0
  }
  return hc
}

// Controls is working in browser
const useBrowser = () => typeof window !== 'undefined'

const useStorage = (props) => {
  const { storage = 'localStorage' } = props
  const isBrowser = useBrowser()

  switch (storage) {
    case 'sessionStorage':
      return isBrowser ? window.sessionStorage : undefined
    case 'localStorage':
      return isBrowser ? window.localStorage : undefined
    default:
      return storage
  }
}

export const usePersistedString = (props) => {
  const { name: defaultName, hashInitials, hashSpecificity } = props
  const { initialValues } = useFormikContext()
  const keyName = `${defaultName}${KEY_DELIMITER}`

  const name = useMemo(
    () => hashInitials
      ? `${keyName}${getHash(initialValues, hashSpecificity)}`
      : defaultName,
    [defaultName, hashInitials, JSON.stringify(initialValues), hashSpecificity],
  )

  const storage = useStorage(props)

  const state = useMemo(() => {
    if (storage) {
      return storage.getItem(name)
    }
    return null
  }, [name, storage])

  const handlePersistValues = useCallback(
    (values) => {
      if (storage) {
        storage.setItem(name, JSON.stringify(values))
        // Remove all past cached values for this form
        Object.keys(storage).forEach((key) => {
          if (key.indexOf(keyName) > -1 && key !== name) {
            storage.removeItem(key)
          }
        })
      }
    },
    [storage],
  )

  return [state, handlePersistValues]
}

const PersistFormikValuesMemo = (props) => {
  const {
    debounce = 300,
    persistInvalid,
    ignoreValues,
    overwriteValues,
  } = props
  const { values, setValues, isValid, initialValues } = useFormikContext()
  const [persistedString, persistValues] = usePersistedString(props)
  const stringValues = JSON.stringify(values)

  const handlePersist = useCallback(() => {
    if (isValid || persistInvalid) {
      const valuesToPersist = ignoreValues ? omit(values, ignoreValues) : values
      persistValues(valuesToPersist)
    }
  }, [stringValues, isValid, persistInvalid])

  useEffect(() => {
    if (persistedString) {
      // Catches invalid json
      try {
        const persistedValues = JSON.parse(persistedString)
        const newValues = {
          ...initialValues,
          ...persistedValues,
          ...overwriteValues,
        }

        // console.log('newValuesnewValuesnewValues', newValues)
        // Initial values should be merged with persisted
        if (stringValues !== JSON.stringify(newValues)) {
          setValues(newValues)
        }
      } catch (error) {
        console.error('Parse persisted values is not possible', error)
      }
    }
  }, [persistedString])

  useDebounce(handlePersist, debounce, [stringValues, isValid, persistInvalid])

  return null
}

export const PersistFormikValues = memo(PersistFormikValuesMemo)
