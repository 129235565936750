import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { useField } from 'formik'
import HintTooltip from './HintTooltip'
import Error from './Error'
import Label from './Label'
import {
  addResponsivity,
  up,
  down,
  between,
  addFont,
  colors,
} from '../../lib/styles'
import { Col, Row, Text, Title, Gap, Link, VH, Button } from '..'
import Success from './Success'
import { CheckIcon } from './CheckIcon'

export const StyledInput = styled.input`
  ${({
    theme: { colors },
    error,
    customCss,
    success,
    background,
    height,
  }) => css`
    ${addFont('signika')}
    background: ${background || colors.inputGrey};
    box-shadow: inset 0px 1px 5px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    border: 0;
    padding: 10px 16px;
    border: solid 2px white;
    outline: none;
    color: ${colors.text};
    height: ${height || 'auto'};
    ${customCss}

    ${error &&
    css`
      border-color: ${colors.red};
    `}
    ${success &&
    css`
      border-color: ${colors.green};
    `}
  `}
`

const Input = ({
  label,
  hint,
  labelAsPlaceholder,
  placeholder,
  name,
  withoutGap,
  showSuccess,
  successLabel,
  width,
  maxWidth,
  minWidth,
  colProps,
  labelFontSize,
  size,
  long,
  ...props
}) => {
  const [field, meta, helpers] = useField(name)

  // Show inline feedback if EITHER
  // - the input is focused AND value is longer than 2 characters
  // - or, the has been visited (touched === true)
  // const [didFocus, setDidFocus] = React.useState(false)
  // const handleFocus = () => setDidFocus(true)
  // const showFeedback =
  //   (!!didFocus && field.value.trim().length > 2) || meta.touched

  const labelProps = {
    fontSize: labelFontSize,
  }
  const inputProps = {}
  if (size === 'small') {
    labelProps.fontSize ??= '16px'
  }
  if (long) {
    inputProps.as = 'textarea'
  }

  const revealSuccess =
    showSuccess && meta.touched && !meta.error && field.value

  return (
    <Col {...colProps} width={width} maxWidth={maxWidth} minWidth={minWidth}>
      {!labelAsPlaceholder && (
        <>
          <Row>
            <Label htmlFor={name} {...labelProps}>
              {label}
            </Label>
            <HintTooltip hint={hint} />
          </Row>
          <Gap gap={size === 'small' ? '0px' : '8px'} />
        </>
      )}
      <Col position="relative">
        <StyledInput
          {...inputProps}
          {...field}
          {...props}
          placeholder={labelAsPlaceholder ? label || placeholder : placeholder}
          error={meta.touched && meta.error}
          success={revealSuccess}
        />
        {revealSuccess && <CheckIcon />}
      </Col>
      {meta.touched && meta.error && <Error name={name} />}
      {revealSuccess && successLabel && <Success text={successLabel} />}
      {!withoutGap && <Gap gap="16px" />}
    </Col>
  )
}

export default Input
