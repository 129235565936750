import React, { useCallback, useContext, useEffect, useState } from 'react'
import useLocalStorageState from 'use-local-storage-state'
import { nanoid } from 'nanoid'
import { useCustomerId } from './hooks/useUserId'

const eventPresets = {
  calculate: {
    eventName: 'calculate',
    body: {
      event_category: 'Calculator',
      event_label: 'Calc wood offers',
      value: 'hodnoty z kalkulacky?',
      eventCalculateCounter: 1,
    },
  },
  // prvni kalkulace na konkretni strance
  page_first_calculate: {
    eventName: 'page_first_calculate',
    body: {
      event_category: 'Calculator',
      event_label: 'Calc wood offers',
      value: 'hodnoty z kalkulacky?',
      eventPageFirstCalculateCounter: 1,
    },
  },
  // prvni kalkulace v dane session (bez refreshe)

  session_first_calculate: {
    eventName: 'session_first_calculate',
    body: {
      event_category: 'Calculator',
      event_label: 'Calc wood offers',
      value: 'hodnoty z kalkulacky?',
      eventSessionFirstCalculateCounter: 1,
    },
  },
  subscribe: {
    eventName: 'subscribe',
    body: {
      eventSubscribeCounter: 1,
    },
  },
  open_cart: {
    eventName: 'open_cart',
    body: {
      eventCartCounter: 1,
    },
  },
  cart_step: {
    eventName: 'cart_step',
    body: {
      eventCartStepCounter: 1,
      // eventCartStepNumber: 1 / 2 / 3 -- set when sending event
    },
  },
  purchase: {
    eventName: 'purchase',
    body: {
      eventPurchaseCounter: 1,
    },
  },
  exit_intent_popup: {
    eventName: 'exit_intent_popup',
    body: {
      exitIntentPopupCounter: 1,
    },
  },
  open_shared_calc_page: {
    eventName: 'open_shared_calc_page',
    body: {
      eventOpenSharedCalcCounter: 1,
    },
  },
  open_send_calc_popup: {
    eventName: 'open_send_calc_popup',
    body: {
      eventOpenSendCalcPopupCounter: 1,
    },
  },
  open_share_calc_popup: {
    eventName: 'open_share_calc_popup',
    body: {
      eventOpenShareCalcPopupCounter: 1,
    },
  },
  send_calc_to_email: {
    eventName: 'send_calc_to_email',
    body: {
      eventSendCalcToEmailCounter: 1,
    },
  },
  share_calc_clicked: {
    eventName: 'share_calc_clicked',
    body: {
      eventShareCalcClickedCounter: 1,
    },
  },
}

// do not forget to add gatsby config core - not anymore.
// add directly to GA4 settings

const dimensions = {
  graph: {
    values: ['detailed', 'lofi'],
  },
  hpButton: {
    // control A/A test = does nothing
    values: ['variant1', 'variant2'],
  },
  notifs: {
    // values: ['on', 'off', 'shuffled', 'withButton'],
    values: ['off'],
  },
  expMobileCalcGirl: {
    // values: ['on', 'off'],
    values: ['off'],
  },
  expPageTop: {
    values: ['original', 'v2'],
  },
  expCalculator: {
    values: ['v2'],
  },
  expCart: {
    values: ['original', 'v2'],
    posthog: true,
  },
  expAA: {
    values: ['control', 'test'],
    posthog: true,
  },
}
const dimensionsConfig = dimensions
const posthogFeaturesMapping = {
  original: 'control',
  v2: 'test',
}

const decorateDimensions = (dimensions) => ({
  ...dimensions,
  expIsNewCalculator: dimensions.expCalculator === 'v2',
  // expIsNewCalculator:
  //   process.env.NODE_ENV === 'development'
  //     ? true
  //     : dimensions.expCalculator === 'v2',
  // expIsNewCart:
  //   process.env.NODE_ENV === 'development' ? true : dimensions.expCart === 'v2',
  expIsNewCart: dimensions.expCart === 'v2',
})

const dimVersion = 7

const isInPercent = (percent) => percent < Math.random()

const getRandomValue = (arr) => arr[Math.floor(arr.length * Math.random())]

const getInitialDimensions = () => ({
  ...Object.keys(dimensions).reduce((acc, key) => {
    const dim = dimensions[key]

    acc[key] = getRandomValue(dim.values)

    return acc
  }, {}),
  dimVersion,
})

const getDevOptions = () => {
  const location = typeof window !== 'undefined' ? window.location : null

  if (!location) {
    return {
      resetDims: false,
      devDimensions: {},
    }
  }

  const params = new URLSearchParams(location.search)
  const resetDims = params.get('exp') === 'dev'

  const devDimensions = {}

  params.forEach((value, key) => {
    devDimensions[key] = value
  })

  return {
    resetDims,
    devDimensions,
  }
}

const createExperimentsContext = () => {
  const experimentsContext = React.createContext({})
  const { Provider, Consumer } = experimentsContext

  const ExperimentsProvider = ({ children }) => {
    const [sessionSentEvents, setSessionSentEvents] = useState({})
    const [pageSentEvents, setPageSentEvents] = useState({})
    const initialDimensions = getInitialDimensions()

    const devOptions = getDevOptions()
    if (devOptions.resetDims) {
      initialDimensions.dimVersion = nanoid()
      Object.keys(devOptions.devDimensions).forEach((key) => {
        initialDimensions[key] = devOptions.devDimensions[key]
      })
    }

    const { customerId, getOrGenerateCustomerId } = useCustomerId()
    // console.log('exp id', customerId)

    // console.log('initialDimensions', initialDimensions)

    const [dimensions, setDimensions] = useLocalStorageState('dimensions', {})
    const [updatedAt, setUpdatedAt] = useLocalStorageState(
      'dimensions-updated-at',
      null
    )

    const [latestUserProperties, setLatestUserProperties] = useState({})

    // const [dimensions, setDimensions] = useState({
    //   graph: 'detailed',
    //   hpButton: 'variant1',
    // })

    const gtag = (...args) => {
      const fn =
        typeof window !== 'undefined'
          ? window.gtag
          : (...args) => {
              console.log('this is not browsers, ignoring gtag', args)
            }

      if (!fn && typeof window !== 'undefined') {
        console.error('GTAG not defined even if this is browser!!')
        console.warn('GTAG not defined even if this is browser!!')
        console.log('GTAG not defined even if this is browser!!')
      }

      if (
        process.env.NODE_ENV === 'development' ||
        process.env.SERVER_ENV === 'dev'
      ) {
        console.log('gtag called, skipping in DEV', args)
      } else {
        fn?.(...args)
      }
    }

    const phFallback = (...args) => {
      console.log('posthog not defined', args)
    }
    const posthog =
      typeof window !== 'undefined'
        ? window.posthog
        : {
            identify: phFallback,
            capture: phFallback,
            setPersonProperties: phFallback,
          }

    const sendEvent = (eventName, body = {}) => {
      const preset = eventPresets[eventName] || {
        eventName,
      }

      gtag('event', preset.eventName, {
        ...dimensions,
        ...preset.body,
        ...body,
        user_id: getOrGenerateCustomerId(),
        user_id_dim: getOrGenerateCustomerId(),
        event_value_count: 1,
        // notifsUserConf: dimensions.notifs,
      })
      console.log('sending event:', preset.eventName)

      // implement posthog
      posthog.identify(getOrGenerateCustomerId())

      const featureFlags = {}
      Object.entries(dimensionsConfig).forEach(([key, dim]) => {
        if (dim.posthog) {
          featureFlags[`$feature/${key}`] =
            posthogFeaturesMapping[dimensions[key]] || dimensions[key]
        }
      })

      posthog.capture(preset.eventName, {
        ...dimensions,
        ...preset.body,
        ...body,
        user_id: getOrGenerateCustomerId(),
        user_id_dim: getOrGenerateCustomerId(),
        event_value_count: 1,
        ...featureFlags,
      })

      console.log('featureFlags', featureFlags)

    }

    const sendPageEvent = (eventName, body) => {
      const { href } = window.location
      const wasSent = !!pageSentEvents[href]?.[eventName]

      if (!wasSent) {
        setPageSentEvents({
          ...pageSentEvents,
          [href]: {
            ...pageSentEvents[href],
            [eventName]: true,
          },
        })
        sendEvent(eventName, body)
        console.log('sending page event', eventName)
      }
    }

    const sendSessionEvent = (eventName, body) => {
      const wasSent = !!sessionSentEvents[eventName]

      if (!wasSent) {
        setSessionSentEvents({
          ...sessionSentEvents,
          [eventName]: true,
        })
        sendEvent(eventName, body)
        console.log('sending session event', eventName)
      }
    }

    const setUserProperties = (properties) => {
      console.log('setting user properties', properties)

      const props = {
        ...dimensions,
        ...properties,
        user_id: getOrGenerateCustomerId(),
        user_id_dim: getOrGenerateCustomerId(),
      }

      setLatestUserProperties((state) => ({
        ...state,
        ...props,
      }))

      gtag('set', 'user_properties', props)

      posthog.setPersonProperties({
        ...props,
      })
    }

    const updateUserProperties = (properties) => {
      console.log('updating user properties', properties)
      setLatestUserProperties((state) => ({
        ...state,
        ...properties,
      }))
      gtag('set', 'user_properties', {
        ...properties,
      })
      posthog.setPersonProperties({
        ...properties,
      })
    }

    // useEffect(() => {
    //   sendEvent('experiments_conf', { hpButton: 'variant1' })
    //   console.log('!sending dimensions', dimensions)
    // }, [])

    useEffect(() => {
      const savedDim = window.localStorage.getItem('dimensions')
      if (
        !savedDim ||
        !initialDimensions ||
        dimensions.dimVersion !== dimVersion
      ) {
        setDimensions(initialDimensions)
        setUpdatedAt(new Date())
        console.log('UPDATE DIM!!!')
        console.log('!sending created dimensions', initialDimensions)
        // gtag('set', 'userData', {
        //   ...initialDimensions,
        //   notifsUserConf: initialDimensions.notifs,
        // })
        // sendEvent('experiments_conf', initialDimensions)
        // sendEvent('user_properties', initialDimensions)
        setUserProperties({
          ...initialDimensions,
          // notifsUserConf: initialDimensions.notifs,
        })
        sendEvent('custom_first_view', initialDimensions)

        // gtag('set', 'user_properties', {
        //   ...initialDimensions,
        //   notifsUserConf: initialDimensions.notifs,
        // })
      } else {
        setUserProperties({
          ...dimensions,
          // notifsUserConf: dimensions.notifs,
        })
        // gtag('set', 'user_properties', {
        //   ...dimensions,
        //   notifsUserConf: dimensions.notifs,
        // })
        sendEvent('custom_first_view', dimensions)
        // sendEvent('user_properties', dimensions)
        console.log('!sending saved dimensions', dimensions)
      }
    }, [])

    // console.log('DIM', dimensions, updatedAt)

    const sharedValues = {
      dimensions: decorateDimensions(dimensions),
      setDimensions,
      gtag,
      sendEvent,
      setUserProperties,
      sendPageEvent,
      sendSessionEvent,
      updateUserProperties,
      latestUserProperties,
    }

    return <Provider value={sharedValues}>{children}</Provider>
  }

  return {
    ExperimentsProvider,
    experimentsContext,
    ExperimentsConsumer: Consumer,
  }
}

export const { ExperimentsProvider, ExperimentsConsumer, experimentsContext } =
  createExperimentsContext()

export default ExperimentsProvider
