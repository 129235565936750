/* eslint-disable import/prefer-default-export */
export const entities = {
  // remember to update gatsby Link component accordingly
  pageKey: {
    staticPages: true,
    label: 'Static pages',
  },
  // pageId: {
  //   resource: 'pages',
  //   label: 'Pages',
  //   isGatsbyPage: true,
  // },
  // anchorId: {
  //   resource: 'anchors',
  //   label: 'Anchors',
  //   clearFields: ['fileId'],
  //   doNotClean: true,
  // },
  postId: {
    resource: 'posts',
    label: 'Posts',
    isGatsbyPage: true,
  },
}
