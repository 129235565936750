import React, { useState } from 'react'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import styled, { css } from 'styled-components'

import { Link, RH, Row, VH, Col, withScrollDetection } from '.'
import { fadeInFromNone, menuFadeIn, menuFadeOut } from '../lib/animations'
import { down, up, addResponsivity, between, theme } from '../lib/styles'

import Gap from './Gap'

// assets
import logoPath from '../data/images/logo-bree.svg'
import menuOpenPath from '../data/images/menu-open.svg'
import menuClosePath from '../data/images/menu-close.svg'
import useOnScroll from './hooks/useOnScroll'
import TP from './TP'
import Logo from './Logo'
import Burger from './Burger'
import { links } from '../lib/links'
import Icon from './Icon'
import { useGlobalContext } from './GlobalContext'
import { useExperiments } from './hooks/useExperiments'

const NavLink = styled(Link.NavLink)`
  ${({ theme: { colors, menuConfig }, asButton, hideDesktop }) => css`
    color: ${menuConfig.dark ? colors.orange : colors.lightOrange};

    & > a {
      font-size: 16px;
      padding: 20px 16px 16px;

      ${up('mobile')} {
        padding: 20px 6px 16px;
      }
      ${up(920)} {
        padding: 20px 16px 16px;
      }

      &:hover {
        color: ${!menuConfig.dark ? colors.orange : colors.lightOrange};
      }
    }

    ${hideDesktop &&
    css`
      ${up('menu')} {
        display: none;
      }
    `}

    ${asButton &&
    css`
      & > a {
        background-color: ${menuConfig.dark
    ? colors.orange
    : colors.lightOrange};
        border-radius: 12px;
        transition: 0.3s;
        text-align: center;
        color: ${colors.white};
        padding: 10px 16px;
        margin: 4px;
        white-space: nowrap;

        &:hover {
          background-color: ${!menuConfig.dark
    ? colors.orange
    : colors.lightOrange};
        }

        &:hover {
          color: ${colors.white};
        }

        ${up('mobile')} {
          padding: 10px 6px;
        }
        ${up(920)} {
          padding: 10px 16px;
        }
      }
    `}
  `}
`

const Wrapper = styled.div`
  ${({
    theme: {
      sizes: { pageWidth },
    },
    absolute,
    isPinned,
    isPinnedOpen,
  }) => css`
    z-index: 10000;
    align-self: center;
    max-width: ${pageWidth}px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: 0 auto;
    padding: 0 10px;
    position: absolute;

    ${isPinned &&
    css`
      transition: 0.2s;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 100;
      width: 100%;
      background: white;
      transform: translate(0px, -150%);
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      border-radius: 0px 0px 12px 12px;
    `}

    ${isPinnedOpen &&
    css`
      transform: translate(0px, 0%);
    `}
  `}
`

const Container = styled.div`
  ${({ theme: { colors, sizes }, isPinned }) => css`
    max-width: ${sizes.pageWidth}px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    display: flex;
    height: auto;
    ${up('menu')} {
    }
  `}
`

const LogoWrapper = styled.div`
  ${({
    isPinned,
    smallerBp,
    smaller,
    theme: {
      colors,
      menuConfig: { dark },
    },
  }) => css`
    display: block;
    width: 185px;
    height: 58px;
    transform: translate(0px, 05px);

    ${smaller &&
    css`
      width: 195px;
      transform: translate(0px, 5px);
    `}

    & > svg {
      width: 100%;
      path {
        fill: ${dark ? colors.orange : colors.lightOrange};
      }
    }
  `}
`

const NavList = styled.div`
  ${({ theme: { sizes, colors }, isOpen }) => css`
    flex-direction: row;
    display: none;
    align-items: center;

    ${isOpen &&
    css`
      display: flex;
      position: absolute;
      flex-direction: column;
      background: ${colors.menuDarkBrown};
      width: 100%;
      top: 0;
      left: 0;
    `}

    ${up('menu')} {
      background: none;
      display: flex;
      position: relative;
    }
  `}
`

const BurgerWrapper = styled.div`
  ${({
    theme: {
      sizes,
      colors,
      menuConfig: { dark },
    },
    isOpen,
  }) => css`
    cursor: pointer;
    z-index: 1;
    /* margin: 16px 0 0; */
    position: relative;
    /* height: 32px; */
    /* height: 48px;
    width: 48px; */

    width: 48px;

    padding: 8px;

    ${up('menu')} {
      display: none;
    }

    svg {
      width: 100%;
      rect,
      path {
        fill: ${dark ? colors.orange : colors.lightOrange};
      }
    }
  `}
`

// const BurgerWrapper = styled.div`
//   ${({ theme: { sizes }, isOpen, animationDelay = 0.1 }) => css`
//     display: block;
//     /* padding: 35px 0 20px; */
//     top: 50%;
//     left: 50%;
//     position: relative;
//     transform: translate(-50%, -50%);
//   `}
// `

const menuLinks = [
  {
    text: 'Srovnávač - objednat dřevo',
    to: '/',
    anchor: 'calculator',
    asButton: true,
  },
  // {
  //   text: 'Bukové dřevo',
  //   to: Link.CALC_BEECH,
  //   anchor: 'calculator',
  // },
  {
    text: 'Dřevo do krbu',
    to: Link.CALC_FIREPLACE,
    anchor: 'calculator',
  },
  // {
  //   text: 'Slovníček',
  //   to: Link.GLOSSARY,
  // },
  {
    text: 'Cenová mapa',
    to: Link.PRICE_MAP,
  },
  {
    text: 'Slovníček',
    to: Link.GLOSSARY,
  },
  {
    text: 'Blog',
    to: Link.BLOG,
  },
  {
    text: 'Kontakt',
    to: '/',
    anchor: 'contact',
    disableActive: true,
    inMinimal: true,
  },
  // {
  //   text: '608 948 666',
  //   href: 'tel:608948666',
  //   asA: true,
  //   hideBetween: ['menu', 1135],
  // },
]

const StyledIcon = styled(Icon)`
  ${({
    theme: {
      colors,
      menuConfig: { dark },
    },
  }) => css`
    path {
      stroke-width: 0.4px;
      stroke: ${dark ? colors.orange : colors.lightOrange};
    }
  `}
`

class BaseMenu extends React.Component {
  state = {
    isOpen: false,
  }

  targetRef = React.createRef()

  targetElement = null

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
    const height = this.wrapperRef.clientHeight
    this.setState({ height })
    this.targetElement = this.targetRef.current
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)
    clearAllBodyScrollLocks()
  }

  handleResize = () => {
    if (window.innerWidth > theme.breakpoints.menu) {
      this.closeMenu()
    }
  }

  toggleMenu = () => {
    const { isOpen } = this.state
    if (isOpen) {
      enableBodyScroll(this.targetElement)
    } else {
      disableBodyScroll(this.targetElement)
    }

    this.setState({ isOpen: !isOpen })
  }

  closeMenu = () => {
    this.setState({ isOpen: false })
    enableBodyScroll(this.targetElement)
  }

  render() {
    const { isOpen, height } = this.state
    const { config, pathname, isPinned, isPinnedOpen, isCartEmpty, showCart } =
      this.props

    const trimmedPathname = pathname.replace(/\/$/, '') || '/'
    // console.log('menu pathname', pathname, trimmedPathname)

    return (
      <TP menuConfig={config}>
        <Wrapper
          {...config}
          isPinned={isPinned}
          isPinnedOpen={isPinnedOpen}
          isOpen={isOpen}
          ref={(ref) => {
            this.wrapperRef = ref
          }}
          height={height}
        >
          <Container>
            <div>
              <Link to="/" onClick={this.closeMenu}>
                <LogoWrapper smaller={isPinned}>
                  <Logo />
                </LogoWrapper>
              </Link>
            </div>

            <NavList isOpen={isOpen} ref={this.targetRef}>
              <Gap mobileGap="48px" gap="0" />
              {menuLinks.map((l, index) => (
                <NavLink
                  isOpen={isOpen}
                  asButton={l.asButton}
                  key={index}
                  to={l.to}
                  isActive={trimmedPathname === l.to && !l.disableActive}
                  onClick={this.closeMenu}
                  animationDelay={0.04 * index}
                  isPinned={isPinned}
                  asA={l.asA}
                  href={l.href}
                  anchor={l.anchor}
                  hideBetween={l.hideBetween}
                  hideDesktop={config.minimal && !l.inMinimal}
                >
                  {l.text}
                </NavLink>
              ))}
              {showCart && (
                <NavLink
                  isOpen={isOpen}
                  key="cart"
                  to={links.CART}
                  onClick={this.closeMenu}
                  isPinned={isPinned}
                  hideDesktop={config.minimal}
                >
                  <StyledIcon
                    name={isCartEmpty ? 'CartEmpty' : 'CartCheck'}
                    size="24px"
                  />
                </NavLink>
              )}
            </NavList>

            <Row justifyContent="flex-end" grow="10" hideAfter="menu">
              <BurgerWrapper isOpen={isOpen} onClick={this.toggleMenu}>
                <Burger closed={!isOpen} />
              </BurgerWrapper>
            </Row>
          </Container>
        </Wrapper>
      </TP>
    )
  }
}

const SCROLL_UP_THRESHOLD = 100
const SCROLL_DOWN_THRESHOLD = 50

const Menu = ({ ...props }) => {
  const [isOnContent, setIsOnContent] = useState(false)
  const [scrollsUp, setScrollsUp] = useState(false)
  const [prevScrollTop, setPrevScrollTop] = useState(-1)
  const isStickyOpen = isOnContent && scrollsUp

  useOnScroll(({ scrollTop }) => {
    if (prevScrollTop === -1) setPrevScrollTop(scrollTop)
    if (scrollTop > 1000 !== isOnContent) setIsOnContent(!isOnContent)

    const scrollDif = scrollTop - prevScrollTop

    if (scrollDif > SCROLL_DOWN_THRESHOLD || scrollDif < -SCROLL_UP_THRESHOLD) {
      if (scrollTop < prevScrollTop && !scrollsUp) setScrollsUp(true)
      if (scrollTop > prevScrollTop && scrollsUp) setScrollsUp(false)

      setPrevScrollTop(scrollTop)
    }
  })

  const { isCartEmpty } = useGlobalContext()

  const { dimensions } = useExperiments()

  // console.log('isStickyOpen', isStickyOpen, isOnContent)

  return (
    <BaseMenu
      {...props}
      isPinned={isOnContent}
      isPinnedOpen={isStickyOpen}
      isCartEmpty={isCartEmpty()}
      showCart={dimensions.expIsNewCart}
    />
  )
}

export default Menu
