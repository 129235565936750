import { useContext } from 'react'

/* eslint-disable import/prefer-default-export */
export const useExperiments = () => {
  const { experimentsContext } = require('../ExperimentsProvider')

  const values = useContext(experimentsContext)

  return values
}
