import { useEffect } from 'react'

const useLostFocus = (callback) => {
  useEffect(() => {
    const handleVisibilityChange = () => {
      console.log('handleVisibilityChange', document.visibilityState)
      if (document.visibilityState === 'hidden') {
        callback()
      }
    }

    const handleBlur = () => {
      console.log('blur')
      callback()
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)
    window.addEventListener('blur', handleBlur)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      window.removeEventListener('blur', handleBlur)
    }
  }, [callback])
}

export default useLostFocus
