// this is imported by KEYSTONE ---> dont use gatsby internals here!
// import { applyLang, applyLangSingle } from './intl'

const applyLang = () => {}
const applyLangSingle = () => {}

// extract nodes from edges, if no data returns empty array like in rest
// (graphql returns null if nothing is found in db...)
export const extractNodes = data => (data && data.edges && data.edges.map(e => e.node)) || []

// extract all edges - nodes data from all data results from DB / graphql
export const extractNodesFromData = (propsData = {}) => {
  const transformed = { ...propsData }
  Object.keys(propsData).forEach(key => {
    // console.log('propsData', propsData, key, propsData[key])
    if (!propsData[key]) {
      console.log('NO PROPS DATA FOR KEY', key)
    }
    // some data does not have to have edges, skip them
    if (propsData[key]?.edges) {
      transformed[key] = propsData[key].edges.map(e => e.node)
    }
  })

  return transformed
}

export const withSameTags = (arrayToFilter = [], tags = []) =>
  arrayToFilter.filter(
    p => p.tags && p.tags.some(tag => tags && tags.find(tag2 => tag2._id === tag._id)),
  )

// extract all edges - nodes data from all data results from DB / graphql
// AND apply lang extractions
export const extractDataAndApplyLangs = (propsData = {}, lang) => {
  if (!lang) {
    console.error('pozor, pri extrakci neni nastaven jazyk!')
  }
  const transformedData = extractNodesFromData(propsData)

  // console.log('transformedData',transformedData )

  Object.keys(propsData || {}).forEach(key => {
    // if its single model only
    // console.log('transformedData[key]', transformedData[key], key, transformedData)
    if (typeof transformedData[key] === 'object' && transformedData[key]?.modelName) {
      // console.log('transformedData[key]', transformedData[key])
      transformedData[key] = applyLangSingle(transformedData[key], lang)
    } else if (
      typeof transformedData[key] === 'object' &&
      Array.isArray(transformedData[key]) &&
      transformedData[key][0] &&
      transformedData[key][0].modelName
    ) {
      // console.log('transformedData[key]', transformedData[key])
      transformedData[key] = applyLang(transformedData[key], lang)
    }
  })

  return { ...propsData, ...transformedData }
}

// newly used in data transforms for editor previews
export const applyLangsToData = (transformedData = {}, lang) => {
  if (!lang) {
    console.error('pozor, pri extrakci neni nastaven jazyk!')
  }

  Object.keys(transformedData || {}).forEach(key => {
    // if its single model only
    if (typeof transformedData[key] === 'object' && transformedData[key].modelName) {
      // console.log('transformedData[key]', transformedData[key])
      transformedData[key] = applyLangSingle(transformedData[key], lang)
    } else if (
      typeof transformedData[key] === 'object' &&
      Array.isArray(transformedData[key]) &&
      transformedData[key][0] &&
      transformedData[key][0].modelName
    ) {
      // console.log('323transformedData[key]', transformedData[key])
      transformedData[key] = applyLang(transformedData[key], lang)
    }
  })

  return { ...transformedData }
}

export function mapIdsToItems(ids, items) {
  return ids?.map(id => items.find(item => item._id === id)).filter(Boolean)
}

export function extractTranslateAndMapIdsToItems(ids, data, language) {
  const { items } = extractDataAndApplyLangs(data, language)

  return mapIdsToItems(ids, items)
}

export function extractTranslateAndPickItem(id, data, language) {
  const { items } = extractDataAndApplyLangs(data, language)
  const item = items.find(item => item._id === id)

  return item
}
