import React, { Component, useRef, useState } from 'react'
import styled, { css } from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip'
import { darken, lighten } from 'polished'
import { withStyles } from '@material-ui/core/styles'
import {
  addResponsivity,
  up,
  down,
  between,
  addFont,
  colors,
} from '../../lib/styles'
import { Col, Row, Text, Title, Gap, Link, VH, Button } from '..'
import isTouchDevice from '../../lib/isTouchDevice'

const StyledCoreIcon = styled.span`
  ${({ theme: { colors }, darker }) => css`
    ${addFont('signika')}
    font-weight: 700;
    font-size: 14px;
    color: #757575;
    width: 20px;
    height: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 200px;
    transition: 0.3s;
    cursor: pointer;
    background: ${darker ? darken(0.1, colors.inputGrey) : colors.inputGrey};

    &:hover {
      background: ${darken(darker ? 0.2 : 0.1, colors.inputGrey)};
    }
  `}
`

export const HintIcon = (props) => <StyledCoreIcon {...props}>?</StyledCoreIcon>
export const InfoIcon = (props) => <StyledCoreIcon {...props}>i</StyledCoreIcon>

export const StyledTooltip = withStyles(() => ({
  tooltip: {
    fontSize: '15px',
    padding: '12px 20px',
    background: 'rgba(0,0,0,0.99)',
    borderRadius: '10px',
  },
  arrow: {
    color: 'rgba(0,0,0,0.99)',
  },
  popper: {
    zIndex: 999999,
  },
}))(Tooltip)

export const TooltipChildrenWrapper = styled.span`
  cursor: pointer;
`

const TooltipChildrenWrapperBasic = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const HintTooltip = ({
  hint,
  title,
  withoutGap,
  infoIcon,
  children,
  noIcon,
  interactive,
  enablePortal,
}) => {
  const [isTouch, setIsTouch] = useState(() => isTouchDevice())
  const [isOpen, setOpen] = useState(false)

  const timerRef = useRef(null)

  return !hint && !title ? null : (
    <>
      {!withoutGap && <Gap />}
      <StyledTooltip
        arrow
        title={hint || title}
        placement="top"
        open={isTouch ? isOpen : undefined}
        interactive={interactive}
        leaveDelay={400}
        enterTouchDelay={1}
        disableFocusListener
        withoutChildrenWrapper
        PopperProps={{
          disablePortal: !enablePortal,
          // popperOptions: {
          //   modifiers: [
          //     {
          //       name: 'flip',
          //       enabled: false,
          //     },
          //   ],
          // },
        }}
      >
        <TooltipChildrenWrapperBasic
          onClick={() => {
            setOpen(true)
          }}
          onMouseEnter={() => {
            setOpen(true)
          }}
          onMouseLeave={() => {
            clearTimeout(timerRef.current)
            timerRef.current = setTimeout(() => {
              setOpen(false)
            }, 300)
          }}
        >
          {!noIcon && infoIcon && <InfoIcon />}
          {!noIcon && !infoIcon && <HintIcon />}
          {children && children}
        </TooltipChildrenWrapperBasic>
      </StyledTooltip>
    </>
  )
}

export default HintTooltip

export const BasicTooltip = ({
  hint,
  title,
  withoutGap,
  infoIcon,
  children,
  noIcon,
  interactive,
  ...props
}) => (
  <StyledTooltip
    PopperProps={{
      disablePortal: true,
    }}
    arrow
    title={hint || title}
    placement="top"
    interactive={interactive}
    leaveDelay={300}
    enterTouchDelay={1}
    disableFocusListener
    withoutChildrenWrapper
    {...props}
  >
    {children}
  </StyledTooltip>
)
