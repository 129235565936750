import React from 'react'
import styled, { css } from 'styled-components'
import { darken, lighten, saturate } from 'polished'
import { useFormikContext } from 'formik'
import Loading from '@kiwicom/orbit-components/lib/Loading'
import Link from './Link'
import ButtonBase from './ButtonBase'
import { down } from '../lib/styles'
import { useExperiments } from './hooks/useExperiments'
import ButtonRealistic from './ButtonRealistic'

const ButtonLink = styled(Link)`
  ${({ theme, responsiveFullWidth, bp = 'mobile' }) => css`
    ${responsiveFullWidth &&
    css`
      ${down(bp)} {
        width: 100%;
      }
    `}
  `}
`

export const ButtonSmallText = styled.div`
  font-size: 13px;
  text-transform: none;
`

const LoadingWrapper = styled.div`
  ${({ theme, small }) => css`
    & > div {
      ${small &&
      css`
        transform: scale(0.65);
        overflow: visible;
      `}
    }
  `}
`

const Button = ({ realistic, children, isLoading, ...props }) => {
  const Component = realistic ? ButtonRealistic : ButtonBase

  return (
    <Component {...props}>
      {isLoading ? (
        <>
          <LoadingWrapper small={props.preset === 'small'}>
            <Loading type="buttonLoader" />
          </LoadingWrapper>
          <span>&nbsp;</span>
        </>
      ) : (
        children
      )}
    </Component>
  )
}

Button.Link = ({
  dashed,
  responsiveFullWidth,
  buttonProps,
  children,
  bp,
  ...rest
}) => {
  const Component = dashed ? Button.Dashed : Button

  return (
    <ButtonLink
      noStyle
      responsiveFullWidth={responsiveFullWidth}
      bp={bp}
      {...rest}
    >
      <Component
        {...buttonProps}
        responsiveFullWidth={responsiveFullWidth}
        bp={bp}
        children={children}
      />
    </ButtonLink>
  )
}

Button.FormikSubmitButton = ({
  children,
  isSubmitting,
  onSubmit,
  onClick,
  ...props
}) => {
  const { submitForm, ...allContext } = useFormikContext()

  // console.log('allContext', allContext)

  let renderChildren = children
  if (typeof children === 'function') {
    renderChildren = children(allContext)
  }

  // console.log('renderChildren', renderChildren)
  return (
    <Button
      disabled={allContext.isSubmitting || isSubmitting}
      isLoading={allContext.isSubmitting || isSubmitting}
      {...props}
      onClick={(e) => {
        e.preventDefault()
        onClick?.()
        if (onSubmit) {
          onSubmit()
        } else {
          submitForm()
        }
      }}
    >
      <>{renderChildren}</>
    </Button>
  )
}

Button.CalculatorLink = ({ to = '/', samePage = true, children }) => {
  const { sendEvent } = useExperiments()

  const onClick = () => {
    sendEvent('button_click', {
      to,
      button_name: 'calculator_link',
      text: children,
    })
  }

  return (
    <Button.Link
      to={to}
      asSpan={samePage}
      anchor="calculator"
      onClick={onClick}
    >
      {children}
    </Button.Link>
  )
}

export default Button
