export const apiData = [
  {
    amount: 1,
    price: 1251,
    totalPrice: 1251,
    numOfPalettes: 0,
    offer: {
      totalPrice: 1251,
      finalTotalPrice: 1251,
      finalAmount: 1,
      amount: 1,
      p: {
        _id: '5ff7895a1b767f189433018d',
        woodCategory: 'soft',
        isDry: false,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 1251,
    },
  },
  {
    amount: 2,
    price: 1053,
    totalPrice: 2106,
    numOfPalettes: 0,
    offer: {
      totalPrice: 2106,
      finalTotalPrice: 2106,
      finalAmount: 2,
      amount: 2,
      p: {
        _id: '5ff7895a1b767f189433018d',
        woodCategory: 'soft',
        isDry: false,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 1053,
    },
  },
  {
    amount: 3,
    price: 987,
    totalPrice: 2961,
    numOfPalettes: 0,
    offer: {
      totalPrice: 2961,
      finalTotalPrice: 2961,
      finalAmount: 3,
      amount: 3,
      p: {
        _id: '5ff7895a1b767f189433018d',
        woodCategory: 'soft',
        isDry: false,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 987,
    },
  },
  {
    amount: 4,
    price: 950,
    totalPrice: 3800,
    numOfPalettes: 0,
    offer: {
      totalPrice: 3800,
      finalTotalPrice: 3800,
      finalAmount: 4,
      amount: 4,
      p: { _id: '5ebbc8060adcdb48b8749999', woodCategory: 'soft', isDry: true },
      numOfPalettes: 0,
      finalPricePerOnePrms: 950,
    },
  },
  {
    amount: 5,
    price: 1010,
    totalPrice: 5050,
    numOfPalettes: 0,
    offer: {
      totalPrice: 5050,
      finalTotalPrice: 5050,
      finalAmount: 5,
      amount: 5,
      p: {
        _id: '5fad0cbc1f0ad543c4ffafb1',
        woodCategory: 'soft',
        isDry: false,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 1010,
    },
  },
  {
    amount: 6,
    price: 930,
    totalPrice: 5580,
    numOfPalettes: 0,
    offer: {
      totalPrice: 5580,
      finalTotalPrice: 5580,
      finalAmount: 6,
      amount: 6,
      p: {
        _id: '5eb19bc397fed36c90822c44',
        woodCategory: 'soft',
        isDry: false,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 930,
    },
  },
  {
    amount: 7,
    price: 959,
    totalPrice: 6710,
    numOfPalettes: 0,
    offer: {
      totalPrice: 6710,
      finalTotalPrice: 6710,
      finalAmount: 7,
      amount: 7,
      p: {
        _id: '5eb19c4c97fed36c90822c45',
        woodCategory: 'soft',
        isDry: false,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 958.57,
    },
  },
  {
    amount: 8,
    price: 950,
    totalPrice: 7600,
    numOfPalettes: 0,
    offer: {
      totalPrice: 7600,
      finalTotalPrice: 7600,
      finalAmount: 8,
      amount: 8,
      p: { _id: '5ebbc82c0adcdb48b874999a', woodCategory: 'soft', isDry: true },
      numOfPalettes: 0,
      finalPricePerOnePrms: 950,
    },
  },
  {
    amount: 9,
    price: 943,
    totalPrice: 8490,
    numOfPalettes: 0,
    offer: {
      totalPrice: 8490,
      finalTotalPrice: 8490,
      finalAmount: 9,
      amount: 9,
      p: {
        _id: '5eb19c4c97fed36c90822c45',
        woodCategory: 'soft',
        isDry: false,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 943.33,
    },
  },
  {
    amount: 10,
    price: 938,
    totalPrice: 9380,
    numOfPalettes: 0,
    offer: {
      totalPrice: 9380,
      finalTotalPrice: 9380,
      finalAmount: 10,
      amount: 10,
      p: {
        _id: '5eb19c4c97fed36c90822c45',
        woodCategory: 'soft',
        isDry: false,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 938,
    },
  },
  {
    amount: 11,
    price: 934,
    totalPrice: 10270,
    numOfPalettes: 0,
    offer: {
      totalPrice: 10270,
      finalTotalPrice: 10270,
      finalAmount: 11,
      amount: 11,
      p: {
        _id: '5eb19c4c97fed36c90822c45',
        woodCategory: 'soft',
        isDry: false,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 933.64,
    },
  },
  {
    amount: 12,
    price: 880,
    totalPrice: 10560,
    numOfPalettes: 0,
    offer: {
      totalPrice: 10560,
      finalTotalPrice: 10560,
      finalAmount: 12,
      amount: 12,
      p: { _id: '5ebbc85b0adcdb48b874999b', woodCategory: 'soft', isDry: true },
      numOfPalettes: 0,
      finalPricePerOnePrms: 880,
    },
  },
  {
    amount: 13,
    price: 902,
    totalPrice: 11730,
    numOfPalettes: 0,
    offer: {
      totalPrice: 11730,
      finalTotalPrice: 11730,
      finalAmount: 13,
      amount: 13,
      p: {
        _id: '5eb17ea797fed36c90822c34',
        woodCategory: 'soft',
        isDry: false,
        prmsPerPalette: null,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 902.31,
    },
  },
  {
    amount: 14,
    price: 887,
    totalPrice: 12420,
    numOfPalettes: 0,
    offer: {
      totalPrice: 12420,
      finalTotalPrice: 12420,
      finalAmount: 14,
      amount: 14,
      p: {
        _id: '5eb17ea797fed36c90822c34',
        woodCategory: 'soft',
        isDry: false,
        prmsPerPalette: null,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 887.14,
    },
  },
  {
    amount: 15,
    price: 874,
    totalPrice: 13110,
    numOfPalettes: 0,
    offer: {
      totalPrice: 13110,
      finalTotalPrice: 13110,
      finalAmount: 15,
      amount: 15,
      p: {
        _id: '5eb17ea797fed36c90822c34',
        woodCategory: 'soft',
        isDry: false,
        prmsPerPalette: null,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 874,
    },
  },
  {
    amount: 16,
    price: 863,
    totalPrice: 13800,
    numOfPalettes: 0,
    offer: {
      totalPrice: 13800,
      finalTotalPrice: 13800,
      finalAmount: 16,
      amount: 16,
      p: {
        _id: '5eb17ea797fed36c90822c34',
        woodCategory: 'soft',
        isDry: false,
        prmsPerPalette: null,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 862.5,
    },
  },
  {
    amount: 17,
    price: 852,
    totalPrice: 14490,
    numOfPalettes: 0,
    offer: {
      totalPrice: 14490,
      finalTotalPrice: 14490,
      finalAmount: 17,
      amount: 17,
      p: {
        _id: '5eb17ea797fed36c90822c34',
        woodCategory: 'soft',
        isDry: false,
        prmsPerPalette: null,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 852.35,
    },
  },
  {
    amount: 18,
    price: 930,
    totalPrice: 16740,
    numOfPalettes: 0,
    offer: {
      totalPrice: 16740,
      finalTotalPrice: 16740,
      finalAmount: 18,
      amount: 18,
      p: {
        _id: '5eb19cb497fed36c90822c46',
        woodCategory: 'soft',
        isDry: false,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 930,
    },
  },
  {
    amount: 19,
    price: 941,
    totalPrice: 17870,
    numOfPalettes: 0,
    offer: {
      totalPrice: 17870,
      finalTotalPrice: 17870,
      finalAmount: 19,
      amount: 19,
      p: {
        _id: '5eb19cd897fed36c90822c47',
        woodCategory: 'soft',
        isDry: false,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 940.53,
    },
  },
  {
    amount: 20,
    price: 938,
    totalPrice: 18760,
    numOfPalettes: 0,
    offer: {
      totalPrice: 18760,
      finalTotalPrice: 18760,
      finalAmount: 20,
      amount: 20,
      p: {
        _id: '5eb19cd897fed36c90822c47',
        woodCategory: 'soft',
        isDry: false,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 938,
    },
  },
  {
    amount: 21,
    price: 936,
    totalPrice: 19650,
    numOfPalettes: 0,
    offer: {
      totalPrice: 19650,
      finalTotalPrice: 19650,
      finalAmount: 21,
      amount: 21,
      p: {
        _id: '5eb19cd897fed36c90822c47',
        woodCategory: 'soft',
        isDry: false,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 935.71,
    },
  },
  {
    amount: 22,
    price: 934,
    totalPrice: 20540,
    numOfPalettes: 0,
    offer: {
      totalPrice: 20540,
      finalTotalPrice: 20540,
      finalAmount: 22,
      amount: 22,
      p: {
        _id: '5eb19cd897fed36c90822c47',
        woodCategory: 'soft',
        isDry: false,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 933.64,
    },
  },
  {
    amount: 23,
    price: 930,
    totalPrice: 21390,
    numOfPalettes: 0,
    offer: {
      totalPrice: 21390,
      finalTotalPrice: 21390,
      finalAmount: 23,
      amount: 23,
      p: {
        _id: '6004a2b91d97047ca611e3fb',
        woodCategory: 'soft',
        isDry: false,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 930,
    },
  },
  {
    amount: 24,
    price: 880,
    totalPrice: 21120,
    numOfPalettes: 0,
    offer: {
      totalPrice: 21120,
      finalTotalPrice: 21120,
      finalAmount: 24,
      amount: 24,
      p: { _id: '5ebbc8aa0adcdb48b874999c', woodCategory: 'soft', isDry: true },
      numOfPalettes: 0,
      finalPricePerOnePrms: 880,
    },
  },
  {
    amount: 25,
    price: 911,
    totalPrice: 22770,
    numOfPalettes: 0,
    offer: {
      totalPrice: 22770,
      finalTotalPrice: 22770,
      finalAmount: 25,
      amount: 25,
      p: {
        _id: '6004a2b91d97047ca611e3fb',
        woodCategory: 'soft',
        isDry: false,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 910.8,
    },
  },
  {
    amount: 26,
    price: 902,
    totalPrice: 23460,
    numOfPalettes: 0,
    offer: {
      totalPrice: 23460,
      finalTotalPrice: 23460,
      finalAmount: 26,
      amount: 26,
      p: {
        _id: '6004a2b91d97047ca611e3fb',
        woodCategory: 'soft',
        isDry: false,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 902.31,
    },
  },
  {
    amount: 27,
    price: 894,
    totalPrice: 24150,
    numOfPalettes: 0,
    offer: {
      totalPrice: 24150,
      finalTotalPrice: 24150,
      finalAmount: 27,
      amount: 27,
      p: {
        _id: '6004a2b91d97047ca611e3fb',
        woodCategory: 'soft',
        isDry: false,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 894.44,
    },
  },
  {
    amount: 28,
    price: 887,
    totalPrice: 24840,
    numOfPalettes: 0,
    offer: {
      totalPrice: 24840,
      finalTotalPrice: 24840,
      finalAmount: 28,
      amount: 28,
      p: {
        _id: '6004a2b91d97047ca611e3fb',
        woodCategory: 'soft',
        isDry: false,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 887.14,
    },
  },
  {
    amount: 29,
    price: 880,
    totalPrice: 25530,
    numOfPalettes: 0,
    offer: {
      totalPrice: 25530,
      finalTotalPrice: 25530,
      finalAmount: 29,
      amount: 29,
      p: {
        _id: '6004a2b91d97047ca611e3fb',
        woodCategory: 'soft',
        isDry: false,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 880.34,
    },
  },
  {
    amount: 30,
    price: 874,
    totalPrice: 26220,
    numOfPalettes: 0,
    offer: {
      totalPrice: 26220,
      finalTotalPrice: 26220,
      finalAmount: 30,
      amount: 30,
      p: {
        _id: '6004a2b91d97047ca611e3fb',
        woodCategory: 'soft',
        isDry: false,
      },
      numOfPalettes: 0,
      finalPricePerOnePrms: 874,
    },
  },
]
