// import MuliLight from '../data/fonts/Muli-Light.ttf'
import BreeSerif from '../data/fonts/BreeSerif-Regular.ttf'
import BreeSerifLight from '../data/fonts/BreeSerif-Light.woff'
import SignikaSemiBold from '../data/fonts/Signika-SemiBold.ttf'
import SignikaRegular from '../data/fonts/Signika-Regular.ttf'
import SignikaLight from '../data/fonts/Signika-Light.ttf'

export default {
  SignikaSemiBold,
  SignikaRegular,
  SignikaLight,
  BreeSerif,
  BreeSerifLight,
}
