// https://styled-icons.dev/
import React from 'react'
import { Cart } from '@styled-icons/ionicons-outline/Cart'
import { BarChart } from '@styled-icons/ionicons-outline/BarChart'
import { CheckmarkCircle } from '@styled-icons/ionicons-outline/CheckmarkCircle'
import { CheckmarkCircle as CheckMarkCircleSolid } from '@styled-icons/ionicons-solid/CheckmarkCircle'

import { Truck } from '@styled-icons/bootstrap/Truck'
import { Happy } from '@styled-icons/ionicons-outline/Happy'
import { ChevronBack } from '@styled-icons/ionicons-outline/ChevronBack'
import { Bulb } from '@styled-icons/ionicons-outline/Bulb'
import { Globe } from '@styled-icons/ionicons-outline/Globe'
import { GlobeSearch } from '@styled-icons/fluentui-system-regular/GlobeSearch'
import { PhoneCheckmark } from '@styled-icons/fluentui-system-regular/PhoneCheckmark'
import { Beach } from '@styled-icons/fluentui-system-regular/Beach'
import { MailSend } from '@styled-icons/boxicons-regular/MailSend'
import { LinkAlt } from '@styled-icons/boxicons-regular/LinkAlt'
import { FacebookCircle } from '@styled-icons/boxicons-logos/FacebookCircle'
import { CartCheck } from '@styled-icons/bootstrap/CartCheck'
import { Cart as CartEmpty } from '@styled-icons/bootstrap/Cart'


const icons = {
  CART: Cart,
  CHART: BarChart,
  CHECK: CheckmarkCircle,
  TRUCK: Truck,
  HAPPY: Happy,
  BACK: ChevronBack,
  BULB: Bulb,
  Globe,
  GlobeSearch,
  PhoneCheckmark,
  Beach,
  ShareEmail: MailSend,
  ShareLink: LinkAlt,
  ShareFacebook: FacebookCircle,
  ShareCheck: CheckmarkCircle,
  InputCheck: CheckMarkCircleSolid,
  CartEmpty,
  CartCheck,
}

function Icon({ name, ...props }) {
  const Component = icons[name] || icons.CHECK

  return <Component {...props} />
}

export default Icon

Object.keys(icons).forEach((key) => {
  Icon[key] = icons[key]
})
