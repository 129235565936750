import React, { useMemo } from 'react'

const PartnerContext = React.createContext()

export function PartnerConextProvider({ children, ...props }) {
  const value = useMemo(
    () => ({
      partner: props.partner,
    }),
    [props.partner],
  )

  return (
    <PartnerContext.Provider value={value}>{children}</PartnerContext.Provider>
  )
}

export const usePartner = () => {
  const { partner } = React.useContext(PartnerContext) || {}

  return { partner }
}
